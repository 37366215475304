import { InfiniteScrollTableCursor } from '@sivis/shared/components/entityView';
import { GridColDef } from '@mui/x-data-grid-pro';
import { useCustomIntl, useTranslateColumns } from '@sivis/intl';
import { Account, GeneratedAccountTypes } from '@sivis/identity/api';
import { accountEntityToTableRow } from '../accountType/accountTypeAccountsTab';
import { useAccountsPageApi } from '../account/useAccountsPageApi';


const columns: GridColDef[] = [
  {
    field: "name",
    minWidth: 200,
    flex: 1,
  },
  {
    field: "systemName",
    minWidth: 150,
    flex: 1,
  },
  {
    field: "accountType",
    renderCell: params => params.value?.name,
    minWidth: 150,
    flex: 1,
  }
];

interface AccountListByIdentityProps {
  identityId: string;
}

export const AccountListByIdentity = ({ identityId }: AccountListByIdentityProps) => {
  const intl = useCustomIntl();

  const {
    onSearch,
    pageSize,
    useAccountsPageQuery,
    parseAccountsPageResult,
  } = useAccountsPageApi({ identityId: identityId });

  const translatedColumns = useTranslateColumns(
    columns,
    "fusion.account.propertyName"
  );

  if (!identityId) {
    return <div>{intl.format("fusion.account.error.noIdentityId")}</div>;
  }

  return (
    <InfiniteScrollTableCursor<Account, GeneratedAccountTypes.GetAccountsQuery>
      columns={translatedColumns}
      pageSize={pageSize}
      usePageQuery={useAccountsPageQuery}
      parseResult={parseAccountsPageResult}
      onSearch={onSearch}
      placeholder={intl.format("fusion.general.searchPlaceholder")}
      renderRow={accountEntityToTableRow}
    />
  );
};
