import { DeleteButton, EditButton, EntityView } from '@sivis/shared/components/entityView';
import AccountTypeInformationTab, {
  AccountTypeInformationTabProps
} from './accountTypeInformationTab';
import AccountTypeAccountsTab from './accountTypeAccountsTab';
import { useAccountTypeApi } from './useAccountTypeApi';
import { Outlet, useOutletContext } from 'react-router-dom';
import { ACCOUNTS_TAB_PATHNAME, ACCOUNT_TYPE_URL, INFORMATION_TAB_PATHNAME } from '../components/routes/constants';
import { useCustomIntl } from '@sivis/intl';
import { getTranslatedErrorMessage } from '@sivis/shared/api-utils';
import { useRouteRedirect } from '../common/useRouteRedirect';

export const AccountTypeView = () => {
  useRouteRedirect({ basePath: ACCOUNT_TYPE_URL, subPath: INFORMATION_TAB_PATHNAME });

  const intl = useCustomIntl();
  const {
    accountType,
    error,
    isNew,
    onSave,
    onCancel,
    onDelete,
    editMode,
    toggleEditMode,
    defaultInput
  } = useAccountTypeApi();

  const informationTab = { label: intl.format("fusion.breadcrumb.information"), path: INFORMATION_TAB_PATHNAME };
  const accountsTab = {
    label: intl.format('fusion.account.list.name'),
    path: ACCOUNTS_TAB_PATHNAME
  };

  const tabs = isNew ? [informationTab] : [informationTab, accountsTab];
  const titleExisting = `${intl.format("fusion.accountType.information.titleExisting")}${accountType?.name ?? ''}`;
  const title = isNew ? intl.format("fusion.accountType.information.titleNew") : titleExisting;

  const buttons = isNew ? undefined : [
    <EditButton disabled={editMode} onClick={toggleEditMode} key="edit" />,
    <DeleteButton disabled={true} onClick={onDelete} key="delete" />
  ];

  return (
    <EntityView
      title={title}
      tabs={tabs}
      actionButtons={buttons}
      error={error ? getTranslatedErrorMessage(error, "fusion.accountType.error.notFound", intl.format) : undefined}>
      <Outlet context={{ editMode, accountType, isNew, onSave, onCancel, onDelete, defaultInput }} />
    </EntityView>
  );
};


export const AccountTypeInformationTabWrapperComponent = () => {
  const props = useOutletContext<AccountTypeInformationTabProps>();
  return <AccountTypeInformationTab {...props} />;
};

export const AccountTypeAccountsTabWrapperComponent = () => {
  const props = useOutletContext<AccountTypeInformationTabProps>();
  return <AccountTypeAccountsTab {...props} />;
};



