import {IText} from './types';
import {useDispatch, useSelector} from 'react-redux';
import {ReactNode, useMemo} from 'react';
import {IntlShape, useIntl} from 'react-intl';
import {addText} from './intlSlice';
import {ELocales} from './ELocales';
import {selectMessages} from './intlSelectors';
import {
  FormatXMLElementFn,
  PrimitiveType,
  Options as IntlMessageFormatOptions
} from "intl-messageformat";
import {MessageDescriptor} from "@formatjs/intl/src/types";

const DEFAULT_MAPPING_LOCALE = ELocales.EN;

export type FormatMessageFunction = (id: string, values?: Record<string, PrimitiveType>) => string;

enum EMessageIdType {
  USER = 'usr',
  PATTERN = 'pat',
}

const getFormattedId = (type: EMessageIdType, prefix: string, text: string): string => {
  return type + (prefix.length > 0 ? '.' + prefix : '') + ':' + text;
};
const isPattern = (message: IText) => {
  return message.id.startsWith(EMessageIdType.PATTERN);
};
const getPattern = (id: string) => {
  return id.split(':')[1] ?? '';
};
const getMappedMessages = (messages: IText[]): Map<string, IText> => {
  const map = new Map<string, IText>();
  messages.forEach(x => map.set(x.id, x));
  return map;
};
const getPatternMessages = (messages: IText[]): { pattern: string, value: IText }[] => {
  const filtered = messages.filter(t => isPattern(t));
  return filtered.map(x => ({pattern: getPattern(x.id), value: x}));
};
const getMessageStub = (id: string, text: string): IText => {
  return {
    id,
    locale: DEFAULT_MAPPING_LOCALE,
    message: text
  };
};
export const useCustomIntl = () => {
  const messages: IText[] = useSelector(selectMessages).filter(t => t.locale === DEFAULT_MAPPING_LOCALE);
  const textMap = useMemo(() => getMappedMessages(messages), [messages]);
  const patterns = useMemo(() => getPatternMessages(messages), [messages]);
  const intl = useIntl();
  const dispatch = useDispatch();

  const tryFindPatternMatch = (text: string) => patterns.find(x => text.match(new RegExp(x.pattern)))?.value;

  const tryFindExactMatch = (id: string) => textMap.get(id);

  const appendMessageStub = (id: string, text: string) => {
    // If text is unknown -> add text to possible texts
    const newMessage = getMessageStub(id, text);
    dispatch(addText(newMessage));
    // Note: adding to the following map won't trigger rerender
    // It is only done, to prevent multiple calls to addText()
    // if the same ID is accessed multiple times between rerenders
    textMap.set(id, newMessage);
    return newMessage;
  };


  const getUnmappedFormatter = (prefix: string, text: string, alternativeTextId?: string) => {
    const id = getFormattedId(EMessageIdType.USER, prefix, text);
    let match = tryFindExactMatch(id);
    if (!match) {
      if (alternativeTextId != null) {
        match = tryFindExactMatch(alternativeTextId);
      }
      if (!match) {
        match = tryFindPatternMatch(text);
        if (!match) {
          match = appendMessageStub(id, text);
          return {id, message: match.message};
        }
      }
    }
    const message = formatMessage(match.id);
    return {id, message};
  };

  const getMappedFormatter = (id: string, text?: string) => {
    const defaultText = text ? text : id;
    let match = tryFindExactMatch(id);
    if (!match) {
      match = tryFindPatternMatch(id);
      if (!match) {
        match = appendMessageStub(id, defaultText);
        return {id, message: match.message};
      }
    }
    const message = formatMessage(match.id);
    return {id, message};
  };

  const formatMessage = (id: string): string => intl.formatMessage({id});

  const formatMappedMessage = (id: string, text?: string): string => getMappedFormatter(id, text).message;

  const formatUnmappedMessage = (prefix: string, text: string, alternativeTextId?: string): string =>
    getUnmappedFormatter(prefix, text, alternativeTextId).message;

  const formatMessageStatic = (id: string, values?: Record<string, PrimitiveType>): string => intl.formatMessage({id}, values);

  // @ts-ignore
  const formatRichText = (id: string, values?: Record<string, string | FormatXMLElementFn<string, ReactNode>>): ReactNode => intl.formatMessage({id}, values);

  const formatBold = (id: string, values?: Record<string, string | FormatXMLElementFn<string, ReactNode>>): ReactNode =>
    formatRichText(id, {...values, bold: chunk => <strong>{chunk}</strong>});

  const formatLight = (id: string, values?: Record<string, string | FormatXMLElementFn<string, ReactNode>>): ReactNode =>
    formatRichText(id, {...values, light: chunk => <span style={{color: "lightgrey"}}> {chunk} </span>});
  const formatMessageNative = (descriptor: MessageDescriptor, values?: Record<string, PrimitiveType | FormatXMLElementFn<string, string>>, opts?: IntlMessageFormatOptions): string =>
      intl.formatMessage(descriptor, values, opts);

  return {
    getUnmappedFormatter, getMappedFormatter, formatUnmappedMessage, formatMappedMessage, intl,
    /**
     * Finds message (possibly parameterized) that corresponds to the given id. Statically verifies message id.
     */
    format: formatMessageStatic,
    /**
     * Format embedded xml tags in messages.
     */
    formatRichText,
    /**
     * Format embedded <bold> tag in messages.
     * Message example: "This is <bold>important</bold>."
     */
    formatBold,
    formatLight,
    /**
     * Wrapper for the formatMessage method in react-intl
     */
    formatMessageNative
  };
};

export type CustomIntlType = ReturnType<typeof useCustomIntl>;
