import { useCustomIntl, useTranslateConfigs } from '@sivis/intl';
import { Stack } from '@mui/system';
import { EntityForm, EntityFormHandle, RowGroup } from '@sivis/shared/components/entityView';
import styles from './rule.module.scss';
import { IconButton } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { updateWatchValues, WatchValues } from './ruleUtils';
import { Criterion } from '@sivis/identity/api';
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';

const configsSingleCriterion = [
  {
    property: 'entityField',
    required: true
  },
  {
    // for now only equals is supported
    property: 'operation',
    disabled: true
  },
  {
    property: 'value',
    required: true
  }
];

interface CriterionFormProps {
  criterion: Criterion;
  groupId: string;
  onDeleteCriterion: (groupId: string, criterionId: string) => void;
  onSave: (value: Record<string, any>) => void;
  setWatchValues: (value: (((prevState: WatchValues) => WatchValues) | WatchValues)) => void;
}

export type CriterionFormHandle = {
  submit: () => Promise<boolean>;
};

export const CriterionForm = forwardRef<CriterionFormHandle, CriterionFormProps>(({
                                                                                    criterion,
                                                                                    groupId,
                                                                                    onDeleteCriterion,
                                                                                    onSave,
                                                                                    setWatchValues
                                                                                  }, ref) => {
  const intl = useCustomIntl();
  const translatedConfigs = useTranslateConfigs(configsSingleCriterion, 'fusion.rule.singleCriterion.propertyName');
  const entityFormRef = useRef<EntityFormHandle>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    setIsSubmitting(false);
  }, [criterion]);

  useImperativeHandle(ref, () => ({
    submit: async () => {
      if (entityFormRef.current && !isSubmitting) {
        setIsSubmitting(true);
        try {
          await entityFormRef.current.submit();
          return !entityFormRef.current.hasErrors();
        } catch (error) {
          return false;
        } finally {
          setIsSubmitting(false);
        }
      }
      return false;
    }
  }));

  return (
    <div key={criterion.id}>
      <Stack direction="row" justify-content="space-between" sx={{ width: '100%' }}>
        <RowGroup differentStyles={styles.rowGroupThreeColumns}>
          <EntityForm
            ref={entityFormRef}
            configs={translatedConfigs}
            value={{ ...criterion }}
            onSubmit={onSave}
            hideButtons
            displayInThreeColumns
            onWatch={(watchValues) => updateWatchValues(`criterion-${criterion.id}`, watchValues, setWatchValues)}
          />
        </RowGroup>
        <IconButton
          onClick={() => onDeleteCriterion(groupId, criterion.id)}
          aria-label={intl.format('general.delete')}>
          <ClearIcon />
        </IconButton>
      </Stack>
    </div>
  );
});
