import { LanguageOutlined } from '@mui/icons-material';
import {
  Button,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Radio,
  RadioGroup,
} from '@pointsharp/component-library';
import { HEADER_ITEM_LEFT, HEADER_ITEM_RIGHT } from '@sivis/app-shell';
import {
  ELocales,
  ELocaleText,
  selectAvailableLanguages,
  selectLanguage,
  setLanguage,
  useCustomIntl,
} from '@sivis/intl';
import {
  PreparedRegistration,
  prepareRegisterComponent,
} from '@sivis/shared/components/registry';

import { PsPopover } from '@sivis/shared/components/popover';

import { selectThemeOptions } from '@sivis/shared/theme';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from './headerItems.module.scss';

const AppName = ({
  name,
  isBlackHeader,
}: {
  name: string;
  isBlackHeader?: boolean;
}) => {
  return (
    <div
      className={
        isBlackHeader ? styles['appNameWhite'] : styles['appNameBlack']
      }
    >
      {name}
    </div>
  );
};

export const prepareHeaderAppNameRegistration = (
  name: string,
  isBlackHeader: boolean = false
): PreparedRegistration =>
  prepareRegisterComponent({
    id: `${HEADER_ITEM_LEFT}/${AppName.name}`,
    name: 'appName',
    component: () => <AppName name={name} isBlackHeader={isBlackHeader} />,
    type: HEADER_ITEM_LEFT,
    order: 0,
  });

const HeaderLanguageSwitcher = () => {
  const dispatch = useDispatch();
  const language = useSelector(selectLanguage);
  const availableLanguages = useSelector(selectAvailableLanguages);
  const themeOptions = useSelector(selectThemeOptions);
  const intl = useCustomIntl();

  const style = {
    item: {
      color: themeOptions?.palette?.background?.almostWhite,
      minHeight: 20,
      '&:hover': {
        backgroundColor: themeOptions?.palette?.background?.almostWhite,
        color: themeOptions?.palette?.background?.almostBlack,
        '& .MuiListItemIcon-root': {
          color: themeOptions?.palette?.background?.almostBlack,
        },
      },
    },
    listItem: {
      p: 0,
    },
    listItemButton: {
      pl: 0,
      pt: 0,
      pb: 0,
      fontSize: '12px',
    },
  };

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLanguageSwitch = (lang: ELocales | string) => {
    dispatch(setLanguage(lang));
    handleMenuClose();
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const selectedLanguage = language;

  return (
    <div>
      <React.Fragment key="languageIcon">
        <Button onClick={handleClick} sx={{ textTransform: 'none' }}>
          <ListItemButton sx={style.item}>
            <LanguageOutlined />
          </ListItemButton>
        </Button>
      </React.Fragment>
      <PsPopover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        popoverTitle={intl.format('fusion.header.language')}
      >
        <List>
          <RadioGroup
            value={selectedLanguage}
            onChange={(e) => handleLanguageSwitch(e.target.value)}
            sx={{ p: 0 }}
          >
            {availableLanguages.map((lang) => (
              <ListItem sx={style.listItem} key={lang}>
                <ListItemButton
                  sx={style.listItemButton}
                  onClick={() => handleLanguageSwitch(lang)}
                >
                  <Radio checked={selectedLanguage === lang} value={lang} />
                  <ListItemText primary={ELocaleText[lang]} />
                </ListItemButton>
              </ListItem>
            ))}
          </RadioGroup>
        </List>
      </PsPopover>
    </div>
  );
};

export const headerLanguageSwitcherRegistration: PreparedRegistration =
  prepareRegisterComponent({
    id: `${HEADER_ITEM_RIGHT}/${HeaderLanguageSwitcher.name}`,
    name: 'headerLanguageSwitcher',
    component: HeaderLanguageSwitcher,
    type: HEADER_ITEM_RIGHT,
    order: 1,
  });
