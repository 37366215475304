import {
  ExpandLess as ExpandLessIcon,
  ExpandMore as ExpandMoreIcon,
} from '@mui/icons-material';
import {
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  pointsharpThemeOptions,
  Typography,
} from '@pointsharp/component-library';
import React, { useEffect, useState } from 'react';
import { matchPath, useLocation } from 'react-router-dom';
import { PsSidebarItem } from './psSidebarItem';
import { ICategory, IItem, isCategory } from './utils';

export interface PsSidebarProps {
  menuList: (ICategory | IItem)[];
  actionsList?: IItem[];
}

export const style = {
  item: {
    color: pointsharpThemeOptions?.palette?.background?.almostWhite,
    minHeight: 48,
    pl: 2.5,
    '&:hover': {
      backgroundColor: pointsharpThemeOptions?.palette?.background?.almostWhite,
      color: pointsharpThemeOptions?.palette?.background?.almostBlack,
      '& .MuiListItemIcon-root': {
        color: pointsharpThemeOptions?.palette?.background?.almostBlack,
      },
      '& .MuiTypography-root': {
        color: pointsharpThemeOptions?.palette?.background?.almostBlack,
      },
    },
  },
  list: {
    padding: 0,
    width: '100%',
    height: '100%',
    bgcolor: pointsharpThemeOptions?.palette?.background?.almostBlack,
  },
  actionsList: {
    padding: 0,
    width: '100%',
    bgcolor: pointsharpThemeOptions?.palette?.background?.almostBlack,
  },
  icon: {
    color: pointsharpThemeOptions?.palette?.background?.almostWhite,
    minWidth: 24,
    mr: 2.5,
  },
  listItemText: {
    fontFamily:
      '-apple-system, BlinkMacSystemFont, "SF Pro Display", "Segoe UI", Roboto, sans-serif',
    fontWeight: 600,
    fontSize: 10,
    lineHeight: 0.158,
    letterSpacing: '0.15em',
    color: pointsharpThemeOptions?.palette?.background?.almostWhite,
  },
};

export const PsSidebar = ({ menuList, actionsList }: PsSidebarProps) => {
  const [openCategory, setOpenCategory] = useState<string>('');
  const [selected, setSelected] = useState<IItem | null>(null);

  const location = useLocation();

  const findSelectedItem = (
    menuList: (ICategory | IItem)[],
    pathname: string
  ): IItem | undefined => {
    let foundItem: IItem | undefined;

    menuList.some((item) => {
      if (isCategory(item)) {
        const nestedItem = findNestedSelectedItem(item.nested, pathname);

        if (nestedItem) {
          setOpenCategory(item.name);
          foundItem = nestedItem;
          return true;
        }
      } else if (matchPath(item.url, pathname)) {
        foundItem = item;
        return true;
      }
      return false;
    });

    return foundItem;
  };

  const findNestedSelectedItem = (
    nestedItems: IItem[],
    pathname: string
  ): IItem | undefined => {
    return nestedItems.find((nestedItem) =>
      matchPath(nestedItem.url, pathname)
    );
  };

  const handleToggle = (name: string) => {
    setOpenCategory((prev) => (prev === name ? '' : name));
  };

  useEffect(() => {
    const selectedItem = findSelectedItem(menuList, location.pathname);

    if (selectedItem) {
      setSelected(selectedItem);
    }
  }, [location.pathname]);

  return (
    <>
      <List sx={style.list} component="nav" aria-labelledby="list-subheader">
        {menuList?.map((item) => {
          if (isCategory(item)) {
            return (
              <React.Fragment key={item.name}>
                <ListItemButton
                  sx={style.item}
                  onClick={() => handleToggle(item.name)}
                >
                  {item.icon && (
                    <ListItemIcon sx={style.icon}>
                      <item.icon />
                    </ListItemIcon>
                  )}
                  <ListItemText
                    primary={
                      <Typography sx={style.listItemText}>
                        {item.name}
                      </Typography>
                    }
                  />
                  {openCategory === item.name ? (
                    <ExpandLessIcon />
                  ) : (
                    <ExpandMoreIcon />
                  )}
                </ListItemButton>
                <Collapse
                  in={openCategory === item.name}
                  timeout="auto"
                  unmountOnExit
                >
                  <List sx={style.list} component="div" disablePadding>
                    {item.nested.map((nestedItem) => (
                      <PsSidebarItem
                        key={nestedItem.name}
                        item={nestedItem}
                        selectedItem={selected}
                        onSelect={setSelected}
                        isNested
                      />
                    ))}
                  </List>
                </Collapse>
              </React.Fragment>
            );
          } else {
            return (
              <PsSidebarItem
                key={item.name}
                item={item}
                selectedItem={selected}
                onSelect={setSelected}
              />
            );
          }
        })}
      </List>

      {actionsList && actionsList?.length > 0 && (
        <List sx={style.actionsList} aria-labelledby="list-actionHeader">
          {actionsList?.map((item) => (
            <PsSidebarItem
              key={item.name}
              item={item}
              selectedItem={selected}
              onSelect={setSelected}
            />
          ))}
        </List>
      )}
    </>
  );
};
