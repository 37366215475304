import { PropsWithChildren } from 'react';
import { SearchField, SearchFieldProps } from '../searchField/searchField';

export interface ToolbarProps extends SearchFieldProps {
  totalEntries: number;
  buttons?: JSX.Element[];
}

export const Toolbar = (props: PropsWithChildren<ToolbarProps>) => {
  return (
    <SearchField
      placeholder={props.placeholder}
      totalEntries={props.totalEntries}
      buttons={props.buttons}
      onSearch={props.onSearch}
    />
  );
};
