import { EntitlementType, ExtendedRoleEntitlement, RelationQueryType, Resource } from '@sivis/identity/api';
import { useCustomIntl, useTranslateColumns } from '@sivis/intl';
import { EntityTableRow, ListModifyViewUpdated } from '@sivis/shared/components/entityView';
import { isEqualById } from '@sivis/shared/utils';
import { useOutletContext } from 'react-router-dom';
import { EntityType, useSingleEditMode } from '../components/breadcrumb/editModeContext';
import { useResourceRoleEntitlementsApi } from '../entitlement/api/useResourceRoleEntitlementsApi';
import { createEntitlementPopup } from '../entitlement/createEntitlementPopup';
import {
  getEntitlementFlags,
  getRoleResourceEntitlementDescription,
  mapTableRowToEntitlement,
  roleEntitlementColumns,
  roleEntitlementToTableRow,
  useEntitlementMessages
} from '../entitlement/entitlementUtils';
import { RoleResourceEntitlementDetails } from '../entitlement/roleResourceEntitlementDetails';
import styles from './resource.module.scss';
import { getResourceEntitlementConfirmationPopup } from './resourceIdentitiesTab';
import { ContextType } from './resourceView';

const ResourceRolesTab = () => {
  const intl = useCustomIntl();
  const {deleteEntitlementMessage} = useEntitlementMessages();
  const {resource, editMode, onCancel} = useOutletContext<ContextType>();
  const {toggleEditMode} = useSingleEditMode(EntityType.RESOURCE);
  const {
    onSave,
    submitted,
    setSubmitted,
    pageSize,
    useEntitlementsPageQuery,
    parseEntitlementsPageResult
  } = useResourceRoleEntitlementsApi(resource);

  const getDescription = (entitlement: ExtendedRoleEntitlement, resource: Resource) =>
    getRoleResourceEntitlementDescription(entitlement, entitlement.roleName ?? "", resource.name, intl.format);

  const getPopupTitle = (entitlement: ExtendedRoleEntitlement) => ({
    primaryTitle: intl.format("fusion.entitlement.title"),
    secondaryTitle: getDescription(entitlement, resource)
  });

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const onSearch = () => {
  };

  const roleEntitlementToTableRowWithFlags = (
      entity: ExtendedRoleEntitlement,
      isNew: boolean,
      onEdit?: (entity: ExtendedRoleEntitlement) => void,
      onDelete?: (entity: ExtendedRoleEntitlement) => void): EntityTableRow => {
    return {
      ...roleEntitlementToTableRow(entity, isNew, onEdit, onDelete),
      flags: getEntitlementFlags({validity: entity.validity}, intl.format)
    };
  };

  const onConfirm = () => {
    setSubmitted(false);
    toggleEditMode();
  };

  const confirmationPopup = getResourceEntitlementConfirmationPopup(
      resource, submitted, onConfirm, intl.format
  );

  const createPopup = createEntitlementPopup({
    primaryTitle: intl.format("fusion.entitlement.roleEntitlement.resource.createPopup.title"),
    secondaryTitle: intl.formatBold(
        "fusion.entitlement.roleEntitlement.resource.createPopup.description",
        {resource: resource?.name ?? ""}
    ),
    type: EntitlementType.ROLE_RESOURCE_ENTITLEMENT,
    resource
  });

  const translatedRoleEntitlementColumns = useTranslateColumns(roleEntitlementColumns, "fusion.entitlement.roleEntitlement.propertyName")
  const shownColumns = editMode ? translatedRoleEntitlementColumns : translatedRoleEntitlementColumns.filter(column => column.field !== "action");

  return <div className={styles.listContainer}>
    {confirmationPopup}
    <ListModifyViewUpdated
        columns={shownColumns}
        onSave={onSave}
        entityToTableRow={roleEntitlementToTableRowWithFlags}
        onSearch={onSearch}
        editMode={editMode}
        getEntityTitle={getPopupTitle}
        isEqual={isEqualById}
        entityDetails={RoleResourceEntitlementDetails}
        deleteMessage={deleteEntitlementMessage}
        mapTableRowToEntity={mapTableRowToEntitlement}
        pageSize={pageSize}
        usePageQuery={useEntitlementsPageQuery}
        parseResult={parseEntitlementsPageResult}
        createButtonText="fusion.entitlement.roleEntitlement.add"
        createPopup={createPopup}
        tableType={RelationQueryType.ROLE_RESOURCE_ENTITLEMENTS}
        onCancel={onCancel}
    />
  </div>;
}

export default ResourceRolesTab;
