import {DeleteButton, EditButton, EntityView} from "@sivis/shared/components/entityView";
import {useOrganizationalEntityApi} from "./api/useOrganizationalEntityApi";
import OrganizationalEntityInformationTab, {
  OrganizationalEntityInformationTabProps
} from "./organizationalEntityInformationTab";
import OrganizationalEntityChildrenTab from "./organizationalEntityChildrenTab";
import {
  CHILD_ENTITIES_TAB_PATHNAME,
  INFORMATION_TAB_PATHNAME,
  ORGANIZATIONAL_ENTITY_URL
} from '../components/routes/constants';
import {Outlet, useOutletContext} from "react-router-dom";
import {useCustomIntl} from "@sivis/intl";
import {getTranslatedErrorMessage} from "@sivis/shared/api-utils";
import {useRouteRedirect} from '../common/useRouteRedirect';


export const OrganizationalEntityView = () => {
  useRouteRedirect({ basePath: ORGANIZATIONAL_ENTITY_URL, subPath: INFORMATION_TAB_PATHNAME });

  const intl = useCustomIntl();
  const {
    defaultInput,
    organizationalEntity,
    organizationalEntityTypes,
    addresses,
    error,
    isNew,
    onSave,
    onCancel,
    onDelete,
    editMode,
    toggleEditMode,
  } = useOrganizationalEntityApi();

  const informationTab = {
    label: intl.format("fusion.breadcrumb.information"),
    path: INFORMATION_TAB_PATHNAME
  };

  const childrenTab = {
    label: intl.format("fusion.breadcrumb.childEntities"),
    path: CHILD_ENTITIES_TAB_PATHNAME
  };

  const tabs = isNew ? [informationTab] : [informationTab, childrenTab];

  const titleExisting = `${intl.format("fusion.organizationalEntity.information.titleExisting")}${organizationalEntity?.name}`;
  const title = isNew ? intl.format("fusion.organizationalEntity.information.titleNew") : titleExisting;
  const buttons = isNew ? undefined : [
    <EditButton disabled={editMode} onClick={toggleEditMode} key="edit"/>,
    <DeleteButton onClick={onDelete} key="delete"/>
  ];

  return <EntityView
    title={title}
    tabs={tabs}
    actionButtons={buttons}
    error={error ? getTranslatedErrorMessage(error, "fusion.organizationalEntity.error.notFound", intl.format) : undefined}>
    <Outlet
      context={{
        editMode,
        organizationalEntity,
        organizationalEntityTypes,
        addresses,
        isNew,
        onSave,
        onCancel,
        onDelete,
        defaultInput
      }}/>
  </EntityView>;
};

export const OrganizationalEntityInformationTabWrapperComponent = () => {
  const props = useOutletContext<OrganizationalEntityInformationTabProps>();
  return <OrganizationalEntityInformationTab {...props} />;
};
export const OrganizationalEntityChildrenTabWrapperComponent = () => {
  return <OrganizationalEntityChildrenTab/>;
};
