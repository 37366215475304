import {
  AccountBoxOutlined,
  DvrOutlined,
  GroupOutlined,
  HomeOutlined,
  InsertDriveFileOutlined,
  Inventory2Outlined,
  ManageAccountsOutlined,
  PersonOutlined,
  ScreenshotMonitorOutlined,
} from '@mui/icons-material';
import {
  ACCOUNT_TYPE_URL,
  ADDRESS_URL,
  IDENTITY_TYPE_URL,
  IDENTITY_URL,
  ORGANIZATIONAL_ENTITY_TYPE_URL,
  ORGANIZATIONAL_ENTITY_URL,
  RESOURCE_TYPE_URL,
  RESOURCE_URL,
  ROLE_URL,
  SYSTEM_TYPE_URL,
  SYSTEM_URL
} from '../routes/constants';

import { useCustomIntl } from '@sivis/intl';
import { PsSidebar } from '@sivis/shared/components/sidebar';
import { ICategory, IItem } from 'libs/shared/components/sidebar/src/lib/utils';

export const FusionSidebar = () => {
  const intl = useCustomIntl();
  const menu: (ICategory | IItem)[] = [
    {
      name: intl.format('fusion.sidebar.category.main'),
      nested: [
        {
          name: intl.format('fusion.identity.list.name'),
          url: IDENTITY_URL,
          icon: PersonOutlined,
        },
        {
          name: intl.format('fusion.role.list.name'),
          url: ROLE_URL,
          icon: GroupOutlined,
        },
        {
          name: intl.format('fusion.resource.list.name'),
          url: RESOURCE_URL,
          icon: Inventory2Outlined,
        },
      ],
    },
    {
      name: intl.format('fusion.sidebar.category.organization'),
      nested: [
        {
          name: intl.format('fusion.organizationalEntity.list.name'),
          url: ORGANIZATIONAL_ENTITY_URL,
          icon: HomeOutlined,
        },
        {
          name: intl.format('fusion.organizationalEntityType.list.name'),
          url: ORGANIZATIONAL_ENTITY_TYPE_URL,
          icon: HomeOutlined,
        },
        {
          name: intl.format('fusion.address.list.name'),
          url: ADDRESS_URL,
          icon: HomeOutlined,
        },
      ],
    },
    {
      name: intl.format('fusion.sidebar.category.configure'),
      nested: [
        {
          name: intl.format('fusion.identityType.list.name'),
          url: IDENTITY_TYPE_URL,
          icon: ManageAccountsOutlined,
        },
        {
          name: intl.format('fusion.accountType.list.name'),
          url: ACCOUNT_TYPE_URL,
          icon: AccountBoxOutlined,
        },
        {
          name: intl.format('fusion.resourceType.list.name'),
          url: RESOURCE_TYPE_URL,
          icon: InsertDriveFileOutlined,
        },
        {
          name: intl.format('fusion.system.list.name'),
          url: SYSTEM_URL,
          icon: ScreenshotMonitorOutlined,
        },
        {
          name: intl.format('fusion.systemType.list.name'),
          url: SYSTEM_TYPE_URL,
          icon: DvrOutlined,
        },
      ],
    },
  ];

  const actions: IItem[] = [];

  return <PsSidebar menuList={menu} actionsList={actions} />;
};
