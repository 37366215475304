import { darken, styled } from '@mui/material';
import {
  DataGridPro,
  DataGridProProps,
  GridColDef,
  GridRowHeightParams,
  GridRowParams
} from '@mui/x-data-grid-pro';
import styles from './table.module.scss';
import { Toolbar, ToolbarProps } from './toolbar';
import { useSelector } from 'react-redux';
import { selectThemeOptions } from '@sivis/shared/theme';
import { Box } from '@pointsharp/component-library';
import React from 'react';

export type EntityTableRow = { id: string } & Record<string, any>;

export interface EntityTableParams {
  columns: GridColDef[];
  rows: EntityTableRow[];
  onRowClick?: (id: string) => void;
  onScrollEnd?: DataGridProProps['onRowsScrollEnd'];
  sideFilter?: JSX.Element;
}

export interface EntityTableProps extends EntityTableParams, ToolbarProps {
  /**
   * An extra element to be displayed between head and first data row
   */
  controlPanel?: JSX.Element;
  toolbarButtons?: JSX.Element[];
}

const CONTROL_PANEL_ID = 'control-panel';

const CONTROL_PANEL_ROW_HEIGHT = 70;

const getHoverBackgroundColor = (color: string) => darken(color, 0.025);
const NEW_ROW_BACKGROUND_COLOR = '#f9f9f9';
const GREYED_OUT_TEXT_COLOR = 'var(--secondaryTextColor)';


export const StyledDataGridPro = styled(DataGridPro)(() => {
    const themeOptions = useSelector(selectThemeOptions);
    return {
      border: 'none',
      '& .MuiDataGrid-virtualScroller': { overflowX: 'hidden' },
      '& .MuiDataGrid-row:nth-of-type(odd)': {
        backgroundColor: themeOptions.palette?.background?.almostWhite
      },
      '& .row-isNew': {
        backgroundColor: NEW_ROW_BACKGROUND_COLOR,
        '&:hover': {
          backgroundColor: getHoverBackgroundColor(NEW_ROW_BACKGROUND_COLOR)
        }
      },
      '& .rowIsPublished--false': {
        color: GREYED_OUT_TEXT_COLOR
      }
    }
  }
);


  export const EntityTable = (props: EntityTableProps) => {
    const columns: GridColDef[] =
      props.controlPanel && props.columns
        ? props.columns.map((column, index) => {
          if (index === 0) {
            return {
              ...column,
              renderCell: (params) => {
                if (params.row.id === CONTROL_PANEL_ID) {
                  return <Box sx={{ flex: 1 }}>{props.controlPanel}</Box>;
                }
                const originalRenderFunc = column.renderCell;
                return originalRenderFunc
                  ? originalRenderFunc(params)
                  : undefined;
              },
              colSpan: ({ row }: { row: EntityTableRow }) => {
                if (row.id === CONTROL_PANEL_ID) {
                  return columns.length;
                }
                return undefined;
              }
            };
          }
          return column;
        })
        : props.columns;

    const firstColumn = columns?.[0].field ?? '';

    const pinnedRows = props.controlPanel
      ? {
        top: [{ id: CONTROL_PANEL_ID, [firstColumn]: props.controlPanel }]
      }
      : undefined;

    const onRowClick = (params: GridRowParams) => {
      props.onRowClick?.(params.row.id);
    };

    return (
      <>
        <Toolbar {...props} buttons={props.toolbarButtons} />
        <div className={styles.entityTable}>
          {props.sideFilter}
          <StyledDataGridPro
            columns={columns}
            rows={props.rows}
            pinnedRows={pinnedRows}
            getRowHeight={({ id }: GridRowHeightParams) => {
              if (id === CONTROL_PANEL_ID) {
                return CONTROL_PANEL_ROW_HEIGHT;
              }
              return null;
            }}
            disableRowSelectionOnClick
            hideFooter
            getRowClassName={(params) => (params.row.isNew ? 'row-isNew' : '')}
            onRowClick={onRowClick}
            onRowsScrollEnd={props.onScrollEnd}
          />
        </div>
      </>
    );
  };
