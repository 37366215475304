import {
  EOwnershipEntity,
  EOwnershipType,
  ExtendedOwnership,
  getOwnershipTypesForRole,
  isEOwnershipEntity,
  OwnershipQueryType,
  Role
} from "@sivis/identity/api";
import {useCustomIntl, useTranslateColumns} from "@sivis/intl";
import {
  DropdownOption,
  ListModifyViewUpdated
} from "@sivis/shared/components/entityView";
import {ConfirmationPopup} from "../components/popup/confirmationPopup";
import styles from "../role/role.module.scss";
import {
  mapTableRowToOwnership,
  onSearch,
  ownershipColumns,
  ownershipsToTableRow,
  useOwnershipMessages
} from "../ownership/ownershipUtils";
import {useState} from "react";
import {EntityType, useSingleEditMode} from "../components/breadcrumb/editModeContext";
import {createOwnershipPopup} from "../ownership/createOwnershipPopup";
import {useOwnershipApiForRole} from "../ownership/api/useOwnershipApiForRole";
import {RoleOwnershipDetails} from "../ownership/RoleOwnershipDetails";
import {useOwnershipsSideFilter} from "../ownership/components/useOwnershipsSideFilter";
import {isEqualById} from "@sivis/shared/utils";
import {parseValidityDateString} from "../utils/validityUtils";

interface RoleOwnershipViewProps {
  role: Role;
  editMode: boolean;
  onCancel?: () => void;
}

export const RoleOwnershipView = ({role, editMode, onCancel}: RoleOwnershipViewProps) => {
  const intl = useCustomIntl();
  const {deleteOwnershipMessage} = useOwnershipMessages();

  const getRoleOwnershipTypeFilterText = (type: string) => {
    if (type === EOwnershipType.ROLE_RESOURCE_SET_OWNERSHIP.valueOf()) {
      return intl.format("fusion.resourceSet.propertyName.name");
    } else if (type === EOwnershipType.ROLE_SYSTEM_OWNERSHIP) {
      return intl.format("fusion.resource.propertyName.systemName");
    } else if (type === EOwnershipType.IDENTITY_ROLE_OWNERSHIP) {
      return intl.format("fusion.identity.propertyName.name");
    }
    return intl.format("fusion.resource.propertyName.name");
  }

  const {
    activeFilter,
    filterComponent
  } = useOwnershipsSideFilter(OwnershipQueryType.ROLE_OWNERSHIPS, getRoleOwnershipTypeFilterText, role?.id, role?.id);
  const {
    onSave,
    submitted,
    setSubmitted,
    pageSize,
    useOwnershipsPageQuery,
    parseOwnershipsPageResult
  } = useOwnershipApiForRole(role, activeFilter ?? undefined);
  const [ownershipTargetType, setOwnershipTargetType] = useState<EOwnershipEntity>();

  const translatedColumns = useTranslateColumns(ownershipColumns, "fusion.ownership.propertyName");
  const shownColumns = editMode ? translatedColumns : translatedColumns.filter(column => column.field !== "action");
  const {toggleEditMode} = useSingleEditMode(EntityType.ROLE);

  const getSecondaryTitleId = (ownership: ExtendedOwnership) => {
    if (ownership.type === EOwnershipType.ROLE_RESOURCE_SET_OWNERSHIP) {
      return "fusion.ownership.ownershipPopup.secondaryTitle.roleResourceSet";
    } else if (ownership.type === EOwnershipType.ROLE_SYSTEM_OWNERSHIP) {
      return "fusion.ownership.ownershipPopup.secondaryTitle.roleSystem";
    } else if (ownership.type === EOwnershipType.IDENTITY_ROLE_OWNERSHIP) {
      return "fusion.ownership.ownershipPopup.secondaryTitle.targetOwned";
    }
    return "fusion.ownership.ownershipPopup.secondaryTitle.roleResource";
  }

  const getOwnershipPopupTitle = (ownership: ExtendedOwnership) => {
    const {type, targetName, validity} = ownership;
    let ownerName = "";
    if (role) {
      if (type !== EOwnershipType.IDENTITY_ROLE_OWNERSHIP) {
        ownerName = role.name ?? "";
      } else {
        ownerName = targetName ?? "";
      }
    }
    const targetEntity = type !== EOwnershipType.IDENTITY_ROLE_OWNERSHIP ? targetName ?? "" : (role?.name ?? "");

    return {
      primaryTitle: intl.format("fusion.ownership.ownershipPopup.title.edit", {
        owner: ownerName,
        target: targetEntity,
      }),
      secondaryTitle: intl.formatBold(getSecondaryTitleId(ownership), {
        owner: ownerName,
        target: targetEntity,
        validFrom: parseValidityDateString(validity?.validFrom),
        validTo: parseValidityDateString(validity?.validTo)
      })
    };
  };

  const confirmationPopup = role ? <ConfirmationPopup
    open={submitted}
    onClose={() => {
      setSubmitted(false);
      toggleEditMode();
    }}
    primaryTitle={`${intl.format("fusion.ownership.ownershipPopup.title")}${role.name}`}
    message={intl.format("fusion.message.submitted", {entity: role.name})}
  /> : null;

  let secondaryTitleId = "fusion.ownership.ownerResourceOwnership.createPopup.description";
  if (ownershipTargetType === EOwnershipEntity.RESOURCE_SET) {
    secondaryTitleId = "fusion.ownership.ownerResourceSetOwnership.createPopup.description";
  } else if (ownershipTargetType === EOwnershipEntity.SYSTEM) {
    secondaryTitleId = "fusion.ownership.ownerSystemOwnership.createPopup.description";
  } else if (ownershipTargetType === EOwnershipEntity.IDENTITY) {
    secondaryTitleId = "fusion.ownership.targetIdentityOwnership.createPopup.description";
  }

  const roleOwnershipDropdownMenuOptions: DropdownOption[] = [
    {
      entityToAdd: EOwnershipEntity.RESOURCE,
      label: intl.format("fusion.resource.propertyName.name")
    },
    {
      entityToAdd: EOwnershipEntity.RESOURCE_SET,
      label: intl.format("fusion.resourceSet.propertyName.name")
    },
    {
      entityToAdd: EOwnershipEntity.SYSTEM,
      label: intl.format("fusion.system.propertyName.systemName")
    },
    {
      entityToAdd: EOwnershipEntity.IDENTITY,
      label: intl.format("fusion.identity.propertyName.name")
    }
  ];

  const handleSelectDropdownOption = (option: DropdownOption | undefined) => {
    if (option && isEOwnershipEntity(option)) {
      setOwnershipTargetType(option);
    }
  };

  const createPopup = createOwnershipPopup({
    ownershipFor: EOwnershipEntity.ROLE,
    ownershipTargetType: ownershipTargetType,
    primaryTitle: intl.format("fusion.roleOwnership.add"),
    secondaryTitle: intl.formatBold(
      secondaryTitleId,
      ownershipTargetType !== EOwnershipEntity.IDENTITY ? {owner: role ? role.name : ""} : {target: role ? role.name : ""}
    ),
    owner: ownershipTargetType === EOwnershipEntity.IDENTITY ? undefined : role,
    target: ownershipTargetType === EOwnershipEntity.IDENTITY ? role : undefined
  });

  return <div className={styles.listContainer}>
    {confirmationPopup}
    <ListModifyViewUpdated
      columns={shownColumns}
      onSave={onSave}
      entityToTableRow={ownershipsToTableRow(getOwnershipTypesForRole, intl.format)}
      onSearch={onSearch}
      editMode={editMode}
      getEntityTitle={getOwnershipPopupTitle}
      isEqual={isEqualById}
      entityDetails={RoleOwnershipDetails}
      deleteMessage={deleteOwnershipMessage}
      mapTableRowToEntity={mapTableRowToOwnership}
      pageSize={pageSize}
      usePageQuery={useOwnershipsPageQuery}
      parseResult={parseOwnershipsPageResult}
      createButtonText="fusion.ownership.add"
      createPopup={createPopup}
      tableType={OwnershipQueryType.ROLE_OWNERSHIPS}
      useToolbarButtonsWithOptions={true}
      dropdownMenuItems={roleOwnershipDropdownMenuOptions}
      setSelectedDropdownOption={handleSelectDropdownOption}
      sideFilter={filterComponent}
      onCancel={onCancel}
    />
  </div>
};
