import {
  selectHome,
  selectSidebarClosed,
  sidebarToggle,
} from '@sivis/app-shell';
import { IntlTooltip, useCustomIntl } from '@sivis/intl';
import { ELogoPlace, Logo } from '@sivis/shared/components/logo';
import { useRegistryNavigator } from '@sivis/shared/components/registry';
import React, { FunctionComponent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styles from './Header.module.scss';
import { DEFAULT_HEADER_TITLE_INTL_ID, HeaderItems } from './HeaderItems';
import SideBarToggleButton from './SideBarToggleButton';

interface HeaderProps {
  alwaysShowSidebar?: boolean;
  newDesign?: boolean;
}

const Header: FunctionComponent<HeaderProps> = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const intl = useCustomIntl();
  const registryNavigate = useRegistryNavigator();

  const sidebarClosed = useSelector(selectSidebarClosed);
  const home = useSelector(selectHome);

  const onLogoClick = () => {
    home && registryNavigate(home);
  };

  const toggleSidebar = () => {
    dispatch(sidebarToggle());
  };

  const onToggleButtonKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' || e.key === ' ') {
      toggleSidebar();
    }
  };

  const handleBackNavigation = () => {
    navigate(-1);
  };

  const backArrow = (
    <IntlTooltip intlId="general.back">
      <div className={styles.backArrow} onClick={handleBackNavigation}>
        <div className={styles.innerArrow}></div>
      </div>
    </IntlTooltip>
  );

  const sidebarClasses = [
    props.newDesign ? styles.newDesign : '',
    styles.header,
  ]
    .filter(Boolean)
    .join(' ');

  return (
    <div className={sidebarClasses} role="navigation">
      {!props.alwaysShowSidebar && (
        <SideBarToggleButton
          clicked={toggleSidebar}
          isClosed={sidebarClosed}
          keyDown={onToggleButtonKeyDown}
        />
      )}
      <div
        className={styles.company}
        onClick={onLogoClick}
        onKeyDown={(e) => (e.key === 'Enter' || e.key === ' ') && onLogoClick()}
        role="button"
        tabIndex={0}
        aria-label={intl.formatMappedMessage(DEFAULT_HEADER_TITLE_INTL_ID)}
      >
        <Logo
          place={ELogoPlace.NAVBAR}
          alt="Logo"
          className={styles.sivislogo}
        />
      </div>
      <HeaderItems></HeaderItems>
      {backArrow}
    </div>
  );
};

export default Header;
