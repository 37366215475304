import {
  FilterEntry,
  FilterExpression,
  RuleStatus,
  SideFilterQueryType,
  useGetBusinessRulesQuery,
  useGetBusinessRuleStatusFilterEntriesQuery
} from '@sivis/identity/api';
import { useEffect, useState } from 'react';
import { useCustomIntl } from '@sivis/intl';
import { compareFilterEntries } from './useOwnershipsSideFilter';
import { resetTable, SideFilterNew } from '@sivis/shared/components/entityView';
import { useDispatch } from 'react-redux';
import { rulesDraftFilter } from '../../rule/api/useBusinessRulesPageApi';
import { mapRuleStatusStringToIntlId, mapToEnum } from '../../rule/ruleUtils';

export const useRulesSideFilter = () => {
  const [filter, setFilter] = useState<FilterExpression | null>(null);
  const [statusFilters, setStatusFilters] = useState<FilterEntry[]>([]);
  const intl = useCustomIntl();

  const { data: dataRulesStatusFilters } = useGetBusinessRuleStatusFilterEntriesQuery();
  const { data: dataRules } = useGetBusinessRulesQuery({ filter: rulesDraftFilter });

  useEffect(() => {
    const newStatusFilters = (dataRulesStatusFilters?.businessRuleStatusFilterEntries?.filter(entry => entry != null) ?? []) as FilterEntry[];
    newStatusFilters.sort(compareFilterEntries);
    setStatusFilters(newStatusFilters);
  }, [dataRulesStatusFilters?.businessRuleStatusFilterEntries, dataRules]);

  const dispatch = useDispatch();
  const onSetFilter = (newFilter: FilterExpression | null) => {
    dispatch(resetTable(SideFilterQueryType.RULES)());
    setFilter(newFilter);
  };

  const buildRuleStatusFilterGroup = (filters: FilterEntry[], title: string) => ({
    id: 'status',
    title,
    filters: filters.map(entry => ({
      id: entry.id,
      text: intl.format(mapRuleStatusStringToIntlId(entry.id)),
      count: entry.count,
      filter: {
        name: 'status',
        operator: 'EQ',
        enumValue: mapToEnum(RuleStatus, entry.id)
      }
    }))
  });

  const statusFilter = <SideFilterNew
    title={(intl.format('fusion.general.filter')).toUpperCase()}
    onFilterChange={onSetFilter}
    filterGroups={[
      buildRuleStatusFilterGroup(statusFilters, intl.format('fusion.rule.propertyName.status'))
    ]}
  />;

  return {
    activeFilter: filter,
    filterComponent: statusFilter
  };

};
