import React from 'react';
import { Box, Typography } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import UnpublishedOutlinedIcon from '@mui/icons-material/UnpublishedOutlined';
import { useCustomIntl } from '@sivis/intl';
import styles from './draft.module.scss';

interface DraftStatusBarProps {
  isActive: boolean;
  draftData: boolean;
  additionalInformationLabel: string;
  additionalInformationText: string;
}

const DraftStatusBar: React.FC<DraftStatusBarProps> = ({
                                                         isActive,
                                                         draftData,
                                                         additionalInformationText,
                                                         additionalInformationLabel
                                                       }) => {
  const intl = useCustomIntl();

  // TODO: fix "Draft: Published" (when published it is no longer a draft)
  return (
    <Box className={styles.statusBar}>
      <Typography className={styles.statusElement}>
        <span className={styles.statusText}>
          {intl.format('fusion.general.status')}:
        </span>{' '}
        {isActive
          ? intl.format('fusion.general.active')
          : intl.format('fusion.general.deactivated')}
        {isActive && <CheckCircleOutlineIcon className={styles.statusIcon} />}
        {!isActive && <UnpublishedOutlinedIcon className={styles.statusIcon} />}
      </Typography>
      <Typography className={styles.statusElement}>
        <span className={styles.statusText}>
          {intl.format('fusion.general.draft')}:
        </span>{' '}
        {draftData
          ? intl.format('fusion.general.unpublished')
          : intl.format('fusion.general.published')}
      </Typography>
      <Typography className={styles.statusElement}>
        <span className={styles.statusText}>
          {intl.format(additionalInformationLabel)}:
        </span>{' '}
        {additionalInformationText}{' '}
      </Typography>
    </Box>
  );
};

export default DraftStatusBar;
