import { ThemeOptions } from '@mui/material';
import {
  getCurrentDomStyles,
  IStylesState,
} from '@sivis/shared/theme';

import { pointsharpThemeOptions } from '@pointsharp/component-library';

export const initializePointsharpTheme = (): IStylesState => {

  const styles = getCurrentDomStyles();

  const themeOptions: ThemeOptions = {
    ...(pointsharpThemeOptions as ThemeOptions),
  };

  return {
    themeType: 'light',
    themeOptions,
    styles,
    loading: false,
    error: null,
    isHelpButtonVisible: false,
  };
};