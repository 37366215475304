import { IntlButton, useCustomIntl } from '@sivis/intl';
import { Card, Typography } from '@mui/material';
import styles from './rule.module.scss';
import { Stack } from '@mui/system';
import { CriterionForm, CriterionFormHandle } from './criterionForm';
import { getOperatorTextBold, WatchValues } from './ruleUtils';
import { CriteriaGroup, RuleLogicalOperator } from '@sivis/identity/api';
import { forwardRef, useImperativeHandle, useRef } from 'react';

interface CriteriaGroupProps {
  group: CriteriaGroup;
  index: number;
  operatorOutsideGroup: RuleLogicalOperator;
  onAddNewCriterion: (groupId: string) => void;
  onDeleteCriterion: (groupId: string, criterionId: string) => void;
  onDeleteCriteriaGroup: (groupId: string) => void;
  setWatchValues: (value: (((prevState: WatchValues) => WatchValues) | WatchValues)) => void;
  onSave: (value: Record<string, any>) => void;
}

export type CriteriaGroupCardHandle = {
  handleSave: () => void;
};

export const CriteriaGroupCard = forwardRef<CriteriaGroupCardHandle, CriteriaGroupProps>(({
                                                                                            group,
                                                                                            index,
                                                                                            operatorOutsideGroup,
                                                                                            onAddNewCriterion,
                                                                                            onDeleteCriterion,
                                                                                            onDeleteCriteriaGroup,
                                                                                            setWatchValues,
                                                                                            onSave
                                                                                          }, ref) => {
  const intl = useCustomIntl();
  const criteriaGroupTitle = intl.format('fusion.rule.criteriaGroupWithIndex', { index: index + 1 });
  const operatorInsideGroup = operatorOutsideGroup === RuleLogicalOperator.And ? RuleLogicalOperator.Or : RuleLogicalOperator.And;
  const criterionFormRefs = useRef<(CriterionFormHandle | null)[]>([]);

  useImperativeHandle(ref, () => ({
    handleSave: async () => {
      const validStates = await Promise.all(
        criterionFormRefs.current.map(ref => ref?.submit() ?? Promise.resolve(true))
      );
      return validStates.every(Boolean);
    }
  }));

  return (
    <Card
      className={styles.ruleMargin}
      key={group.id}
      title={criteriaGroupTitle}
      variant={'outlined'}
    >
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography className={styles.criteriaGroupTitle}>
          {criteriaGroupTitle}
        </Typography>
        <IntlButton
          className={styles.deleteCriteriaGroupButton}
          intlId={'fusion.general.delete.uppercase'}
          onClick={() => onDeleteCriteriaGroup(group.id)}
        />
      </Stack>
      {group.criteriaInsideGroup.map((criterion, criterionIndex) => (
        <div key={criterion.id}>
          <CriterionForm
            ref={(el) => (criterionFormRefs.current[criterionIndex] = el)}
            key={criterion.id}
            criterion={criterion}
            groupId={group.id}
            onDeleteCriterion={onDeleteCriterion}
            onSave={onSave}
            setWatchValues={setWatchValues}
          />
          {criterionIndex < group.criteriaInsideGroup.length - 1 && (
            <div className={styles.ruleOperator}>
              {getOperatorTextBold(operatorInsideGroup, intl.formatBold)}
            </div>
          )}
        </div>
      ))}
      <IntlButton className={styles.ruleMargin}
                  variant="outlined"
                  intlId={'fusion.rule.addCriterion'}
                  onClick={() => {
                    onAddNewCriterion(group.id);
                  }}
      />
    </Card>
  );
});
