import styles from './role.module.scss';
import { EntityTableRow, ListModifyViewUpdated } from '@sivis/shared/components/entityView';
import { IconButton } from '@mui/material';
import { identityName, IdentityRoleMembership, RelationQueryType, Role } from '@sivis/identity/api';
import { useIdentityRoleMembershipsApi } from '../membership/api/useIdentityRoleMembershipsApi';
import { GridColDef } from '@mui/x-data-grid-pro';
import EditIcon from '@mui/icons-material/Edit';
import ClearIcon from '@mui/icons-material/Clear';
import { IdentityRoleMembershipDetails } from '../membership/identityRoleMembershipDetails';
import {
  deleteMembershipMessage,
  getAccountsText,
  getMembershipFlags,
  mapTableRowToMembership
} from '../membership/membershipUtils';
import { useCustomIntl, useTranslateColumns } from '@sivis/intl';
import { ConfirmationPopup } from '../components/popup/confirmationPopup';
import { createMembershipPopup } from '../membership/createMembershipPopup';
import { isEqualById } from '@sivis/shared/utils';

interface RoleIdentityViewProps {
  role: Role;
  editMode: boolean;
  onCancel?: () => void;
}

const columns: GridColDef[] = [
  {
    field: "name",
    flex: 1
  },
  {
    field: "email",
    flex: 1
  },
  {
    field: "accounts",
    flex: 1
  },
  {
    field: "flags",
    flex: 1,
    sortable: false,
    renderCell: params => params.row.flags
  },
  {
    field: "action",
    width: 100,
    align: "right",
    sortable: false,
    renderCell: params => params.row.action
  }
];

export const RoleIdentityView = ({role, editMode, onCancel}: RoleIdentityViewProps) => {
  const intl = useCustomIntl();
  const {
    onSave,
    submitted,
    setSubmitted,
    onSearch,
    pageSize,
    useMembershipsPageQuery,
    parseMembershipsPageResult
  } = useIdentityRoleMembershipsApi(role);

  const translatedColumns = useTranslateColumns(columns, "fusion.identity.role.propertyName");

  const entityToTableRow = (
      entity: IdentityRoleMembership,
      isNew: boolean,
      onEdit?: (entity: IdentityRoleMembership) => void,
      onDelete?: (entity: IdentityRoleMembership) => void
  ): EntityTableRow => {
    return {
      id: entity.id,
      name: entity.identity.firstName + " " + entity.identity.lastName,
      email: entity.identity.primaryEmail ?? "",
      isNew: isNew,
      accounts: getAccountsText(entity.allAccounts ?? undefined, intl.format),
      flags: getMembershipFlags(entity, intl.format),
      action: <div className={styles.deleteButtonContainer}>
        <IconButton
            onClick={(e) => {
              e.stopPropagation();
              onEdit?.(entity);
            }}
            aria-label={intl.format("general.edit")}>
          <EditIcon/>
        </IconButton>
        <IconButton
            onClick={(e) => {
              e.stopPropagation();
              onDelete?.(entity);
            }}
            aria-label={intl.format("general.delete")}>
          <ClearIcon/>
        </IconButton>
      </div>
    };
  };

  const getMembershipPopupTitle = (membership: IdentityRoleMembership) => ({
    primaryTitle: intl.format(
      "fusion.membership.membershipPopup.title",
      {name: identityName(membership.identity)}
    ),
    secondaryTitle: intl.format(
      "fusion.membership.membershipPopup.description",
      {identity: identityName(membership.identity), role: membership.role.name}
    )
  });

  const shownColumns = editMode ? translatedColumns : translatedColumns.filter(column => column.field !== "action");

  const confirmationPopup = role ? <ConfirmationPopup
      open={submitted}
      onClose={() => setSubmitted(false)}
      primaryTitle={intl.format("fusion.membership.membershipPopup.title", {name: role.name})}
      message={intl.format("fusion.message.submitted", {entity: role.name})}
  /> : null;

  const createPopup = createMembershipPopup({
    primaryTitle: intl.format("fusion.role.identity.createPopup.title"),
    secondaryTitle: intl.format(
        "fusion.role.identity.createPopup.description",
        {role: role ? role.name : ""}
    ),
    role
  });

  return <div className={styles.listContainer}>
    {confirmationPopup}
    <ListModifyViewUpdated
        columns={shownColumns}
        onSave={onSave}
        entityToTableRow={entityToTableRow}
        onSearch={onSearch}
        editMode={editMode}
        getEntityTitle={getMembershipPopupTitle}
        isEqual={isEqualById}
        entityDetails={IdentityRoleMembershipDetails}
        deleteMessage={deleteMembershipMessage}
        mapTableRowToEntity={mapTableRowToMembership}
        pageSize={pageSize}
        usePageQuery={useMembershipsPageQuery}
        parseResult={parseMembershipsPageResult}
        createButtonText="fusion.role.identity.add"
        createPopup={createPopup}
        tableType={RelationQueryType.IDENTITY_ROLE_MEMBERSHIPS}
        onCancel={onCancel}
    />
  </div>;
};
