import {
  DeleteButton,
  EditButton,
  EntityInformation,
  EntityView,
} from "@sivis/shared/components/entityView";
import styles from "./role.module.scss";
import {useRoleApi} from "./useRoleApi";
import {RoleIdentityView} from "./roleIdentityView";
import {Outlet, useOutletContext} from "react-router-dom";
import {Role, RoleInput} from "@sivis/identity/api";
import {
  IDENTITIES_TAB_PATHNAME,
  INFORMATION_TAB_PATHNAME,
  OWNERSHIP_TAB_PATHNAME,
  RESOURCES_TAB_PATHNAME,
  ROLE_URL
} from '../components/routes/constants';
import {RoleResourceView} from "./roleResourceView";
import {useCustomIntl, useTranslateConfigs} from "@sivis/intl";
import {getTranslatedErrorMessage} from "@sivis/shared/api-utils";
import {RoleOwnershipView} from "./roleOwnershipView";
import {useRouteRedirect} from '../common/useRouteRedirect';


const configs = [
  {
    property: 'name',
    required: true
  },
  {
    property: 'description',
  }
];

export const RoleView = () => {
  useRouteRedirect({ basePath: ROLE_URL, subPath: INFORMATION_TAB_PATHNAME });

  const intl = useCustomIntl();
  const {
    defaultInput,
    role,
    error,
    isNew,
    onSave,
    onCancel,
    onDelete,
    editMode,
    toggleEditMode
  } = useRoleApi();

  const informationTab = {
    label: intl.format("fusion.breadcrumb.information"),
    path: INFORMATION_TAB_PATHNAME
  };
  const tabs = isNew ? [informationTab] : [
    informationTab,
    {label: intl.format("fusion.identity.list.name"), path: IDENTITIES_TAB_PATHNAME},
    {label: intl.format("fusion.resource.list.name"), path: RESOURCES_TAB_PATHNAME},
    {label: intl.format("fusion.ownership.list.name"), path: OWNERSHIP_TAB_PATHNAME}
  ];

  const titleExisting = `${intl.format("fusion.role.information.titleExisting")}${role?.name}`;
  const title = isNew ? intl.format("fusion.role.information.titleNew") : titleExisting;
  const buttons = isNew ? undefined : [
    <EditButton disabled={editMode} onClick={toggleEditMode} key="edit"/>,
    <DeleteButton onClick={onDelete} key="delete"/>
  ];

  return <EntityView
    title={title}
    tabs={tabs}
    actionButtons={buttons}
    error={error ? getTranslatedErrorMessage(error, "fusion.role.error.notFound", intl.format) : undefined}>
    <Outlet context={{editMode, role, defaultInput, onSave, onCancel}}/>
  </EntityView>;
};

type ContextType = {
  editMode: boolean;
  role: Role;
  defaultInput: RoleInput;
  onSave: (value: Record<string, any>) => void;
  onCancel: () => void;
};

export const RoleInformationTab = () => {
  const {editMode, defaultInput, onSave, onCancel} = useOutletContext<ContextType>();

  const translatedConfigs = useTranslateConfigs(configs, "fusion.role.propertyName");

  const entityDetails = <EntityInformation
    editMode={editMode}
    configs={translatedConfigs}
    value={defaultInput}
    onSubmit={onSave}
    onCancel={onCancel}
  />;

  return <div className={styles.listContainer}>
    {entityDetails}
  </div>;
};

export const RoleIdentityTab = () => {
  const {editMode, onCancel, role} = useOutletContext<ContextType>();
  return <RoleIdentityView role={role} editMode={editMode} onCancel={onCancel}/>;
};

export const RoleResourceTab = () => {
  const {editMode, onCancel, role} = useOutletContext<ContextType>();
  return <RoleResourceView role={role} editMode={editMode} onCancel={onCancel}/>;
};

export const RoleOwnershipTab = () => {
  const {editMode, onCancel, role} = useOutletContext<ContextType>();
  return <RoleOwnershipView role={role} editMode={editMode} onCancel={onCancel}/>;
}
