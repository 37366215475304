import { EAuthProvider } from '@sivis/shared/types';
import { useDispatch, useSelector } from 'react-redux';
import { authenticateAdmin, selectAuthError, selectAuthLoading } from '../../redux/authSlice';
import { LOGIN_URL } from './sivisWebApi';

export const useLoginConfigs = () => {
  const authError = useSelector(selectAuthError);
  const errors = {
    authError
  };
  const loading = useSelector(selectAuthLoading);

  const dispatch = useDispatch();
  const onAdminLogin = (username: string, password: string) => dispatch(authenticateAdmin({
    username,
    password
  }));

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const doNothing = () => {
  };

  return {
    authProvider: EAuthProvider.ADMIN,
    alternativeAuthProvider: EAuthProvider.NONE,
    adminEnabled: true,
    errors,
    directSSO: false,
    onLogoClick: doNothing,
    goToLink: 'https://www.pointsharp.com/',
    springConnected: true,
    sapSystems: [],
    showResetPassword: false,
    resetPasswordLink: '',
    isPasswordInvalid: false,
    loading,
    onAdminLogin: onAdminLogin,
    onUserLogin: doNothing,
    onSamlLogin: doNothing,
    loginUrl: LOGIN_URL
  };
};
