import React, { FunctionComponent } from 'react';
import styles from './Sidebar.module.scss';
import { useSelector } from 'react-redux';
import { selectSidebarClosed } from './sidebarSlice';

interface SidebarProps {
  children: React.ReactNode;
  newDesign?: boolean;
}

const Sidebar: FunctionComponent<SidebarProps> = (props) => {
  const closed = useSelector(selectSidebarClosed);

  const sidebarClasses = [
    props.newDesign ? styles.newDesign : '',
    closed ? styles.closed : '',
  ]
    .filter(Boolean)
    .join(' ');

  return <div id={styles.evoSideNavbar} className={sidebarClasses} role="complementary">
    {props.children}
  </div>;
};

export default Sidebar;
