import { pointsharpThemeOptions } from '@pointsharp/component-library';
import { IntlButton } from '@sivis/intl';
import styles from './form.module.scss';

export interface FormButtonsProps {
  /**
   * Handle submit automatically with a form if onSave is not set
   */
  onSave?: () => void;
  onCancel?: () => void;
  onDelete?: () => void;
  onSaveDraft?: (value: Record<string, any>) => void;
  disabled?: boolean;
  saveInsteadOfSubmit?: boolean;
  isDraftable?: boolean;
  cancelDisabled?: boolean;
}

export const FormButtons = (props: FormButtonsProps) => {
  const style = {
    button: {
      backgroundColor: pointsharpThemeOptions?.palette?.brand?.primary,
    },
    cancelButton: {
      backgroundColor: pointsharpThemeOptions?.palette?.background?.almostWhite,
      color: pointsharpThemeOptions?.palette?.background?.almostBlack,
    },
  };

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const onSaveFallback = () => {};
  let saveButtonIntlId = 'general.submit';
  if (props.saveInsteadOfSubmit) {
    saveButtonIntlId = 'general.save';
  } else if (props.isDraftable) {
    saveButtonIntlId = 'general.publish';
  }

  const isCancelDisabled =
    props.cancelDisabled !== undefined ? props.cancelDisabled : props.disabled;
  return (
    <div className={styles.entityFooter}>
      <IntlButton
        variant="contained"
        type="submit"
        disabled={props.disabled}
        onClick={props.onSave ?? onSaveFallback}
        intlId={saveButtonIntlId}
        sx={style.button}
      />
      {props.onDelete ? (
        <IntlButton
          variant="contained"
          onClick={props.onDelete}
          color="error"
          disabled={props.disabled}
          intlId="general.delete"
          sx={style.button}
        />
      ) : null}
      {props.isDraftable ? (
        <IntlButton
          variant="contained"
          onClick={props.onSaveDraft}
          disabled={props.disabled}
          intlId="general.saveDraft"
          sx={style.button}
        />
      ) : null}
      <IntlButton
        variant="text"
        onClick={props.onCancel}
        disabled={isCancelDisabled}
        intlId="general.cancel"
        sx={style.cancelButton}
      />
    </div>
  );
};
