import {DeleteButton, EditButton, EntityView} from "@sivis/shared/components/entityView";
import ResourceSetResourcesTab from "./ResourceSetResourcesTab";
import ResourceSetInformationTab from "./resourceSetInformationTab";
import {useResourceSetApi} from "./useResourceSetApi";
import {ResourceSet, ResourceSetInput} from "@sivis/identity/api";
import {Outlet, useOutletContext} from "react-router-dom";
import {
  IDENTITIES_TAB_PATHNAME,
  INFORMATION_TAB_PATHNAME,
  OWNERSHIP_TAB_PATHNAME,
  RESOURCE_SET_URL,
  RESOURCES_TAB_PATHNAME,
  ROLES_TAB_PATHNAME
} from '../components/routes/constants';
import {useCustomIntl} from "@sivis/intl";
import {getTranslatedErrorMessage} from "@sivis/shared/api-utils";
import {ResourceSetOwnershipTab} from "./ResourceSetOwnershipTab";
import {useRouteRedirect} from '../common/useRouteRedirect';

// TODO: clarify if resources can be added to identities via resource set as well as via direct entitlement
export const ResourceSetView = () => {
  useRouteRedirect({ basePath: RESOURCE_SET_URL, subPath: INFORMATION_TAB_PATHNAME });

  const intl = useCustomIntl();
  const {
    resourceSet,
    resourceSetInput,
    error,
    onSave,
    onDelete,
    onCancel,
    isNew,
    editMode,
    toggleEditMode,
  } = useResourceSetApi();

  const handleOnSubmit = (value: Record<string, any>) => {
    onSave(value as ResourceSetInput);
  };

  const informationTab = {
    label: intl.format("fusion.breadcrumb.information"),
    path: INFORMATION_TAB_PATHNAME
  };
  const identitiesTab = {
    label: intl.format("fusion.identity.list.name"),
    path: IDENTITIES_TAB_PATHNAME
  };
  const rolesTab = {label: intl.format("fusion.role.list.name"), path: ROLES_TAB_PATHNAME};
  const resourcesTab = {
    label: intl.format("fusion.resource.list.name"),
    path: RESOURCES_TAB_PATHNAME
  };
  const ownershipTab = {
    label: intl.format("fusion.ownership.list.name"),
    path: OWNERSHIP_TAB_PATHNAME
  };

  const tabs = isNew ? [informationTab] : [informationTab, identitiesTab, rolesTab, resourcesTab, ownershipTab];
  const titleExisting = `${intl.format("fusion.resourceSet.information.titleExisting")}${resourceSet?.name}`;
  const title = isNew ? intl.format("fusion.resourceSet.information.titleNew") : titleExisting;
  const buttons = isNew ? undefined : [
    <EditButton disabled={editMode} onClick={toggleEditMode} key="edit"/>,
    <DeleteButton onClick={onDelete} key="delete"/>
  ];

  return <EntityView
    title={title}
    tabs={tabs}
    actionButtons={buttons}
    error={error ? getTranslatedErrorMessage(error, "fusion.resourceSet.error.notFound", intl.format) : undefined}>
    <Outlet context={{resourceSet, resourceSetInput, handleOnSubmit, onCancel, editMode}}/>
  </EntityView>;
};

type ContextType = {
  resourceSet: ResourceSet
  resourceSetInput: ResourceSetInput,
  handleOnSubmit: (value: Record<string, any>) => void,
  onCancel: () => void,
  editMode: boolean,
};

export const ResourceSetInformationTabWrapperComponent = () => {
  const {resourceSetInput, handleOnSubmit, onCancel, editMode} = useOutletContext<ContextType>();
  return <ResourceSetInformationTab
    resourceSetInput={resourceSetInput}
    handleOnSubmit={handleOnSubmit}
    onCancel={onCancel}
    editMode={editMode}
  />;
};

export const ResourceSetResourcesTabWrapperComponent = () => {
  const {resourceSet, editMode} = useOutletContext<ContextType>();
  return <ResourceSetResourcesTab
    resourceSet={resourceSet}
    editMode={editMode}
  />;
};

export const ResourceSetOwnershipTabWrapperComponent = () => {
  const {resourceSet, editMode, onCancel } = useOutletContext<ContextType>();
  return <ResourceSetOwnershipTab
    resourceSet={resourceSet}
    editMode={editMode} onCancel={onCancel}
  />;
}

