import { Outlet, RouteObject } from 'react-router-dom';
import { AccountTypeList } from './accountTypeList';
import {
  AccountTypeAccountsTabWrapperComponent,
  AccountTypeInformationTabWrapperComponent,
  AccountTypeView
} from './accountTypeView';
import {
  DynamicEntityViewBreadcrumb,
  intlSimpleBreadcrumbEditMode
} from '../components/breadcrumb/breadcrumb';
import { ACCOUNTS_TAB_PATHNAME, INFORMATION_TAB_PATHNAME } from '../components/routes/constants';
import { EntityType } from '../components/breadcrumb/editModeContext';
import { useGetAccountTypeQuery } from '@sivis/identity/api';

const useGetAccountTypeName = (id: string, isNew: boolean) => {
  const { data } = useGetAccountTypeQuery({ id }, { skip: isNew });
  return data?.accountType?.name;
};

export const accountTypeRoutes: RouteObject[] = [
  {
    index: true,
    element: <AccountTypeList />
  },
  {
    path: ":id",
    element: <Outlet />,
    handle: {
      crumb: DynamicEntityViewBreadcrumb({
        useGetEntityName: useGetAccountTypeName,
        pathSuffix: "/" + INFORMATION_TAB_PATHNAME
      })
    },
    children: [
      {
        index: true,
        element: <AccountTypeView />,
      },
      {
        element: <AccountTypeView />,
        children: [
          {
            path: INFORMATION_TAB_PATHNAME,
            element: <AccountTypeInformationTabWrapperComponent />,
            handle: {
              crumb: intlSimpleBreadcrumbEditMode({
                intlId: "fusion.breadcrumb.information",
                intlIdEdit: "fusion.breadcrumb.information.edit",
                entity: EntityType.ACCOUNT_TYPE
              })
            }
          },
          {
            path: ACCOUNTS_TAB_PATHNAME,
            element: <AccountTypeAccountsTabWrapperComponent />,
            handle: {
              crumb: intlSimpleBreadcrumbEditMode({
                intlId: 'fusion.breadcrumb.fieldDefinitions',
                intlIdEdit: "fusion.breadcrumb.accounts.edit",
                entity: EntityType.ACCOUNT
              })
            }
          }
        ]
      }
    ]
  },
];
