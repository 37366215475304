import { Alert } from '@mui/material';
import {
  CursorBasedPageResult,
  EntityTableRow,
  InfiniteScrollTableCursor,
  TableType
} from './components/table';
import { ReactNode, useState } from 'react';
import { FormButtons } from './components/form/FormButtons';
import { GridColDef } from '@mui/x-data-grid-pro';
import { Popup } from './components/popup/popup';
import { containsListItem } from './components/utils';
import { useCustomIntl } from '@sivis/intl';
import { EntityDetailsProps } from './listModifyView';
import {
  TypedUseQueryHookResult,
  UseQueryHookResult
} from '@reduxjs/toolkit/dist/query/react/buildHooks';
import { CreateButtonWithIcon } from './components/actionButtons/actionButtons';
import {
  CreateButtonWithDropdown,
  DropdownOption
} from './components/actionButtons/CreateButtonWithDropdown';

export interface CreatePopupProps<ListItem> {
  open: boolean;
  onClose: () => void;
  onSave?: (added: ListItem[]) => void;
}

interface ListModifyViewUpdatedProps<ListItem, QueryResult> {
  columns: GridColDef[];
  entityToTableRow: (entity: ListItem, isNew: boolean, onEdit?: (entity: ListItem) => void,
                     onDelete?: (entity: ListItem) => void) => EntityTableRow;
  onCancel?: () => void;
  onSave?: (toAdd: ListItem[], toDelete: ListItem[]) => Promise<void>;
  onSearch: (searchStr: string) => void;
  editMode: boolean;
  getEntityTitle: (entity: ListItem) => {
    primaryTitle: string;
    secondaryTitle: JSX.Element | string | ReactNode;
  };
  isEqual: (a: ListItem, b: ListItem) => boolean;
  entityDetails: React.FunctionComponent<EntityDetailsProps<ListItem>>;
  deleteMessage?: string;
  // To find the chosen entity on row click (e.g. rowId is composite for membership and not readable)
  mapTableRowToEntity: (rowId: string, entity: ListItem) => boolean;
  toolbarButtons?: JSX.Element[];
  createButtonText?: string;
  createPopup?: ((props: CreatePopupProps<ListItem>) => JSX.Element);
  pageSize: number;
  usePageQuery: (cursor: string | null) => (TypedUseQueryHookResult<QueryResult, any, any> | UseQueryHookResult<any, any>);
  parseResult: (res?: QueryResult) => CursorBasedPageResult<ListItem>;

  tableType?: TableType;
  sideFilter?: JSX.Element;
  useToolbarButtonsWithOptions?: boolean;
  dropdownMenuItems?: DropdownOption[];
  setSelectedDropdownOption?: (option: DropdownOption | undefined) => void;
}

/**
 * ListModifyView with pagination.
 * TODO: Remove old ListModifyView and refactor components to use this.
 */
export const ListModifyViewUpdated = <ListItem, QueryResult>(props: ListModifyViewUpdatedProps<ListItem, QueryResult>) => {
  const intl = useCustomIntl();
  // Rows are kept in state here and in InfiniteScrollTable.
  // Should be refactored if it causes performance issue.
  const [rows, setRows] = useState<ListItem[]>([]);
  const [addedEntities, setAddedEntities] = useState<ListItem[]>([]);
  const [deletedEntities, setDeletedEntities] = useState<ListItem[]>([]);
  const [openAddPopup, setOpenAddPopup] = useState(false);
  const [openEditPopup, setOpenEditPopup] = useState<ListItem | null>(null);
  const [openDeletePopup, setOpenDeletePopup] = useState<ListItem | null>(null);
  const isEqual = props.isEqual;

  const renderRows = (entities: ListItem[]) => {
    return [
      ...addedEntities.map(entity => props.entityToTableRow(entity, true, onOpenEditPopup, onOpenDeletePopup)),
      ...entities.filter(entity => !isDeleted(entity))
      .map(entity => props.entityToTableRow(entity, false, onOpenEditPopup, onOpenDeletePopup))
    ];
  };

  const modified = addedEntities.length > 0 || deletedEntities.length > 0;
  const isAdded = containsListItem(addedEntities, isEqual);
  const isDeleted = containsListItem(deletedEntities, isEqual);
  const isExisting = containsListItem(rows, isEqual);

  const onAddEntities = (toAdd: ListItem[]) => {
    setAddedEntities([...addedEntities, ...toAdd.filter(item => !isAdded(item) && !isExisting(item))]);
    closeAddPopup();
  };

  const editAddedEntity = (toEdit: ListItem) => {
    if (isAdded(toEdit)) {
      setAddedEntities([...addedEntities.filter(added => !isEqual(added, toEdit)), toEdit]);
    }
  };

  // Ask for confirmation only for saved membership
  const onOpenDeletePopup = (toDelete: ListItem) => {
    if (isAdded(toDelete)) {
      onDelete(toDelete);
    } else if (isExisting(toDelete)) {
      setOpenDeletePopup(toDelete);
    }
  };

  const onDelete = (toDelete: ListItem) => {
    if (!isDeleted(toDelete) && isExisting(toDelete)) {
      setDeletedEntities([...deletedEntities, toDelete]);
    } else if (!isDeleted(toDelete) && isAdded(toDelete)) {
      setAddedEntities(addedEntities.filter(added => !isEqual(added, toDelete)));
    }
  };

  const onOpenEditPopup = (toEdit: ListItem) => {
    if (isAdded(toEdit) || isExisting(toEdit)) {
      setOpenEditPopup(toEdit);
    }
  };

  const onRowClick = (id: string) => {
    const foundEntity = addedEntities.find(added => props.mapTableRowToEntity(id, added)) ??
      rows.find(existed => props.mapTableRowToEntity(id, existed));
    if (foundEntity) {
      setOpenEditPopup(foundEntity);
    }
  };

  const reset = () => {
    setAddedEntities([]);
    setDeletedEntities([]);
    if (props.onCancel) {
      props.onCancel();
    }
  };

  const save = () => {
    if (modified) {
      props.onSave?.(addedEntities, deletedEntities).then(reset);
    }
  };
  const closeAddPopup = () => {
    setOpenAddPopup(false);
    if (props.setSelectedDropdownOption) {
      props.setSelectedDropdownOption(undefined);
    }
  }

  const closeDeletePopup = () => setOpenDeletePopup(null);
  const closeEditPopup = () => setOpenEditPopup(null);

  const confirmDelete = () => {
    if (openDeletePopup) {
      onDelete(openDeletePopup ?? "");
    }
    closeDeletePopup();
  };

  const deleteEntityTitle = openDeletePopup ? props.getEntityTitle(openDeletePopup) : undefined;
  const editEntityTitle = openEditPopup ? props.getEntityTitle(openEditPopup) : undefined;
  const deleteEntityMessage = props.deleteMessage ?? intl.format("fusion.general.deleteConnection");

  const addPopup = props.createPopup?.({
    open: openAddPopup,
    onClose: closeAddPopup,
    onSave: onAddEntities
  });

  const deletePopup = <Popup
    open={openDeletePopup !== null}
    onClose={closeDeletePopup}
    onConfirm={confirmDelete}
    primaryTitle={deleteEntityTitle?.primaryTitle ?? ""}
    secondaryTitle={deleteEntityTitle?.secondaryTitle}
    styleType="message"
  >
    <Alert severity="error">
      {deleteEntityMessage}
    </Alert>
  </Popup>

  const editPopup = <Popup
    open={openEditPopup !== null}
    primaryTitle={editEntityTitle?.primaryTitle ?? ""}
    secondaryTitle={editEntityTitle?.secondaryTitle}
    onClose={closeEditPopup}
    hideButtons
    styleType="edit"
  >
    {openEditPopup ? <props.entityDetails
      entity={openEditPopup}
      onCancel={closeEditPopup}
      isNew={isAdded(openEditPopup)}
      onEditNew={editAddedEntity}
      editMode={props.editMode}
    /> : null}
  </Popup>

  let toolbarButtons: JSX.Element[] = [];
  if (props.editMode) {
    if (props.toolbarButtons) {
      toolbarButtons = props.toolbarButtons;
    } else {
      toolbarButtons = [
        <CreateButtonWithIcon
          intlId={props.createButtonText ?? ""}
          key="create"
          onClick={() => setOpenAddPopup(true)}
        />
      ];
    }
  }

  const onSelect = (option: DropdownOption) => {
    if (props.setSelectedDropdownOption) {
      props.setSelectedDropdownOption(option)
    }
    setOpenAddPopup(true);
  }

  const toolbarButtonsWithOptions = (props.editMode && props.dropdownMenuItems) ? [
    <CreateButtonWithDropdown
      onSelect={onSelect}
      menuItems={props.dropdownMenuItems}
      key="createButton"
    />] : [];

  const isCancelDisabled = !props.onCancel;

  return <div>
    <InfiniteScrollTableCursor
      toolbarButtons={props.useToolbarButtonsWithOptions ? toolbarButtonsWithOptions : toolbarButtons}
      columns={props.columns}
      pageSize={props.pageSize}
      usePageQuery={props.usePageQuery}
      renderRows={renderRows}
      parseResult={props.parseResult}
      onSearch={props.onSearch}
      placeholder={intl.format("fusion.general.searchPlaceholder")}
      onRowClick={onRowClick}
      onRowsChange={setRows}
      tableType={props.tableType}
      sideFilter={props.sideFilter}
    />
    {props.editMode && (
      <FormButtons
        onSave={save}
        onCancel={reset}
        cancelDisabled={isCancelDisabled} /* Cancel button is disabled if onCancel is not provided */
        disabled={!modified}
      />
    )}    {addPopup}
    {deletePopup}
    {editPopup}
  </div>
};
