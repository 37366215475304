import {
  EOwnershipEntity,
  EOwnershipType,
  ExtendedOwnership,
  getOwnershipTargetType,
  Identity,
  identityName,
  isEOwnershipEntity,
  OwnershipQueryType
} from "@sivis/identity/api";
import {useCustomIntl, useTranslateColumns} from "@sivis/intl";
import {
  DropdownOption,
  ListModifyViewUpdated
} from "@sivis/shared/components/entityView";
import styles from "./identity.module.scss";
import {ConfirmationPopup} from "../components/popup/confirmationPopup";
import {useOwnershipApiForOwner} from "../ownership/api/useOwnershipApiForOwner";
import {
  mapTableRowToOwnership,
  onSearch,
  ownershipColumns,
  ownershipsToTableRow,
  useOwnershipMessages
} from "../ownership/ownershipUtils";
import {EntityType, useSingleEditMode} from "../components/breadcrumb/editModeContext";
import {createOwnershipPopup} from "../ownership/createOwnershipPopup";
import {useState} from "react";
import {IdentityOwnershipDetails} from "../ownership/IdentityOwnershipDetails";
import {useOwnershipsSideFilter} from "../ownership/components/useOwnershipsSideFilter";
import {isEqualById} from "@sivis/shared/utils";
import {parseValidityDateString} from "../utils/validityUtils";


interface IdentityOwnershipViewProps {
  identity: Identity;
  editMode: boolean;
  onCancel?: () => void;
}

// TODO: include ownership of identities for roles
export const IdentityOwnershipView = ({identity, editMode, onCancel}: IdentityOwnershipViewProps) => {
  const intl = useCustomIntl();
  const {deleteOwnershipMessage} = useOwnershipMessages();

  const getIdentityOwnershipTypeFilterText = (type: string) => {
    if (type === EOwnershipType.IDENTITY_RESOURCE_SET_OWNERSHIP.valueOf()) {
      return intl.format("fusion.resourceSet.propertyName.name");
    } else if (type === EOwnershipType.IDENTITY_SYSTEM_OWNERSHIP) {
      return intl.format("fusion.resource.propertyName.systemName");
    } else if (type === EOwnershipType.IDENTITY_ROLE_OWNERSHIP) {
      return intl.format("fusion.role.propertyName.name");
    }
    return intl.format("fusion.resource.propertyName.name");
  }

  const {
    activeFilter,
    filterComponent
  } = useOwnershipsSideFilter(OwnershipQueryType.IDENTITY_OWNERSHIPS, getIdentityOwnershipTypeFilterText, identity?.id, identity?.id);
  const {
    onSave,
    submitted,
    setSubmitted,
    pageSize,
    useOwnershipsPageQuery,
    parseOwnershipsPageResult
  } = useOwnershipApiForOwner(identity, activeFilter ?? undefined);
  const [ownershipTargetType, setOwnershipTargetType] = useState<EOwnershipEntity>();

  const translatedColumns = useTranslateColumns(ownershipColumns, "fusion.ownership.propertyName");
  const shownColumns = editMode ? translatedColumns : translatedColumns.filter(column => column.field !== "action");
  const {toggleEditMode} = useSingleEditMode(EntityType.IDENTITY);

  const getSecondaryTitleId = (ownership: ExtendedOwnership) => {
    if (ownership.type === EOwnershipType.IDENTITY_RESOURCE_SET_OWNERSHIP) {
      return "fusion.ownership.ownershipPopup.secondaryTitle.identityResourceSet";
    } else if (ownership.type === EOwnershipType.IDENTITY_SYSTEM_OWNERSHIP) {
      return "fusion.ownership.ownershipPopup.secondaryTitle.identitySystem";
    } else if (ownership.type === EOwnershipType.IDENTITY_ROLE_OWNERSHIP) {
      return "fusion.ownership.ownershipPopup.secondaryTitle.identityRole";
    }
    return "fusion.ownership.ownershipPopup.secondaryTitle.identityResource";
  }

  const getOwnershipPopupTitle = (ownership: ExtendedOwnership) => ({
    primaryTitle: intl.format("fusion.ownership.ownershipPopup.title.edit", {
      owner: identity ? identityName(identity) : "",
      target: ownership.targetName
    }),
    secondaryTitle: intl.formatBold(getSecondaryTitleId(ownership), {
      owner: identity ? identityName(identity) : "",
      numAccounts: identity?.accountsCount?.toString() ?? "",
      target: ownership.targetName ? ownership.targetName : "",
      validFrom: parseValidityDateString(ownership.validity?.validFrom),
      validTo: parseValidityDateString(ownership.validity?.validTo)
    })
  });

  const confirmationPopup = identity ? <ConfirmationPopup
    open={submitted}
    onClose={() => {
      setSubmitted(false);
      toggleEditMode();
    }}
    primaryTitle={`${intl.format("fusion.ownership.ownershipPopup.title")}${identityName(identity)}`}
    message={intl.format("fusion.message.submitted", {entity: identityName(identity)})}
  /> : null;

  let secondaryTitleId = "fusion.ownership.ownerResourceOwnership.createPopup.description";
  if (ownershipTargetType === EOwnershipEntity.RESOURCE_SET) {
    secondaryTitleId = "fusion.ownership.ownerResourceSetOwnership.createPopup.description";
  } else if (ownershipTargetType === EOwnershipEntity.SYSTEM) {
    secondaryTitleId = "fusion.ownership.ownerSystemOwnership.createPopup.description";
  } else if (ownershipTargetType === EOwnershipEntity.ROLE) {
    secondaryTitleId = "fusion.ownership.ownerRoleOwnership.createPopup.description";
  }

  const identityOwnershipDropdownMenuOptions: DropdownOption[] = [
    {
      entityToAdd: EOwnershipEntity.ROLE,
      label: intl.format("fusion.role.propertyName.name")
    },
    {
      entityToAdd: EOwnershipEntity.RESOURCE,
      label: intl.format("fusion.resource.propertyName.name")
    },
    {
      entityToAdd: EOwnershipEntity.RESOURCE_SET,
      label: intl.format("fusion.resourceSet.propertyName.name")
    },
    {
      entityToAdd: EOwnershipEntity.SYSTEM,
      label: intl.format("fusion.system.propertyName.systemName")
    }
  ];

  const handleSelectDropdownOption = (option: DropdownOption | undefined) => {
    if (option && isEOwnershipEntity(option)) {
      setOwnershipTargetType(option);
    }
  };

  const createPopup = createOwnershipPopup({
    ownershipFor: EOwnershipEntity.IDENTITY,
    ownershipTargetType: ownershipTargetType,
    primaryTitle: intl.format("fusion.identityOwnership.add"),
    secondaryTitle: intl.formatBold(
      secondaryTitleId,
      {owner: identity ? identityName(identity) : ""}
    ),
    owner: identity
  });

  // TODO: refactor ListModifyViewUpdated to be not aware of ownership entity an to pass CreateButtonWithDropdown as whole
  return <div className={styles.listContainer}>
    {confirmationPopup}
    <ListModifyViewUpdated
      columns={shownColumns}
      onSave={onSave}
      entityToTableRow={ownershipsToTableRow(getOwnershipTargetType, intl.format)}
      onSearch={onSearch}
      editMode={editMode}
      getEntityTitle={getOwnershipPopupTitle}
      isEqual={isEqualById}
      entityDetails={IdentityOwnershipDetails}
      deleteMessage={deleteOwnershipMessage}
      mapTableRowToEntity={mapTableRowToOwnership}
      pageSize={pageSize}
      usePageQuery={useOwnershipsPageQuery}
      parseResult={parseOwnershipsPageResult}
      createButtonText="fusion.ownership.add"
      createPopup={createPopup}
      tableType={OwnershipQueryType.IDENTITY_OWNERSHIPS}
      useToolbarButtonsWithOptions={true}
      dropdownMenuItems={identityOwnershipDropdownMenuOptions}
      setSelectedDropdownOption={handleSelectDropdownOption}
      sideFilter={filterComponent}
      onCancel={onCancel}
    />
  </div>
};
