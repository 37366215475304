import { useOutletContext } from 'react-router-dom';
import { IntlButton, useCustomIntl, useTranslateConfigs } from '@sivis/intl';
import {
  calculateDescriptionForRule,
  calculateDescriptionForRuleList,
  emptyBusinessRuleWithJSXElementCriteriaGroups,
  emptyBusinessRuleWithStringCriteriaGroups,
  updateWatchValues
} from './ruleUtils';
import { useEffect, useState } from 'react';
import { EntityInformation, FieldType, Popup, TabBar } from '@sivis/shared/components/entityView';
import styles from './rule.module.scss';
import { ContextType } from './ruleView';
import { RuleAffectedIdentitiesTab } from './ruleAffectedIdentitiesTab';
import { RuleCriteriaPopup } from './ruleCriteriaPopup';
import { Spinner } from '@sivis/app-shell';
import { BusinessRule, RuleStatus } from '@sivis/identity/api';
import DraftStatusBar from '../components/draft/draftStatusBar';
import DraftCard from '../components/draft/draftCard';
import { getTranslatedErrorMessage } from '@sivis/shared/api-utils';
import { ActionPopup } from '../components/popup/actionPopup';
import { Alert } from '@mui/material';

export const RuleInformationTab = () => {
  const {
    editMode,
    error,
    isLoading,
    isNew,
    businessRule,
    onSave,
    onPublish,
    onCancel,
    watchValues,
    setWatchValues,
    draftData,
    setDraftData,
    onDeleteDraft
  } = useOutletContext<ContextType>();
  const intl = useCustomIntl();
  const [openEditCriteriaPopup, setOpenEditCriteriaPopup] = useState(false);
  const [publishPopupOpen, setPublishPopupOpen] = useState(false);
  const [flattenedBusinessRule, setFlattenedBusinessRule] = useState(editMode ? emptyBusinessRuleWithStringCriteriaGroups : emptyBusinessRuleWithJSXElementCriteriaGroups);
  const [flattenedDraft, setFlattenedDraft] = useState(editMode ? emptyBusinessRuleWithStringCriteriaGroups : emptyBusinessRuleWithJSXElementCriteriaGroups);
  const toggleOpenEditCriteriaPopupMode = () => {
    setOpenEditCriteriaPopup(!openEditCriteriaPopup);
  };

  const editCriteriaButton = <IntlButton
    variant="outlined"
    onClick={toggleOpenEditCriteriaPopupMode}
    disabled={!editMode}
    intlId="fusion.rule.editCriteria"
  />;

  const configsGeneral = [
    {
      property: 'name',
      required: true
    },
    {
      property: 'targetValue',
      required: true
    },
    {
      property: 'criteriaGroups',
      disabled: true,
      fieldType: FieldType.READONLY,
      showJSXElementBelow: editCriteriaButton,
      renderRow: () => draftData && editMode ? calculateDescriptionForRule(draftData.criteriaGroups, intl.format, draftData.operatorOutsideGroup) : calculateDescriptionForRule(
        businessRule.criteriaGroups, intl.format, businessRule.operatorOutsideGroup)
    }
  ];

  const translatedConfigs = useTranslateConfigs(configsGeneral, 'fusion.rule.propertyName');
  const primaryTitleRuleCriteriaPopup = isNew ? intl.format('fusion.rule.createRule.title') : intl.format('fusion.rule.editRule.title', { rule: businessRule?.name });
  const secondaryTitleRuleCriteriaPopup = isNew ? intl.format('fusion.rule.createRule.secondaryTitle') : intl.format('fusion.rule.editRule.secondaryTitle', { rule: businessRule?.name });

  const handleOnSaveDraft = () => {
    let draft: BusinessRule;
    if (draftData) {
      // update existing draft
      draft = {
        ...draftData,
        name: watchValues['mainForm']?.name || draftData.name || '',
        targetValue: watchValues['mainForm']?.targetValue || draftData.targetValue || '',
        isPublished: false,
        publishedId: draftData.publishedId
      };
    } else {
      // create new draft
      draft = {
        ...businessRule,
        id: '',
        name: watchValues['mainForm']?.name || businessRule.name || '',
        targetValue: watchValues['mainForm']?.targetValue || businessRule.targetValue || '',
        isPublished: false,
        publishedId: businessRule.id
      };
    }
    onSave(draft);
  };

  const handleOnPublish = () => {
    setPublishPopupOpen(true);
  };

  const handleOnConfirmPublish = () => {
    let updatedBusinessRule: BusinessRule;
    if (!draftData) {
      // create new published rule or update existing published rule
        updatedBusinessRule = {
          ...businessRule,
          ...watchValues['mainForm'],
          criteriaGroups: businessRule.criteriaGroups,
          isPublished: true,
          publishedId: businessRule.id
        };
    } else {
      // publish draft changes to existing rule
      updatedBusinessRule = {
        ...draftData,
        ...watchValues['mainForm'],
        criteriaGroups: draftData.criteriaGroups,
        isPublished: true,
        publishedId: businessRule.publishedId
      };
    }
    onPublish(updatedBusinessRule);
    setPublishPopupOpen(false);
    setDraftData(undefined);
  };

  useEffect(() => {
    let updatedRule;
      updatedRule = businessRule ? {
        ...businessRule,
        criteriaGroups: editMode
          ? calculateDescriptionForRuleList(((businessRule.criteriaGroups) ?? []), intl.format, businessRule.operatorOutsideGroup).toString()
          : calculateDescriptionForRule((businessRule.criteriaGroups ?? []), intl.format, businessRule.operatorOutsideGroup)
      } : editMode ? emptyBusinessRuleWithStringCriteriaGroups : emptyBusinessRuleWithJSXElementCriteriaGroups;
    setFlattenedBusinessRule(updatedRule);
  }, [businessRule, editMode]);

  useEffect(() => {
    if (draftData) {
      const updatedDraft = {
        ...draftData,
        criteriaGroups: calculateDescriptionForRuleList((draftData.criteriaGroups ?? []), intl.format, draftData.operatorOutsideGroup).toString()
      };
      setFlattenedDraft(updatedDraft);
    }
  }, [draftData, editMode]);

  const rulePopupTabs = [
    {
      label: intl.format('fusion.rule.ruleCriteria'),
      content: <RuleCriteriaPopup
        togglePopupOpen={toggleOpenEditCriteriaPopupMode}
      />
    },
    {
      label: intl.format('fusion.rule.affectedIdentities.list.name'),
      content: <RuleAffectedIdentitiesTab />
    }
  ];

  const ruleCriteriaPopup = <Popup
    open={openEditCriteriaPopup}
    onClose={() => setOpenEditCriteriaPopup(false)}
    primaryTitle={primaryTitleRuleCriteriaPopup}
    secondaryTitle={secondaryTitleRuleCriteriaPopup}
    styleType="edit"
    hideButtons
  >
    <TabBar tabs={rulePopupTabs} />
  </Popup>;

  const publishAlert = (
    <Alert severity="info">
      {intl.format('fusion.rule.publishPopup.infoBox')}
    </Alert>
  );
  const publishPopup = (
    <ActionPopup
      open={publishPopupOpen}
      primaryTitle={intl.format('fusion.rule.publishPopup.title')}
      secondaryTitle={intl.format('fusion.rule.publishPopup.secondaryTitle')}
      onClose={() => setPublishPopupOpen(false)}
      onConfirm={handleOnConfirmPublish}
      alert={publishAlert}
    ></ActionPopup>
  );

  const draftDetails =
    <div>
      <DraftStatusBar
        isActive={businessRule.status === RuleStatus.Active}
        draftData={(isNew || draftData) ? true : !businessRule.isPublished}
        additionalInformationLabel={'fusion.rule.propertyName.changes'}
        additionalInformationText={businessRule.changes ?? 'Not active'}
      />
      {
        editMode && draftData?.publishedId != null &&
        <DraftCard
          draftData={{
            updatedAt: draftData.meta?.updatedAt ?? Date.now().toString(),
            updatedBy: draftData.meta?.updatedBy ?? Date.now().toString()
          }}
          onDiscardDraft={() => onDeleteDraft(draftData.id)}
        />
      }
    </div>;

  const entityDetailsGeneral = <EntityInformation
      key={JSON.stringify(flattenedBusinessRule)}
      editMode={editMode}
      configs={translatedConfigs}
      value={(editMode && draftData) ? flattenedDraft : flattenedBusinessRule}
      onSubmit={handleOnPublish}
      onSaveDraft={handleOnSaveDraft}
      onCancel={onCancel}
      onWatch={(watchValues) => {
        updateWatchValues('mainForm', watchValues, setWatchValues);
      }}
      displayInTwoColumns={false}
      isDraftable={true}
      draftStatusBar={draftDetails}
  />;

  const content = isLoading ? <Spinner /> : entityDetailsGeneral;

  return <div className={styles.listContainer}>
    {error ?
      <div>{getTranslatedErrorMessage(error, 'An error occurred', intl.format)}</div>
      : content}
    {openEditCriteriaPopup && ruleCriteriaPopup}
    {publishPopupOpen && publishPopup}
  </div>;
};
