import AddIcon from '@mui/icons-material/Add';
import { pointsharpThemeOptions } from '@pointsharp/component-library';
import { IntlButton } from '@sivis/intl';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

const useStyles = () => {
  return {
    button: {
      color: pointsharpThemeOptions?.palette?.background?.almostWhite,
      backgroundColor: pointsharpThemeOptions?.palette?.background?.almostBlack,
      height: '32px',
      '&.Mui-disabled': {
        color: pointsharpThemeOptions?.palette?.background?.almostBlack,
        backgroundColor:
          pointsharpThemeOptions?.palette?.background?.almostWhite,
      },
      '&:hover': {
        color: pointsharpThemeOptions?.palette?.background?.almostBlack,
      },
    },
    buttonCancel: {
      color: pointsharpThemeOptions?.palette?.background?.almostBlack,
      backgroundColor: pointsharpThemeOptions?.palette?.background?.almostWhite,
    },
  };
};

export const CreateButton = ({ disabled }: { disabled?: boolean }) => {
  const navigate = useNavigate();
  const onCreate = useCallback(() => navigate('new'), [navigate]);
  const styles = useStyles();

  return (
    <IntlButton
      variant="outlined"
      onClick={onCreate}
      disabled={disabled}
      intlId="fusion.button.create"
      sx={styles.button}
    />
  );
};

export const EditButton = ({
  onClick,
  disabled,
}: {
  onClick?: () => void;
  disabled?: boolean;
}) => {
  const styles = useStyles();
  const ariaIntlId = disabled
    ? 'general.aria.editActivated'
    : 'general.aria.edit';

  return (
    <IntlButton
      variant="outlined"
      onClick={onClick}
      disabled={disabled}
      intlId="general.edit"
      ariaIntlId={ariaIntlId}
      sx={styles.button}
    />
  );
};

export const DeleteButton = ({
  onClick,
  disabled,
}: {
  onClick?: () => void;
  disabled?: boolean;
}) => {
  const styles = useStyles();

  return (
    <IntlButton
      variant="outlined"
      onClick={onClick}
      disabled={disabled}
      intlId="general.delete"
      sx={styles.button}
    />
  );
};

export const DeactivateButton = ({
  onClick,
  disabled,
}: {
  onClick?: () => void;
  disabled?: boolean;
}) => {
  const styles = useStyles();

  return (
    <IntlButton
      variant="outlined"
      onClick={onClick}
      disabled={disabled}
      intlId="general.deactivate"
      sx={styles.button}
    />
  );
};

export const ActivateButton = ({
  onClick,
  disabled,
}: {
  onClick?: () => void;
  disabled?: boolean;
}) => {
  const styles = useStyles();

  return (
    <IntlButton
      variant="outlined"
      onClick={onClick}
      disabled={disabled}
      intlId="general.activate"
      sx={styles.button}
    />
  );
};

interface CreateButtonWithIconProps {
  intlId: string;
  disabled?: boolean;
  onClick: () => void;
}

export const CreateButtonWithIcon = ({
  disabled,
  intlId,
  onClick,
}: CreateButtonWithIconProps) => {
  const styles = useStyles();

  return (
    <IntlButton
      variant="outlined"
      onClick={onClick}
      disabled={disabled}
      intlId={intlId}
      sx={styles.button}
      icon={<AddIcon />}
    />
  );
};
