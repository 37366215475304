import { useCustomIntl, useTranslateColumns } from '@sivis/intl';
import { GridColDef, GridRowParams } from '@mui/x-data-grid-pro';
import { useNavigate } from 'react-router-dom';
import {
  CreateButtonWithIcon,
  EntityView,
  InfiniteScrollTableCursor
} from '@sivis/shared/components/entityView';
import { calculateDescriptionForRuleList, mapRuleStatusStringToIntlId } from './ruleUtils';
import { Spinner } from '@sivis/app-shell';
import { BusinessRule, SideFilterQueryType } from '@sivis/identity/api';
import { useBusinessRulesPageApi } from './api/useBusinessRulesPageApi';
import { useRulesSideFilter } from '../ownership/components/useRulesSideFilter';
import { getTranslatedErrorMessage } from '@sivis/shared/api-utils';

const columns: GridColDef[] = [
  {
    field: 'name',
    flex: 1
  },
  {
    field: 'targetValue',
    flex: 1
  },
  {
    field: 'details',
    flex: 2
  },
  {
    field: 'status',
    flex: 1
  }
];

export const RuleList = () => {
  const intl = useCustomIntl();
  const { activeFilter, filterComponent } = useRulesSideFilter();
  const {
    onSearch,
    useBusinessRulesPageQuery,
    parseBusinessRulesPageResult,
    pageSize,
    isLoading,
    error
  } = useBusinessRulesPageApi(activeFilter ?? undefined);
  const navigate = useNavigate();
  const translatedColumns = useTranslateColumns(columns, 'fusion.rule.propertyName');

  const entityToTableRow = (entity: BusinessRule) => {
    return {
      id: entity.id,
      name: entity.name,
      targetValue: entity.targetValue,
      details: entity.criteriaGroups ? calculateDescriptionForRuleList(entity.criteriaGroups, intl.format, entity.operatorOutsideGroup) : '',
      status: intl.format(mapRuleStatusStringToIntlId(entity.status.valueOf())),
      isPublished: entity.isPublished
    };
  };

  const onRowClick = (id: string) => navigate(id);
  const onCreate = () => navigate('new');
  const createRuleButton = <CreateButtonWithIcon intlId="fusion.rule.list.create" key="create"
                                                 onClick={onCreate} />;

  const content = isLoading ? <Spinner />
    : <InfiniteScrollTableCursor
      columns={translatedColumns}
      pageSize={pageSize}
      usePageQuery={useBusinessRulesPageQuery}
      renderRow={entityToTableRow}
      parseResult={parseBusinessRulesPageResult}
      onSearch={onSearch}
      placeholder={intl.format('fusion.general.searchPlaceholder')}
      onRowClick={onRowClick}
      sideFilter={filterComponent}
      tableType={SideFilterQueryType.RULES}
      getRowClassName={(params: GridRowParams) => `rowIsPublished--${params.row.isPublished}`}
    />;

  return <EntityView
    title={intl.format('fusion.rule.list.name')}
    actionButtons={[createRuleButton]}
  >
    {error ?
      <div>{getTranslatedErrorMessage(error, 'An error occurred', intl.format)}</div>
      : content}
  </EntityView>;
};
