import { EntityForm, EntityFormHandle, EntityFormProps } from './entityForm';
import { AttributeRows } from './attributeRows';
import { RowGroup } from './rowGroup';
import { useRef, useState, useEffect } from 'react';
import { FormButtons } from './FormButtons';
import _ from 'lodash';

interface EntityInformationProps extends EntityFormProps {
  editMode: boolean;
  saveInsteadOfSubmit?: boolean;
  searchFunction?: (searchTerm: string) => any;
  searchResults?: any;
  loadingEnities?: boolean;
  isDraftable?: boolean;
  draftStatusBar?: JSX.Element;
  onSaveDraft?: (value: Record<string, any>) => void;
  searchFunctions?: {
    [key: string]: {
      searchFunction: (searchTerm: string) => any;
      searchResults: any;
      loadingState: boolean;
      currentValue?: {
        id: string;
        [key: string]: any; // This allows for different properties for identity and system
      };
    };
  };
}

export const EntityInformation = (props: EntityInformationProps) => {
  const ref = useRef<EntityFormHandle>(null);
  const [isFormDirty, setIsFormDirty] = useState(false);
  const initialValuesRef = useRef(props.value);

  const submitFormFromOutside = () => {
    ref.current?.submit();
  };

  const saveDraftFromOutside = () => {
    ref.current?.saveDraft();
  };

  useEffect(() => {
    if (props.editMode) {
      initialValuesRef.current = props.value;
      setIsFormDirty(false);
    }
  }, [props.editMode, props.value]);

  const handleWatch = (currentValues: Record<string, unknown>) => {
    const hasChanges = !_.isEqual(currentValues, initialValuesRef.current);
    setIsFormDirty(hasChanges);
  };

  return (
    <div>
      <RowGroup>
        {props.draftStatusBar}
        {props.editMode ? (
          <EntityForm
            ref={ref}
            configs={props.configs}
            value={props.value}
            onSubmit={props.onSubmit}
            onSaveDraft={props.onSaveDraft}
            onCancel={props.onCancel}
            hideButtons
            displayInTwoColumns={props.displayInTwoColumns ?? true}
            onWatch={handleWatch}
            searchFunction={props.searchFunction}
            searchResults={props.searchResults}
            loadingEntities={props.loadingEnities ?? false}
            isDraftable={props.isDraftable}
            searchFunctions={props.searchFunctions}
          />
        ) : (
          <AttributeRows
            configs={props.configs}
            value={props.value}
            displayInTwoColumns={props.displayInTwoColumns ?? true}
          />
        )}
      </RowGroup>
      {props.editMode ? (
        <FormButtons
          onCancel={props.onCancel}
          onSave={submitFormFromOutside}
          saveInsteadOfSubmit={props.saveInsteadOfSubmit}
          isDraftable={props.isDraftable}
          onSaveDraft={saveDraftFromOutside}
          disabled={!isFormDirty}
          cancelDisabled={false}
        />
      ) : null}
    </div>
  );
};
