import { EntityInformation, FieldType } from '@sivis/shared/components/entityView';
import { useCustomIntl, useTranslateConfigs } from '@sivis/intl';
import { AsDefinition, AsdInput, AsMapping, DraftActions } from '@sivis/attributesync/api';
import { forwardRef, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import DraftStatusBar from '../components/draft/draftStatusBar';

export interface AttributeSyncDefinitionInformationTabProps {
  asDefinition: AsDefinition | undefined;
  draftData: AsDefinition;
  isNew: boolean;
  onSave: (value: Record<string, any>) => void;
  onSaveDraft: (value: Record<string, any>) => void;
  onPublishDraft: (draftId: string, value: Record<string, any>) => void;
  onCancel: (systemId?: string) => void;
  editMode: boolean;
  defaultInput: AsdInput;
  openPublishPopup: (source: string) => void;
}

export type AttributeSyncDefinitionInformationTabHandle = {
  submit: () => void;
};

const AttributeSyncDefinitionInformationTab = forwardRef<
  AttributeSyncDefinitionInformationTabHandle,
  AttributeSyncDefinitionInformationTabProps
>((props: AttributeSyncDefinitionInformationTabProps, ref) => {
  const flattenedAsDefinition = useMemo(
    () =>
      props.asDefinition
        ? {
            ...props.asDefinition,
          }
        : {},
    [props.asDefinition]
  );

  const intl = useCustomIntl();

  const [formValue, setFormValue] = useState<Record<string, any>>(
    props.isNew ? props.defaultInput : flattenedAsDefinition
  );

  useEffect(() => {
    setFormValue(() => {
      if (props.editMode && props.draftData) {
        return props.draftData;
      } else {
        return props.isNew ? props.defaultInput : flattenedAsDefinition;
      }
    });
  }, [props.editMode, props.draftData, props.isNew, flattenedAsDefinition]);

  const handleWatch = (value: Record<string, any>) => {
    // Only update the fields that are in the EntityInformation form to avoid overwriting other fields
    setFormValue((prevFormValue) => ({
      ...prevFormValue,
      name: value.name ?? prevFormValue.name,
      priority: value.priority ?? prevFormValue.priority,
      description: value.description ?? prevFormValue.description,
    }));
  };

  const handleSubmit = (value: Record<string, any>) => {
    setFormValue(value);
    props.isNew ? props.onSave(value) : props.openPublishPopup('information');
  };

  const handleDraft = () => {
    const formValueCopy = { ...formValue };
    formValueCopy.asMappings = formValue.asMappings.filter(
      (mapping: AsMapping) => mapping.draftAction !== DraftActions.Deleted
    );
    props.onSaveDraft(formValueCopy);
  };

  useImperativeHandle(
    ref,
    () => ({
      submit() {
        props.draftData
          ? props.onPublishDraft(props.draftData.id, formValue)
          : props.onSave(formValue);
      },
    }),
    [props.onSave, props.draftData, formValue]
  );

  const configs = [
    {
      property: 'name',
      required: true,
    },
    {
      property: 'priority',
      fieldType: FieldType.NUMBER,
    },
    {
      property: 'defined',
      fieldType: FieldType.READONLY,
      renderRow: (_: string) =>
        String(props.asDefinition?.asMappings?.length ?? 0),
    },
    {
      property: 'description',
    },
  ];

  const translatedConfigs = useTranslateConfigs(
    configs,
    'fusion.attributesynchronizationdefinition.propertyName'
  );

  const draftStatusBar = <>
    {!props.isNew && (
      <DraftStatusBar
        isActive={props.asDefinition?.isActive ?? false}
        draftData={!!props.draftData}
        additionalInformationLabel={'fusion.general.details'}
        additionalInformationText={intl.format(
          'fusion.attributesynchronizationdefinition.information.customDefinition'
        )}
      />
    )}
  </>;

  return (
    <EntityInformation
      editMode={props.editMode}
      configs={translatedConfigs}
      value={formValue}
      onSubmit={handleSubmit}
      onCancel={props.onCancel}
      onSaveDraft={handleDraft}
      onWatch={handleWatch}
      isDraftable={!props.isNew}
      draftStatusBar={draftStatusBar}
    />
  );
});

export default AttributeSyncDefinitionInformationTab;
