export const INFORMATION_TAB_PATHNAME = "information";
export const IDENTITY_TYPES_TAB_PATHNAME = "identity-types";
export const ROLES_TAB_PATHNAME = "roles";
export const IDENTITIES_TAB_PATHNAME = "identities";
export const RESOURCES_TAB_PATHNAME = "resources";
export const SYSTEMS_TAB_PATHNAME = "systems";
export const ORGANIZATIONAL_ENTITIES_TAB_PATHNAME = "organizational-entities";
export const CHILD_ENTITIES_TAB_PATHNAME = "child-entities";
export const ACCOUNTS_TAB_PATHNAME = "accounts";
export const ACCOUNT_TYPES_TAB_PATHNAME = "account-types";
export const FIELDDEFINITION_TAB_PATHNAME = "fielddefinitions";
export const TYPE_RELATIONS_TAB_PATHNAME = "type-relations";
export const OWNERSHIP_TAB_PATHNAME = "ownership";
export const ATTRIBUTE_SYNCHRONIZATION_DEFINITION_TAB_PATHNAME = "attribute-synchronization-definition"
export const AFFECTED_TAB_PATHNAME = "affected-identities";
export const FIELDS_TAB_PATHNAME = "defined-fields";
export const RULES_TAB_PATHNAME = "rules";
export const AFFECTED_IDENTITIES_PATHNAME = "affected-identities";

// URL constants for navigation routes in the application
export const ACCOUNT_TYPE_URL = '/account-type';
export const ACCOUNT_URL = '/account';
export const ADDRESS_URL = '/address';
export const IDENTITY_TYPE_URL = '/identity-type';
export const IDENTITY_URL = '/identity';
export const ORGANIZATIONAL_ENTITY_TYPE_URL = '/organizational-entity-type';
export const ORGANIZATIONAL_ENTITY_URL = '/organizational-entity';
export const PERMISSION_URL = '/permission';
export const RESOURCE_SET_URL = '/resource-set';
export const RESOURCE_TYPE_URL = '/resource-type';
export const RESOURCE_URL = '/resource';
export const ROLE_URL = '/role';
export const RULE_URL = '/rule';
export const SYSTEM_TYPE_URL = '/system-type';
export const SYSTEM_URL = '/system';
