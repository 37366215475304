import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

interface RouteRedirectOptions {
  basePath: string;      // Base path, e.g., '/identity', '/account'
  subPath: string;       // Sub-path to redirect to, e.g., 'information', 'details', 'profile'
}

/**
 * A hook to redirect from a base path with an ID to a specified sub-path.
 * @param options Configuration object with basePath and subPath
 */
export const useRouteRedirect = ({ basePath, subPath }: RouteRedirectOptions) => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const currentPath = location.pathname;
    // Check if we are on /basePath/:id without the sub-path
    if (currentPath.match(new RegExp(`^${basePath}/[^/]+$`))) {
      // Redirect to the sub-path, replacing the current entry
      navigate(`${currentPath}/${subPath}`, { replace: true });
    }
  }, [location.pathname, navigate, basePath, subPath]);
};
