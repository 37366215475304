import { combineFilter, FilterOperator } from '@sivis/shared/api-utils';
import { GetOwnershipsQuery } from './ownership.generated';
import { ThunkAction } from '@reduxjs/toolkit';
import { identityApi } from '../identity/identityEnhanced';
import {
  EOwnershipEntity,
  EOwnershipType,
  GeneratedIdentityTypes,
  GeneratedResourceSetTypes,
  GeneratedResourceTypes,
  GeneratedRoleTypes,
  GeneratedSystemTypes,
  Identity,
  identityName,
  ownershipApi,
  resourceApi,
  resourceSetApi,
  roleApi,
  systemApi
} from '@sivis/identity/api';

const fetchEntitiesByIds = async (
  ids: Array<string>,
  apiEndpoint: any,
  dispatch: (arg: any) => any
) => {
  const filter = ids.length ? combineFilter(FilterOperator.OR, ...ids.map(id => ({
    name: "id",
    operator: FilterOperator.EQ,
    uuidValue: id
  }))) : undefined;
  const promise = dispatch(apiEndpoint.initiate({filter}));
  const {data} = await promise;
  promise.unsubscribe();
  return data;
};

const getTargetIdsFromEdges = (edges: any[] | undefined, type: EOwnershipType) => edges?.filter(edge => edge?.node.type == type && edge?.node.targetId).map(edge => edge?.node.targetId ?? "") ?? [];
const getOwnerIdsFromEdges = (edges: any[] | undefined, type: EOwnershipType) => edges?.filter(edge => edge?.node.type == type && edge?.node.ownerId).map(edge => edge?.node.ownerId ?? "") ?? [];

function getIdentityOwnershipType(entity: EOwnershipEntity) {
  if (entity === EOwnershipEntity.RESOURCE) {
    return EOwnershipType.IDENTITY_RESOURCE_OWNERSHIP;
  }
  if (entity === EOwnershipEntity.RESOURCE_SET) {
    return EOwnershipType.IDENTITY_RESOURCE_SET_OWNERSHIP;
  }
  if (entity === EOwnershipEntity.ROLE) {
    return EOwnershipType.IDENTITY_ROLE_OWNERSHIP
  }
  return EOwnershipType.IDENTITY_SYSTEM_OWNERSHIP;
}

function getRoleOwnershipType(entity: EOwnershipEntity) {
  if (entity === EOwnershipEntity.RESOURCE) {
    return EOwnershipType.ROLE_RESOURCE_OWNERSHIP;
  }
  if (entity === EOwnershipEntity.RESOURCE_SET) {
    return EOwnershipType.ROLE_RESOURCE_SET_OWNERSHIP;
  }
  if (entity === EOwnershipEntity.IDENTITY) {
    return EOwnershipType.IDENTITY_ROLE_OWNERSHIP
  }
  return EOwnershipType.ROLE_SYSTEM_OWNERSHIP;
}

const extendOwnershipsForOwner = (entity: EOwnershipEntity, dataOwnerships: GetOwnershipsQuery, arg: {
  cacheKey: string
}): ThunkAction<any, any, any, any> => {
  return async (dispatch) => {
    const resourceIds = getTargetIdsFromEdges(dataOwnerships.ownerships?.edges, entity == EOwnershipEntity.IDENTITY ? EOwnershipType.IDENTITY_RESOURCE_OWNERSHIP : EOwnershipType.ROLE_RESOURCE_OWNERSHIP);
    const resourceSetIds = getTargetIdsFromEdges(dataOwnerships.ownerships?.edges, entity == EOwnershipEntity.IDENTITY ? EOwnershipType.IDENTITY_RESOURCE_SET_OWNERSHIP : EOwnershipType.ROLE_RESOURCE_SET_OWNERSHIP);
    const systemIds = getTargetIdsFromEdges(dataOwnerships.ownerships?.edges, entity == EOwnershipEntity.IDENTITY ? EOwnershipType.IDENTITY_SYSTEM_OWNERSHIP : EOwnershipType.ROLE_SYSTEM_OWNERSHIP);
    const roleIds = getTargetIdsFromEdges(dataOwnerships.ownerships?.edges, EOwnershipType.IDENTITY_ROLE_OWNERSHIP);

    const dataResources = await fetchEntitiesByIds(resourceIds, resourceApi.endpoints.GetResources, dispatch);
    const dataResourceSets = await fetchEntitiesByIds(resourceSetIds, resourceSetApi.endpoints.GetResourceSets, dispatch);
    const dataSystems = await fetchEntitiesByIds(systemIds, systemApi.endpoints.GetSystems, dispatch);
    const dataRoles = await fetchEntitiesByIds(roleIds, roleApi.endpoints.GetRoles, dispatch);

    if (dataResources || dataResourceSets || dataSystems || dataRoles) {
      dispatch(patchOwnershipsCacheWithTargetsForIdentity(arg.cacheKey, dataResources, dataResourceSets, dataSystems, dataRoles));
    }
  }
}

const extendOwnershipsForTarget = (entity: EOwnershipEntity, dataOwnerships: GetOwnershipsQuery, arg: {
  cacheKey: string
}): ThunkAction<any, any, any, any> => {
  return async (dispatch) => {
    const identityOwnershipType = getIdentityOwnershipType(entity);
    const roleOwnershipType = getRoleOwnershipType(entity);

    const identityIds = getOwnerIdsFromEdges(dataOwnerships.ownerships?.edges, identityOwnershipType);
    const roleIds = getOwnerIdsFromEdges(dataOwnerships.ownerships?.edges, roleOwnershipType);

    const dataIdentities = await fetchEntitiesByIds(identityIds, identityApi.endpoints.GetIdentities, dispatch);
    const dataRoles = await fetchEntitiesByIds(roleIds, roleApi.endpoints.GetRoles, dispatch);

    if (dataIdentities || dataRoles) {
      if (entity === EOwnershipEntity.RESOURCE) {
        dispatch(patchOwnershipsCacheWithOwnersForResource(arg.cacheKey, dataIdentities, dataRoles));
      } else if (entity === EOwnershipEntity.RESOURCE_SET) {
        dispatch(patchOwnershipsCacheWithOwnersForResourceSet(arg.cacheKey, dataIdentities, dataRoles));
      } else if (entity === EOwnershipEntity.SYSTEM) {
        dispatch(patchOwnershipsCacheWithOwnersForSystem(arg.cacheKey, dataIdentities, dataRoles));
      }
    }
  }
}

const extendOwnershipsForRole = (entity: EOwnershipEntity, dataOwnerships: GetOwnershipsQuery, arg: {
  cacheKey: string
}): ThunkAction<any, any, any, any> => {
  return async (dispatch) => {
    const resourceIds = getTargetIdsFromEdges(dataOwnerships.ownerships?.edges, EOwnershipType.ROLE_RESOURCE_OWNERSHIP);
    const resourceSetIds = getTargetIdsFromEdges(dataOwnerships.ownerships?.edges, EOwnershipType.ROLE_RESOURCE_SET_OWNERSHIP);
    const systemIds = getTargetIdsFromEdges(dataOwnerships.ownerships?.edges, EOwnershipType.ROLE_SYSTEM_OWNERSHIP);
    const identityIds = getOwnerIdsFromEdges(dataOwnerships.ownerships?.edges, EOwnershipType.IDENTITY_ROLE_OWNERSHIP);

    const dataResources = await fetchEntitiesByIds(resourceIds, resourceApi.endpoints.GetResources, dispatch);
    const dataResourceSets = await fetchEntitiesByIds(resourceSetIds, resourceSetApi.endpoints.GetResourceSets, dispatch);
    const dataSystems = await fetchEntitiesByIds(systemIds, systemApi.endpoints.GetSystems, dispatch);
    const dataIdentities = await fetchEntitiesByIds(identityIds, identityApi.endpoints.GetIdentities, dispatch);

    if (dataResources || dataResourceSets || dataSystems || dataIdentities) {
      dispatch(patchOwnershipsCacheWithOwnersAndTargetsForRole(arg.cacheKey, dataResources, dataResourceSets, dataSystems, dataIdentities));
    }
  }
}

export const extendIdentityOwnerships = (dataOwnerships: GetOwnershipsQuery, arg: {
  cacheKey: string
}): ThunkAction<any, any, any, any> => extendOwnershipsForOwner(EOwnershipEntity.IDENTITY, dataOwnerships, arg);

export const extendRoleOwnerships = (dataOwnerships: GetOwnershipsQuery, arg: {
  cacheKey: string
}): ThunkAction<any, any, any, any> => extendOwnershipsForRole(EOwnershipEntity.ROLE, dataOwnerships, arg);

export const extendResourceOwnerships = (dataOwnerships: GetOwnershipsQuery, arg: {
  cacheKey: string
}): ThunkAction<any, any, any, any> => extendOwnershipsForTarget(EOwnershipEntity.RESOURCE, dataOwnerships, arg);

export const extendResourceSetOwnerships = (dataOwnerships: GetOwnershipsQuery, arg: {
  cacheKey: string
}): ThunkAction<any, any, any, any> => extendOwnershipsForTarget(EOwnershipEntity.RESOURCE_SET, dataOwnerships, arg);

export const extendSystemOwnerships = (dataOwnerships: GetOwnershipsQuery, arg: {
  cacheKey: string
}): ThunkAction<any, any, any, any> => extendOwnershipsForTarget(EOwnershipEntity.SYSTEM, dataOwnerships, arg);


const getResourceName = (resources: GeneratedResourceTypes.GetResourcesQuery["resources"], id: string) => {
  return resources?.edges.find(edge => edge?.node?.id === id)?.node?.name ?? "";
}
const getResourceSetName = (resourceSets: GeneratedResourceSetTypes.GetResourceSetsQuery["resourceSets"], id: string) => {
  return resourceSets?.edges.find(edge => edge?.node?.id === id)?.node?.name ?? "";
}
const getRoleName = (roles: GeneratedRoleTypes.GetRolesQuery["roles"], id: string) => {
  return roles?.edges.find(edge => edge?.node?.id === id)?.node?.name ?? "";
}
const getSystemName = (systems: GeneratedSystemTypes.GetSystemsQuery["systems"], id: string) => {
  return systems?.edges.find(edge => edge?.node?.id === id)?.node?.systemName ?? "";
}
const getIdentityName = (identities: GeneratedIdentityTypes.GetIdentitiesQuery["identities"], ownerId: string) => {
  const identity = identities?.edges.find(edge => edge?.node?.id === ownerId)?.node;
  return identity ? identityName(identity as Identity) : "";
};

enum ContextType {
  TARGETS,
  OWNERS,
  ROLES
}

const setTargetName = (
  edge: {
    node: { type: string; targetId?: any; ownerId?: any; targetName?: string; };
  },
  {
    resources = undefined,
    resourceSets = undefined,
    systems = undefined,
    roles = undefined,
    identities = undefined,
  }: {
    resources?: GeneratedResourceTypes.GetResourcesQuery,
    resourceSets?: GeneratedResourceSetTypes.GetResourceSetsQuery,
    systems?: GeneratedSystemTypes.GetSystemsQuery,
    roles?: GeneratedRoleTypes.GetRolesQuery,
    identities?: GeneratedIdentityTypes.GetIdentitiesQuery
  },
  context: ContextType
) => {
  let targetName = edge.node.targetName;

  if (!targetName) {
    const {type, targetId, ownerId} = edge.node;
    switch (type) {
      case EOwnershipType.IDENTITY_RESOURCE_OWNERSHIP.valueOf():
        targetName = context === ContextType.OWNERS ? getResourceName(resources?.resources, targetId) : getIdentityName(identities?.identities, ownerId);
        break;
      case EOwnershipType.ROLE_RESOURCE_OWNERSHIP.valueOf():
        targetName = context === ContextType.ROLES ? getResourceName(resources?.resources, targetId) : getRoleName(roles?.roles, ownerId);
        break;
      case EOwnershipType.IDENTITY_RESOURCE_SET_OWNERSHIP.valueOf():
        targetName = context === ContextType.OWNERS ? getResourceSetName(resourceSets?.resourceSets, targetId) : getIdentityName(identities?.identities, ownerId);
        break;
      case EOwnershipType.ROLE_RESOURCE_SET_OWNERSHIP.valueOf():
        targetName = context === ContextType.ROLES ? getResourceSetName(resourceSets?.resourceSets, targetId) : getRoleName(roles?.roles, ownerId);
        break;
      case EOwnershipType.IDENTITY_SYSTEM_OWNERSHIP.valueOf():
        targetName = context === ContextType.OWNERS ? getSystemName(systems?.systems, targetId) : getIdentityName(identities?.identities, ownerId);
        break;
      case EOwnershipType.ROLE_SYSTEM_OWNERSHIP.valueOf():
        targetName = context === ContextType.ROLES ? getSystemName(systems?.systems, targetId) : getRoleName(roles?.roles, ownerId);
        break;
      case EOwnershipType.IDENTITY_ROLE_OWNERSHIP.valueOf():
        targetName = context === ContextType.OWNERS ? getRoleName(roles?.roles, targetId) : getIdentityName(identities?.identities, ownerId);
        break;
      default:
        targetName = "";
    }
  }
  return {...edge, node: {...edge.node, targetName}} as any;
};

const patchOwnershipsCacheWithTargetsForIdentity = (cacheKey: string, resources?: GeneratedResourceTypes.GetResourcesQuery, resourceSets?: GeneratedResourceSetTypes.GetResourceSetsQuery, systems?: GeneratedSystemTypes.GetSystemsQuery, roles?: GeneratedRoleTypes.GetRolesQuery) => {
  return ownershipApi.util.updateQueryData("GetIdentityOwnerships", {
    endpointName: ownershipApi.endpoints.GetIdentityOwnerships.name,
    cacheKey: cacheKey
  } as any, currentCacheData => {
    currentCacheData?.ownerships?.edges.forEach((edge, index, array) => {
      if (edge?.node) {
        array[index] = setTargetName(edge, {
          resources,
          resourceSets,
          systems,
          roles
        }, ContextType.OWNERS);
      }
    });
    return currentCacheData;
  })
}

const patchOwnershipsCacheWithOwnersAndTargetsForRole = (cacheKey: string, resources?: GeneratedResourceTypes.GetResourcesQuery, resourceSets?: GeneratedResourceSetTypes.GetResourceSetsQuery, systems?: GeneratedSystemTypes.GetSystemsQuery, identities?: GeneratedIdentityTypes.GetIdentitiesQuery) => {
  return ownershipApi.util.updateQueryData("GetRoleOwnerships", {
    endpointName: ownershipApi.endpoints.GetRoleOwnerships.name,
    cacheKey: cacheKey
  } as any, currentCacheData => {
    currentCacheData?.ownerships?.edges.forEach((edge, index, array) => {
      if (edge?.node) {
        array[index] = setTargetName(edge, {
          resources,
          resourceSets,
          systems,
          identities
        }, ContextType.ROLES);
      }
    });
    return currentCacheData;
  })
}

const patchOwnershipsCacheWithOwnersForResource = (cacheKey: string, identities?: GeneratedIdentityTypes.GetIdentitiesQuery, roles?: GeneratedRoleTypes.GetRolesQuery) => {
  return ownershipApi.util.updateQueryData("GetResourceOwnerships", {
    endpointName: ownershipApi.endpoints.GetResourceOwnerships.name,
    cacheKey: cacheKey
  } as any, currentCacheData => {
    currentCacheData?.ownerships?.edges.forEach((edge, index, array) => {
      if (edge?.node) {
        array[index] = setTargetName(edge, {
          roles,
          identities
        }, ContextType.TARGETS);
      }
    });
    return currentCacheData;
  })
}

const patchOwnershipsCacheWithOwnersForResourceSet = (cacheKey: string, identities?: GeneratedIdentityTypes.GetIdentitiesQuery, roles?: GeneratedRoleTypes.GetRolesQuery) => {
  return ownershipApi.util.updateQueryData("GetResourceSetOwnerships", {
    endpointName: ownershipApi.endpoints.GetResourceSetOwnerships.name,
    cacheKey: cacheKey
  } as any, currentCacheData => {
    currentCacheData?.ownerships?.edges.forEach((edge, index, array) => {
      if (edge?.node) {
        array[index] = setTargetName(edge, {
          roles,
          identities
        }, ContextType.TARGETS);
      }
    });
    return currentCacheData;
  })
}

const patchOwnershipsCacheWithOwnersForSystem = (cacheKey: string, identities?: GeneratedIdentityTypes.GetIdentitiesQuery, roles?: GeneratedRoleTypes.GetRolesQuery) => {
  return ownershipApi.util.updateQueryData("GetSystemOwnerships", {
    endpointName: ownershipApi.endpoints.GetSystemOwnerships.name,
    cacheKey: cacheKey
  } as any, currentCacheData => {
    currentCacheData?.ownerships?.edges.forEach((edge, index, array) => {
      if (edge?.node) {
        array[index] = setTargetName(edge, {
          roles,
          identities
        }, ContextType.TARGETS);
      }
    });
    return currentCacheData;
  })
}
