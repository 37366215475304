export interface IItem {
  name: string;
  url: string;
  icon?: React.ElementType;
  counter?: number;
}

export interface ICategory {
  name: string;
  nested: IItem[];
  icon?: React.ElementType;
}

// Type guard to distinguish Category from Item
export const isCategory = (item: ICategory | IItem): item is ICategory => {
  return (item as ICategory)?.nested !== undefined;
};
