import { TZDate } from '@date-fns/tz';

export const convertUTCToLocalDate = (dateString?: string) => {
  if (!dateString) {
    return undefined;
  }

  // Indicate dateString is in UTC by adding "Z" to the end
  return new TZDate(dateString + 'Z', Intl.DateTimeFormat().resolvedOptions().timeZone);
};

/**
 * Convert local date to UTC, then remove the milliseconds and trailing Z, keeping the format YYYY-MM-DDTHH:mm:ss.
 */
export const dateToUTCStringWithoutTimezone = (date?: Date) => date ? date.toISOString().slice(0, 19) : undefined;
