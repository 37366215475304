import * as Types from '../identityApi.types';

import { identityBaseApi } from '../identityBaseApi';

          /* eslint-disable */
            /**
             *
             * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
             *
             * instead, edit one of the `.graphql` files in this project and run
             *
             * npm run graphql-codegen
             *
             * for this file to be re-created
             */
             
export type GetAccountRoleMembershipsQueryVariables = Types.Exact<{
  first?: Types.InputMaybe<Types.Scalars['Int']>;
  after?: Types.InputMaybe<Types.Scalars['String']>;
  sort?: Types.InputMaybe<Types.Scalars['String']>;
  filter?: Types.InputMaybe<Types.FilterExpression>;
}>;


export type GetAccountRoleMembershipsQuery = { __typename?: 'Query', accountRoleMemberships?: { __typename?: 'AccountRoleMembershipConnection', edges: Array<{ __typename?: 'AccountRoleMembershipEdge', node: { __typename?: 'AccountRoleMembership', id: string, active: boolean, validity?: { __typename?: 'Validity', validFrom?: string | null, validTo?: string | null } | null, account: { __typename?: 'Account', id: string, name: string, identityId: string, system?: { __typename?: 'System', id: string, systemName: string } | null }, role: { __typename?: 'Role', id: string, name: string }, meta?: { __typename?: 'Metadata', createdAt?: string | null, createdBy?: string | null, updatedAt?: string | null, updatedBy?: string | null } | null } } | null>, pageInfo: { __typename?: 'PageInfo', hasPreviousPage: boolean, hasNextPage: boolean, endCursor?: string | null, startCursor?: string | null } } | null };

export type UpdateAccountRoleMembershipMutationVariables = Types.Exact<{
  input?: Types.InputMaybe<Types.AccountRoleMembershipInput>;
  id: Types.Scalars['ID'];
}>;


export type UpdateAccountRoleMembershipMutation = { __typename?: 'Mutation', updateAccountRoleMembership: { __typename?: 'AccountRoleMembership', active: boolean, validity?: { __typename?: 'Validity', validFrom?: string | null, validTo?: string | null } | null, account: { __typename?: 'Account', id: string, name: string, identityId: string, system?: { __typename?: 'System', id: string, systemName: string } | null }, role: { __typename?: 'Role', id: string, name: string } } };


export const GetAccountRoleMembershipsDocument = `
    query GetAccountRoleMemberships($first: Int, $after: String, $sort: String, $filter: FilterExpression) {
  accountRoleMemberships(
    first: $first
    after: $after
    sort: $sort
    filter: $filter
  ) {
    edges {
      node {
        id
        validity {
          validFrom
          validTo
        }
        active
        account {
          id
          name
          identityId
          system {
            id
            systemName
          }
        }
        role {
          id
          name
        }
        meta {
          createdAt
          createdBy
          updatedAt
          updatedBy
        }
      }
    }
    pageInfo {
      hasPreviousPage
      hasNextPage
      endCursor
      startCursor
    }
  }
}
    `;
export const UpdateAccountRoleMembershipDocument = `
    mutation UpdateAccountRoleMembership($input: AccountRoleMembershipInput, $id: ID!) {
  updateAccountRoleMembership(input: $input, id: $id) {
    validity {
      validFrom
      validTo
    }
    active
    account {
      id
      name
      identityId
      system {
        id
        systemName
      }
    }
    role {
      id
      name
    }
  }
}
    `;

const injectedRtkApi = identityBaseApi.injectEndpoints({
  endpoints: (build) => ({
    GetAccountRoleMemberships: build.query<GetAccountRoleMembershipsQuery, GetAccountRoleMembershipsQueryVariables | void>({
      query: (variables) => ({ document: GetAccountRoleMembershipsDocument, variables })
    }),
    UpdateAccountRoleMembership: build.mutation<UpdateAccountRoleMembershipMutation, UpdateAccountRoleMembershipMutationVariables>({
      query: (variables) => ({ document: UpdateAccountRoleMembershipDocument, variables })
    }),
  }),
});

export { injectedRtkApi as api };
export const { useGetAccountRoleMembershipsQuery, useLazyGetAccountRoleMembershipsQuery, useUpdateAccountRoleMembershipMutation } = injectedRtkApi;

