import {ThemeOptions} from '@mui/material/styles';
import {Theme} from '@mui/material';

import './globalStyles.scss';

const PRIMARY_TEXT_COLOR = '--primaryTextColor';
const SELECTED_BACKGROUND_COLOR = 'rgba(0, 0, 0, 0.08)';

export const initialSivisThemeOptions = (): ThemeOptions => ({
  palette: {
    primary: {
      main:
        getComputedStyle(document.documentElement).getPropertyValue('--primaryColor').replace(/\s/g, '') || '#F29224'
    },
    secondary: {
      main:
        getComputedStyle(document.documentElement).getPropertyValue('--secondaryColor').replace(/\s/g, '') ||
        '#5B7DDB'
    },
    error: {
      main: getComputedStyle(document.documentElement).getPropertyValue('--errorColor').replace(/\s/g, '') ||
        '#FF1744'
    },
  },
  typography: {
    fontFamily:
      getComputedStyle(document.documentElement).getPropertyValue('--fontFamily').trim() ||
      'Source Sans Pro, sans-serif',
    allVariants: {
      color:
        getComputedStyle(document.documentElement)
        .getPropertyValue(PRIMARY_TEXT_COLOR)
        .replace(/\s/, '') || '#656B6D'
    },
    button: {
      textTransform: 'none'
    }
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          minWidth:
            getComputedStyle(document.documentElement).getPropertyValue('--buttonWidth').replace(/\s/, '') ||
            '120px',
          whiteSpace: 'nowrap',
          fontSize: '14px'
        },
        outlinedPrimary: {
          color:
            getComputedStyle(document.documentElement)
            .getPropertyValue(PRIMARY_TEXT_COLOR)
            .replace(/\s/, '') || '#656B6D'
        },
        outlinedSecondary: {
          color:
            getComputedStyle(document.documentElement)
            .getPropertyValue(PRIMARY_TEXT_COLOR)
            .replace(/\s/, '') || '#656B6D'
        },
        containedPrimary: {
          color:
            getComputedStyle(document.documentElement)
            .getPropertyValue('--secondaryTextColor')
            .replace(/\s/, '') || '#FFFFFF'
        },
        contained: {
          boxShadow: 'none',
          '&:hover': {
            boxShadow: 'none'
          }
        }
      }
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          padding: '7px 14px',
          fontSize: '13px',
          minWidth: '16px'
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        inputSizeSmall: {
          paddingTop: 0,
          paddingBottom: 0,
          height: '30px',
          fontSize: '14px',
          paddingLeft: '10px',
          color:
            getComputedStyle(document.documentElement)
            .getPropertyValue(PRIMARY_TEXT_COLOR)
            .replace(/\s/, '') || '#656B6D'
        }
      }
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          color:
            getComputedStyle(document.documentElement)
            .getPropertyValue(PRIMARY_TEXT_COLOR)
            .replace(/\s/, '') || '#656B6D',
          lineHeight: 'normal'
        }
      }
    },
    MuiTab: {
      styleOverrides: {
        textColorInherit: {
          opacity: 1
        },
        root: {
          minWidth: '160px'
        }
      }
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          color:
            getComputedStyle(document.documentElement)
            .getPropertyValue(PRIMARY_TEXT_COLOR)
            .replace(/\s/, '') || '#656B6D'
        }
      }
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          color: 'unset'
        }
      }
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          zIndex: '1100'
        }
      }
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: SELECTED_BACKGROUND_COLOR,
            '&:hover': {
              backgroundColor: SELECTED_BACKGROUND_COLOR
            }
          }
        }
      }
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: SELECTED_BACKGROUND_COLOR,
            '&:hover': {
              backgroundColor: SELECTED_BACKGROUND_COLOR
            }
          }
        }
      }
    },
    MuiStepLabel: {
      styleOverrides: {
        label: {
          '&.Mui-active': {
            color: getComputedStyle(document.documentElement)
            .getPropertyValue(PRIMARY_TEXT_COLOR)
            .replace(/\s/, '') || '#656B6D'
          }
        }
      }
    },
    MuiStepIcon: {
      styleOverrides: {
        text: {
          fill: '#FFFFFF'
        }
      }
    }
  }
});

export type SivisTheme = Theme;
