import { Link, matchPath, useLocation } from 'react-router-dom';
import {  Box,  ListItemButton,  ListItemIcon,  ListItemText,  pointsharpThemeOptions} from '@pointsharp/component-library';

import { IItem } from './utils';
import { SimplePaletteColorOptions } from '@mui/material/styles/createPalette';
import { useEffect, useState } from 'react';

const style = {
  item: {
    color: pointsharpThemeOptions?.palette?.background?.almostWhite,
    minHeight: 48,
    pl: 2.5,
    '&:hover': {
      backgroundColor: pointsharpThemeOptions?.palette?.background?.almostWhite,
      color: pointsharpThemeOptions?.palette?.background?.almostBlack,
      '& .MuiListItemIcon-root': {
        color: pointsharpThemeOptions?.palette?.background?.almostBlack,
      },
    },
  },
  nestedItem: {
    pl: 4,
  },
  selectedItem: {
    backgroundColor: pointsharpThemeOptions?.palette?.background?.almostWhite,
    color: pointsharpThemeOptions?.palette?.background?.almostBlack,
    '& .MuiListItemIcon-root': {
      color: pointsharpThemeOptions?.palette?.background?.almostBlack,
    },
  },
  icon: {
    color: pointsharpThemeOptions?.palette?.background?.almostWhite,
    minWidth: 24,
    mr: 2.5,
  },
  link: {
    textDecoration: 'none',
    color: pointsharpThemeOptions?.palette?.background?.almostWhite,
  },
  counter: {
    minWidth: 24,
    height: 24,
    gap: 6,
    color: pointsharpThemeOptions!.palette!.background!.almostWhite!,
    borderRadius: 24,
    background: (pointsharpThemeOptions?.palette?.success as SimplePaletteColorOptions)?.main,
    lineHeight: '24px',
    textAlign: 'center',
    fontSize: 16,
    fontStyle: 'normal',
    fontWeight: 700,
    letterSpacing: 0.08,
  },
};
interface PsSidebarItemProps {
  item: IItem;
  onSelect: (item: IItem) => void;
  selectedItem: IItem | null;
  isNested?: boolean;
}

export const PsSidebarItem = ({
                                 item,
                                 onSelect,
                                 selectedItem,
                                 isNested = false,
                               }: PsSidebarItemProps) => {
  const location = useLocation();
  const [selected, setSelected] = useState(false);

  useEffect(() => {
    setSelected(!!matchPath({path: item.url, end: false}, location.pathname));
  }, [location.pathname]);

  useEffect(() => {
    if (selected) {
      onSelect(item);
    }
  }, [selected]);

  return (
    <Link
      to={item.url}
      style={style.link}
      onClick={() => {
        setSelected(!!matchPath({path: item.url, end: false}, location.pathname));
        onSelect(item);
      }}
    >
      <ListItemButton
        sx={{
          ...style.item,
          ...(selectedItem?.url === item.url && style.selectedItem),
          ...(isNested && style.nestedItem),
        }}
      >
        <ListItemIcon sx={style.icon}>
          {item.icon && <item.icon />}
        </ListItemIcon>
        <ListItemText primary={item.name} />
        {item?.counter != null && <Box sx={style.counter}>{item?.counter}</Box>}
      </ListItemButton>
    </Link>
  );
};
