import {
  EntitlementType,
  ExtendedResourceEntitlement,
  RelationQueryType,
  Role
} from '@sivis/identity/api';
import { useRoleResourceEntitlementsApi } from '../entitlement/api/useRoleResourceEntitlementsApi';
import {
  getEntitlementFlags,
  getResourceEntitlementColumnsWithFlags,
  getRoleResourceEntitlementDescription,
  mapTableRowToEntitlement,
  resourceEntitlementToTableRow,
  useEntitlementMessages
} from '../entitlement/entitlementUtils';
import styles from './role.module.scss';
import { EntityTableRow, ListModifyViewUpdated } from '@sivis/shared/components/entityView';
import { useCustomIntl, useTranslateColumns } from '@sivis/intl';
import { ConfirmationPopup } from '../components/popup/confirmationPopup';
import React from 'react';
import { RoleResourceEntitlementDetails } from '../entitlement/roleResourceEntitlementDetails';
import {
  useResourceEntitlementFilter
} from '../entitlement/components/useResourceEntitlementFilter';
import { createEntitlementPopup } from '../entitlement/createEntitlementPopup';
import { isEqualById } from '@sivis/shared/utils';

interface RoleResourceViewProps {
  role: Role;
  editMode: boolean;
  onCancel?: () => void;
}

export const RoleResourceView = ({role, editMode, onCancel}: RoleResourceViewProps) => {
  const intl = useCustomIntl();
  const {deleteEntitlementMessage} = useEntitlementMessages();
  const {
    activeFilter,
    filterComponent
  } = useResourceEntitlementFilter(
    EntitlementType.ROLE_RESOURCE_ENTITLEMENT,
    RelationQueryType.ROLE_RESOURCE_ENTITLEMENTS,
    role?.id
  );
  const {
    onSave,
    submitted,
    setSubmitted,
    pageSize,
    useEntitlementsPageQuery,
    parseEntitlementsPageResult
  } = useRoleResourceEntitlementsApi(role, activeFilter ?? undefined);

  const getDescription = (entitlement: ExtendedResourceEntitlement, role: Role) =>
    getRoleResourceEntitlementDescription(entitlement, role.name, entitlement.resourceName ?? "", intl.format);

  const getPopupTitle = (entitlement: ExtendedResourceEntitlement) => ({
    primaryTitle: intl.format("fusion.entitlement.title.edit"),
    secondaryTitle: getDescription(entitlement, role)
  });

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const onSearch = () => {
  };

  const translatedResourceEntitlementColumns = useTranslateColumns(getResourceEntitlementColumnsWithFlags(), "fusion.entitlement.resourceEntitlement.propertyName")
  const shownColumns = editMode ? translatedResourceEntitlementColumns : translatedResourceEntitlementColumns.filter(column => column.field !== "action");

  const resourceEntitlementToTableRowWithFlags = (
    entity: ExtendedResourceEntitlement,
    isNew: boolean,
    onEdit?: (entity: ExtendedResourceEntitlement) => void,
    onDelete?: (entity: ExtendedResourceEntitlement) => void): EntityTableRow => {
    return {
      ...resourceEntitlementToTableRow(entity, isNew, onEdit, onDelete),
      flags: getEntitlementFlags({validity: entity.validity}, intl.format)
    };
  };

  const confirmationPopup = role ? <ConfirmationPopup
    open={submitted}
    onClose={() => setSubmitted(false)}
    primaryTitle={intl.format("fusion.entitlement.popup.title", {entity: role.name})}
    message={intl.format("fusion.message.submitted", {entity: role.name})}
  /> : null;

  const createPopup = createEntitlementPopup({
    primaryTitle: intl.format("fusion.entitlement.resourceEntitlement.role.createPopup.title"),
    secondaryTitle: intl.formatBold(
      "fusion.entitlement.resourceEntitlement.role.createPopup.description",
      {role: role?.name ?? ""}
    ),
    type: EntitlementType.ROLE_RESOURCE_ENTITLEMENT,
    role
  });

  return <div className={styles.listContainer}>
    {confirmationPopup}
    <ListModifyViewUpdated
      columns={shownColumns}
      onSave={onSave}
      entityToTableRow={resourceEntitlementToTableRowWithFlags}
      onSearch={onSearch}
      editMode={editMode}
      getEntityTitle={getPopupTitle}
      isEqual={isEqualById}
      entityDetails={RoleResourceEntitlementDetails}
      deleteMessage={deleteEntitlementMessage}
      mapTableRowToEntity={mapTableRowToEntitlement}
      pageSize={pageSize}
      usePageQuery={useEntitlementsPageQuery}
      parseResult={parseEntitlementsPageResult}
      createButtonText="fusion.entitlement.resourceEntitlement.add"
      createPopup={createPopup}
      sideFilter={filterComponent}
      tableType={RelationQueryType.ROLE_RESOURCE_ENTITLEMENTS}
      onCancel={onCancel}
    />
  </div>;
};
