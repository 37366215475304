import {
  GeneratedIdentityRoleMembershipTypes,
  Identity,
  IdentityRoleMembership,
  searchMembershipsInIdentityFilter,
  useCreateIdentityRoleMembershipMutation,
  useDeleteIdentityRoleMembershipMutation,
  useGetIdentityRoleMembershipsQuery
} from '@sivis/identity/api';
import { useState } from 'react';
import { useListSearch } from '../../utils/filterUtils';
import {
  DEFAULT_ENTITY_SORT,
  DEFAULT_PAGE_SIZE,
  parseCursorBasedPageResult
} from '../../common/parsePageResult';
import { CursorBasedPageResult } from '@sivis/shared/components/entityView';
import { combineFilter, FilterOperator } from '@sivis/shared/api-utils';

export const parseMembershipsPageResult = (res?: GeneratedIdentityRoleMembershipTypes.GetIdentityRoleMembershipsQuery): CursorBasedPageResult<IdentityRoleMembership> => {
  return parseCursorBasedPageResult('identityRoleMemberships', res);
};

export const useRoleIdentityMembershipApi = (toggleEditMode: () => void, identity?: Identity) => {
  const identityId = identity?.id;
  const {
    filter: searchMembershipsFilter,
    onSearch
  } = useListSearch(searchMembershipsInIdentityFilter);
  const identityFilter = {
    name: "identity.id",
    operator: FilterOperator.EQ,
    uuidValue: identityId
  };
  const membershipsFilter = searchMembershipsFilter ? combineFilter(FilterOperator.AND, searchMembershipsFilter, identityFilter)
    : identityFilter;
  const [deleteMembership] = useDeleteIdentityRoleMembershipMutation();
  const [createMembership] = useCreateIdentityRoleMembershipMutation();
  const [submitted, setSubmitted] = useState(false);

  const useMembershipsPageQuery = (cursor: string | null) =>
    useGetIdentityRoleMembershipsQuery(
      {
        first: DEFAULT_PAGE_SIZE,
        after: cursor,
        sort: DEFAULT_ENTITY_SORT,
        filter: membershipsFilter
      },
      {skip: !identityId}
    );

  const onSave = (added: IdentityRoleMembership[], deleted: IdentityRoleMembership[]) => {
    if (!identityId) {
      return Promise.resolve();
    }
    return Promise.all([
      ...added.map(membership => createMembership({
        identityId, roleId: membership.role.id,
        input: {}
      })),
      ...deleted.map(membership => deleteMembership({id: membership.id}))
    ]).then(() => {
      setSubmitted(true);
      toggleEditMode();
    });
  };

  return {
    onSave,
    onSearch,
    submitted,
    setSubmitted,
    pageSize: DEFAULT_PAGE_SIZE,
    useMembershipsPageQuery,
    parseMembershipsPageResult
  };
};
