import { List, ListItem, ListItemButton } from "@mui/material";
import {
  combineFilter,
  FilterExpression,
  FilterOperator,
  LogicalOperator
} from "@sivis/shared/api-utils";
import { useEffect, useState } from "react";
import styles from "./table.module.scss";

interface SideFilterProps {
  title: string;
  onFilterChange?: (filter: FilterExpression | null) => void;
  filterGroups: FilterGroupDef[];
}

interface FilterGroupDef {
  id: string;
  title: string;
  filters: FilterItemDef[];
}

interface FilterItemDef {
  id: string;
  text: string;
  filter: FilterExpression;
  count: number;
}

const useFilterMap = (operator: LogicalOperator, onChange?: (filter: FilterExpression | null) => void) => {
  const [activeFilter, setActiveFilter] = useState<Map<string, FilterExpression>>(new Map());

  useEffect(() => {
    onChange?.(activeFilter.size ? combineFilter(operator, ...activeFilter.values()) : null);
  }, [activeFilter]);

  const onSetFilter = (key: string, filter: FilterExpression | null) => {
    if (filter == null) {
      const newFilter = new Map(activeFilter);
      newFilter.delete(key);
      setActiveFilter(newFilter);
    } else {
      setActiveFilter(new Map(activeFilter.set(key, filter)));
    }
  }

  const onToggleFilter = (key: string, filter: FilterExpression) => {
    onSetFilter(key, activeFilter.has(key) ? null : filter);
  };

  return {
    activeFilter,
    onToggleFilter,
    onSetFilter
  }
};

export const SideFilterNew = (props: SideFilterProps) => {
  const {onSetFilter} = useFilterMap(FilterOperator.AND, props.onFilterChange);

  return <div className={styles.filterContainer}>
    <div className={styles.filterContentContainer}>
      <List disablePadding>
        {props.filterGroups.map(group => <FilterGroupNew
          {...group}
          key={group.id}
          onFilterChange={filter => onSetFilter(group.id, filter)}
        />)}
      </List>
    </div>
  </div>;
};

interface FilterGroupNewProps extends FilterGroupDef {
  onFilterChange: (filter: FilterExpression | null) => void;
}

const FilterGroupNew = (props: FilterGroupNewProps) => {
  const {activeFilter, onToggleFilter} = useFilterMap(FilterOperator.OR, props.onFilterChange);

  return <ListItem disablePadding key={props.id}>
    <div className={styles.filterGroupContainer}>
      <div className={styles.filterGroupTitle}>{props.title}</div>
      <List disablePadding>
        {props.filters.map(filter => <FilterItem
          key={filter.id}
          filterKey={filter.id}
          filter={filter.filter}
          active={activeFilter.has(filter.id)}
          count={filter.count}
          onClick={onToggleFilter}
          text={filter.text}
        />)}
      </List>
    </div>
  </ListItem>;
};

interface FilterItemProps {
  filterKey: string;
  filter: FilterExpression;
  active: boolean;
  text: string;
  count: number;
  onClick: (key: string, filter: FilterExpression) => void;
}

const FILTER_TEXT_COLOR_ACTIVE = "#3171E6";
const FILTER_TEXT_COLOR = "#656b6d";
const FILTER_BG_COLOR = "#F0F5FF";

const FilterItem = (props: FilterItemProps) => {
  const onClick = () => {
    props.onClick(props.filterKey, props.filter);
  };

  return <ListItem disablePadding key={props.filterKey}>
    <ListItemButton onClick={onClick} sx={{
      backgroundColor: props.active ? FILTER_BG_COLOR : undefined,
      color: props.active ? FILTER_TEXT_COLOR_ACTIVE : FILTER_TEXT_COLOR
    }}>
      <div className={styles.filterText}>
        <div>{props.text}</div>
        <div>{props.count}</div>
      </div>
    </ListItemButton>
  </ListItem>;
};
