import SearchIconOutlined from '@mui/icons-material/Search';
import {
  Box,
  pointsharpThemeOptions,
  TextField,
  Typography,
} from '@pointsharp/component-library';
import { isEnterEvent } from '@sivis/shared/utils';
import React, { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';

export interface SearchFieldProps {
  placeholder: string;
  totalEntries?: number;
  buttons?: JSX.Element[];
  onSearch: (searchStr: string) => void;
}

export const SearchField = ({
  placeholder,
  totalEntries,
  buttons,
  onSearch,
}: SearchFieldProps) => {
  const intl = useIntl();
  const style = {
    outerBox: {
      display: 'flex',
      py: 2,
      px: 2,
      justifyContent: 'space-between',
      alignItems: 'center',
      borderBottom: '1px solid #000',
      bgcolor: pointsharpThemeOptions.palette?.background?.almostWhite,
    },
    innerBox: {
      fullWidth: true,
      px: '5px',
      height: '25px',
    },
    typography: {
      color: pointsharpThemeOptions.palette?.grey?.[800],
    },
  };

  const [input, setInput] = useState('');

  const onChangeInput = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      setInput(e.target.value);
    },
    []
  );

  const searchInput = useCallback(() => onSearch(input), [onSearch, input]);

  const onKeyDown = useCallback(
    (e: React.KeyboardEvent) => {
      if (isEnterEvent(e)) {
        e.preventDefault();
        searchInput();
      }
    },
    [searchInput]
  );

  return (
    <Box sx={style.outerBox}>
      <Box display="flex" alignItems="center" gap={1} flex={1} maxWidth="300px">
        <SearchIconOutlined color="action" />
        <TextField
          variant="standard"
          InputProps={{ disableUnderline: true }}
          placeholder={placeholder}
          onChange={onChangeInput}
          onKeyDown={onKeyDown}
          sx={style.innerBox}
        />
      </Box>
      {totalEntries && totalEntries > 0 && (
        <Typography variant="body1" sx={style.typography}>
          {totalEntries} {intl.formatMessage({ id: 'fusion.table.entry' }, { entries: totalEntries })}
        </Typography>
      )}

      {buttons}
    </Box>
  );
};
