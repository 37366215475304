import {
  Account,
  GeneratedAccountTypes,
  searchAccountsFilter,
  useGetAccountsQuery
} from '@sivis/identity/api';
import { CursorBasedPageResult } from '@sivis/shared/components/entityView';
import {
  DEFAULT_ENTITY_SORT,
  DEFAULT_PAGE_SIZE,
  parseCursorBasedPageResult
} from '../common/parsePageResult';
import { getExistingOrCombinedFilter, useListSearch } from '../utils/filterUtils';
import { FilterOperator } from '@sivis/shared/api-utils';

export const parseAccountsPageResult = (res?: GeneratedAccountTypes.GetAccountsQuery): CursorBasedPageResult<Account> => {
  return parseCursorBasedPageResult('accounts', res);
};

interface AccountQueryProps {
  accountTypeId?: string;
  identityId?: string;
}

export const useAccountsPageApi = (
  { accountTypeId, identityId }: AccountQueryProps = {}
) => {
  const { filter: searchFilter, onSearch } = useListSearch(searchAccountsFilter);

  let accountTypeFilter;
  let identityTypeFiler;
  if (accountTypeId) {
    accountTypeFilter = {
      operator: FilterOperator.EQ,
      name: 'accountType.id',
      uuidValue: accountTypeId
    };
  }
  if (identityId) {
    identityTypeFiler = {
      operator: FilterOperator.EQ,
      name: 'identity.id',
      uuidValue: identityId
    };
  }
  const identityAndAccountFilter = getExistingOrCombinedFilter(accountTypeFilter, identityTypeFiler);
  const filter = getExistingOrCombinedFilter(identityAndAccountFilter, searchFilter);

  const useAccountsPageQuery = (cursor: string | null) => {
    const queryResult = useGetAccountsQuery({
      first: DEFAULT_PAGE_SIZE,
      after: cursor,
      sort: DEFAULT_ENTITY_SORT,
      filter: filter
    });

    return {
      ...queryResult,
      refetch: queryResult.refetch,
    };
  };

  return {
    onSearch,
    pageSize: DEFAULT_PAGE_SIZE,
    useAccountsPageQuery,
    parseAccountsPageResult
  };
};
