import { GraphQLResolveInfo } from 'graphql';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type RequireFields<T, K extends keyof T> = Omit<T, K> & { [P in K]-?: NonNullable<T[P]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

export type Account = {
  __typename?: 'Account';
  accountType: AccountType;
  customFields?: Maybe<CustomFields>;
  externalEntityIds?: Maybe<ExternalEntityIds>;
  id: Scalars['ID'];
  identityId: Scalars['ID'];
  meta?: Maybe<Metadata>;
  name: Scalars['String'];
  system?: Maybe<System>;
};

export type AccountConnection = {
  __typename?: 'AccountConnection';
  edges: Array<Maybe<AccountEdge>>;
  pageInfo: PageInfo;
};

export type AccountEdge = {
  __typename?: 'AccountEdge';
  cursor: Scalars['String'];
  node: Account;
};

export type AccountFieldDefinition = {
  __typename?: 'AccountFieldDefinition';
  fieldDefinitionFields: FieldDefinitionFields;
  id: Scalars['ID'];
  system: Scalars['ID'];
};

export type AccountFieldDefinitionInput = {
  fieldDefinitionFields: FieldDefinitionFieldsInput;
  system: Scalars['ID'];
};

export type AccountInput = {
  accountType: Scalars['ID'];
  customFields?: InputMaybe<CustomFieldsInput>;
  externalEntityIds?: InputMaybe<ExternalEntityIdsInput>;
  identityId?: InputMaybe<Scalars['ID']>;
  name: Scalars['String'];
  system?: InputMaybe<Scalars['ID']>;
};

export type AccountRoleMembership = {
  __typename?: 'AccountRoleMembership';
  account: Account;
  active: Scalars['Boolean'];
  id: Scalars['ID'];
  meta?: Maybe<Metadata>;
  role: Role;
  validity?: Maybe<Validity>;
};

export type AccountRoleMembershipConnection = {
  __typename?: 'AccountRoleMembershipConnection';
  edges: Array<Maybe<AccountRoleMembershipEdge>>;
  pageInfo: PageInfo;
};

export type AccountRoleMembershipEdge = {
  __typename?: 'AccountRoleMembershipEdge';
  cursor: Scalars['String'];
  node: AccountRoleMembership;
};

export type AccountRoleMembershipInput = {
  accountId?: InputMaybe<Scalars['ID']>;
  active?: InputMaybe<Scalars['Boolean']>;
  roleId?: InputMaybe<Scalars['ID']>;
  validity?: InputMaybe<ValidityInput>;
};

export type AccountType = {
  __typename?: 'AccountType';
  apiName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  meta?: Maybe<Metadata>;
  name: Scalars['String'];
};

export type AccountTypeConnection = {
  __typename?: 'AccountTypeConnection';
  edges: Array<Maybe<AccountTypeEdge>>;
  pageInfo: PageInfo;
};

export type AccountTypeEdge = {
  __typename?: 'AccountTypeEdge';
  cursor: Scalars['String'];
  node: AccountType;
};

export type AccountTypeInput = {
  apiName?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/**  Address */
export type Address = {
  __typename?: 'Address';
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  address3?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  defaultLanguage?: Maybe<Scalars['String']>;
  defaultTimeZone?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  fax?: Maybe<Scalars['String']>;
  faxExtension?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  meta?: Maybe<Metadata>;
  mobilePhone?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  organizationalEntities?: Maybe<OrganizationalEntityConnection>;
  phone?: Maybe<Scalars['String']>;
  phoneExtension?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  searchTerm?: Maybe<Scalars['String']>;
  standardCommunicationMethod?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  zipcode?: Maybe<Scalars['String']>;
};


/**  Address */
export type AddressOrganizationalEntitiesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<FilterExpression>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Scalars['String']>;
};

export type AddressConnection = {
  __typename?: 'AddressConnection';
  edges?: Maybe<Array<Maybe<AddressEdge>>>;
  pageInfo?: Maybe<PageInfo>;
};

export type AddressEdge = {
  __typename?: 'AddressEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<Address>;
};

export type AddressInput = {
  address1?: InputMaybe<Scalars['String']>;
  address2?: InputMaybe<Scalars['String']>;
  address3?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  defaultLanguage?: InputMaybe<Scalars['String']>;
  defaultTimeZone?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  fax?: InputMaybe<Scalars['String']>;
  faxExtension?: InputMaybe<Scalars['String']>;
  mobilePhone?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  phoneExtension?: InputMaybe<Scalars['String']>;
  region?: InputMaybe<Scalars['String']>;
  searchTerm?: InputMaybe<Scalars['String']>;
  standardCommunicationMethod?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['String']>;
  updatedBy?: InputMaybe<Scalars['String']>;
  zipcode?: InputMaybe<Scalars['String']>;
};

export type BusinessRule = {
  __typename?: 'BusinessRule';
  changes?: Maybe<Scalars['String']>;
  criteriaGroups?: Maybe<Array<CriteriaGroup>>;
  id: Scalars['ID'];
  isPublished?: Maybe<Scalars['Boolean']>;
  meta?: Maybe<Metadata>;
  name: Scalars['String'];
  operatorOutsideGroup: RuleLogicalOperator;
  publishedId?: Maybe<Scalars['ID']>;
  ruleAction: RuleAction;
  status: RuleStatus;
  targetValue: Scalars['String'];
};

export type BusinessRuleConnection = {
  __typename?: 'BusinessRuleConnection';
  edges: Array<Maybe<BusinessRuleEdge>>;
  pageInfo: PageInfo;
};

export type BusinessRuleEdge = {
  __typename?: 'BusinessRuleEdge';
  cursor: Scalars['String'];
  node: BusinessRule;
};

export type BusinessRuleInput = {
  changes?: InputMaybe<Scalars['String']>;
  criteriaGroups?: InputMaybe<Array<InputMaybe<CriteriaGroupInput>>>;
  isPublished?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  operatorOutsideGroup?: InputMaybe<RuleLogicalOperator>;
  publishedId?: InputMaybe<Scalars['ID']>;
  ruleAction?: InputMaybe<RuleAction>;
  status?: InputMaybe<RuleStatus>;
  targetValue?: InputMaybe<Scalars['String']>;
};

export type BusinessRuleKey = {
  __typename?: 'BusinessRuleKey';
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  ruleAction?: Maybe<RuleAction>;
  targetValue?: Maybe<Scalars['String']>;
};

export type CriteriaGroup = {
  __typename?: 'CriteriaGroup';
  criteriaInsideGroup: Array<Criterion>;
  id: Scalars['ID'];
  meta?: Maybe<Metadata>;
};

export type CriteriaGroupInput = {
  criteriaGroupId?: InputMaybe<Scalars['ID']>;
  criteriaInsideGroup?: InputMaybe<Array<InputMaybe<CriterionInput>>>;
};

export type Criterion = {
  __typename?: 'Criterion';
  entityField: Scalars['String'];
  id: Scalars['ID'];
  meta?: Maybe<Metadata>;
  negate?: Maybe<Scalars['Boolean']>;
  operation: RuleOperation;
  value: Scalars['String'];
};

export type CriterionInput = {
  criterionId?: InputMaybe<Scalars['ID']>;
  entityField?: InputMaybe<Scalars['String']>;
  negate?: InputMaybe<Scalars['Boolean']>;
  operation: RuleOperation;
  value?: InputMaybe<Scalars['String']>;
};

export type CustomFields = {
  __typename?: 'CustomFields';
  customField1?: Maybe<Scalars['String']>;
  customField2?: Maybe<Scalars['String']>;
  customField3?: Maybe<Scalars['String']>;
  customField4?: Maybe<Scalars['String']>;
  customField5?: Maybe<Scalars['String']>;
  customField6?: Maybe<Scalars['String']>;
  customField7?: Maybe<Scalars['String']>;
  customField8?: Maybe<Scalars['String']>;
  customField9?: Maybe<Scalars['String']>;
  customField10?: Maybe<Scalars['String']>;
  customField11?: Maybe<Scalars['String']>;
  customField12?: Maybe<Scalars['String']>;
  customField13?: Maybe<Scalars['String']>;
  customField14?: Maybe<Scalars['String']>;
  customField15?: Maybe<Scalars['String']>;
  customField16?: Maybe<Scalars['String']>;
  customField17?: Maybe<Scalars['String']>;
  customField18?: Maybe<Scalars['String']>;
  customField19?: Maybe<Scalars['String']>;
};

export type CustomFieldsInput = {
  customField1?: InputMaybe<Scalars['String']>;
  customField2?: InputMaybe<Scalars['String']>;
  customField3?: InputMaybe<Scalars['String']>;
  customField4?: InputMaybe<Scalars['String']>;
  customField5?: InputMaybe<Scalars['String']>;
  customField6?: InputMaybe<Scalars['String']>;
  customField7?: InputMaybe<Scalars['String']>;
  customField8?: InputMaybe<Scalars['String']>;
  customField9?: InputMaybe<Scalars['String']>;
  customField10?: InputMaybe<Scalars['String']>;
  customField11?: InputMaybe<Scalars['String']>;
  customField12?: InputMaybe<Scalars['String']>;
  customField13?: InputMaybe<Scalars['String']>;
  customField14?: InputMaybe<Scalars['String']>;
  customField15?: InputMaybe<Scalars['String']>;
  customField16?: InputMaybe<Scalars['String']>;
  customField17?: InputMaybe<Scalars['String']>;
  customField18?: InputMaybe<Scalars['String']>;
  customField19?: InputMaybe<Scalars['String']>;
};

export type Entitlement = {
  __typename?: 'Entitlement';
  entitledId: Scalars['ID'];
  id: Scalars['ID'];
  meta?: Maybe<Metadata>;
  resourceTypeId?: Maybe<Scalars['ID']>;
  systemId?: Maybe<Scalars['ID']>;
  targetId: Scalars['ID'];
  type: Scalars['String'];
  validity?: Maybe<Validity>;
};

export type EntitlementConnection = {
  __typename?: 'EntitlementConnection';
  edges: Array<Maybe<EntitlementEdge>>;
  pageInfo: PageInfo;
};

export type EntitlementEdge = {
  __typename?: 'EntitlementEdge';
  cursor: Scalars['String'];
  node: Entitlement;
};

export type EntitlementInput = {
  resourceTypeId?: InputMaybe<Scalars['ID']>;
  systemId?: InputMaybe<Scalars['ID']>;
  validity?: InputMaybe<ValidityInput>;
};

export type ExternalEntityIds = {
  __typename?: 'ExternalEntityIds';
  externalEntityId1?: Maybe<Scalars['String']>;
  externalEntityId2?: Maybe<Scalars['String']>;
  externalEntityId3?: Maybe<Scalars['String']>;
  externalEntityId4?: Maybe<Scalars['String']>;
  externalEntityId5?: Maybe<Scalars['String']>;
  externalEntityId6?: Maybe<Scalars['String']>;
  externalEntityId7?: Maybe<Scalars['String']>;
  externalEntityId8?: Maybe<Scalars['String']>;
  externalEntityId9?: Maybe<Scalars['String']>;
  externalEntityId10?: Maybe<Scalars['String']>;
  externalEntityId11?: Maybe<Scalars['String']>;
  externalEntityId12?: Maybe<Scalars['String']>;
  externalEntityId13?: Maybe<Scalars['String']>;
  externalEntityId14?: Maybe<Scalars['String']>;
  externalEntityId15?: Maybe<Scalars['String']>;
  externalEntityId16?: Maybe<Scalars['String']>;
  externalEntityId17?: Maybe<Scalars['String']>;
  externalEntityId18?: Maybe<Scalars['String']>;
  externalEntityId19?: Maybe<Scalars['String']>;
};

export type ExternalEntityIdsInput = {
  externalEntityId1?: InputMaybe<Scalars['String']>;
  externalEntityId2?: InputMaybe<Scalars['String']>;
  externalEntityId3?: InputMaybe<Scalars['String']>;
  externalEntityId4?: InputMaybe<Scalars['String']>;
  externalEntityId5?: InputMaybe<Scalars['String']>;
  externalEntityId6?: InputMaybe<Scalars['String']>;
  externalEntityId7?: InputMaybe<Scalars['String']>;
  externalEntityId8?: InputMaybe<Scalars['String']>;
  externalEntityId9?: InputMaybe<Scalars['String']>;
  externalEntityId10?: InputMaybe<Scalars['String']>;
  externalEntityId11?: InputMaybe<Scalars['String']>;
  externalEntityId12?: InputMaybe<Scalars['String']>;
  externalEntityId13?: InputMaybe<Scalars['String']>;
  externalEntityId14?: InputMaybe<Scalars['String']>;
  externalEntityId15?: InputMaybe<Scalars['String']>;
  externalEntityId16?: InputMaybe<Scalars['String']>;
  externalEntityId17?: InputMaybe<Scalars['String']>;
  externalEntityId18?: InputMaybe<Scalars['String']>;
  externalEntityId19?: InputMaybe<Scalars['String']>;
};

export type FieldDefinitionFields = {
  __typename?: 'FieldDefinitionFields';
  apiName: Scalars['String'];
  autoValue?: Maybe<Scalars['String']>;
  autoValueDefinition?: Maybe<Scalars['String']>;
  autoValueSetting?: Maybe<Scalars['String']>;
  defaultValue?: Maybe<Scalars['String']>;
  fieldName: Scalars['String'];
  fieldType: ValueTypes;
  helpText?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  readOnly: Scalars['Boolean'];
  required: Scalars['Boolean'];
  searchable: Scalars['Boolean'];
  uiMaxCharacters: Scalars['Int'];
  uiPosition: Scalars['Int'];
  uniqueField: Scalars['Boolean'];
  visible: Scalars['Boolean'];
};

export type FieldDefinitionFieldsInput = {
  apiName: Scalars['String'];
  autoValue?: InputMaybe<Scalars['String']>;
  autoValueDefinition?: InputMaybe<Scalars['String']>;
  autoValueSetting?: InputMaybe<Scalars['String']>;
  defaultValue?: InputMaybe<Scalars['String']>;
  fieldName: Scalars['String'];
  fieldType: ValueTypes;
  helpText?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  readOnly: Scalars['Boolean'];
  required: Scalars['Boolean'];
  searchable: Scalars['Boolean'];
  uiMaxCharacters: Scalars['Int'];
  uiPosition: Scalars['Int'];
  uniqueField: Scalars['Boolean'];
  visible: Scalars['Boolean'];
};

export type FilterEntry = {
  __typename?: 'FilterEntry';
  count: Scalars['Float'];
  filter?: Maybe<FilterExpressionOutput>;
  id: Scalars['ID'];
  text?: Maybe<Scalars['String']>;
};

export type FilterExpression = {
  booleanValue?: InputMaybe<Scalars['Boolean']>;
  dateValue?: InputMaybe<Scalars['String']>;
  enumValue?: InputMaybe<Scalars['String']>;
  floatValue?: InputMaybe<Scalars['Float']>;
  intValue?: InputMaybe<Scalars['Int']>;
  logicalValue?: InputMaybe<Array<InputMaybe<FilterExpression>>>;
  name?: InputMaybe<Scalars['String']>;
  negate?: InputMaybe<Scalars['Boolean']>;
  operator: Scalars['String'];
  stringValue?: InputMaybe<Scalars['String']>;
  uuidValue?: InputMaybe<Scalars['ID']>;
};

export type FilterExpressionOutput = {
  __typename?: 'FilterExpressionOutput';
  booleanValue?: Maybe<Scalars['Boolean']>;
  dateValue?: Maybe<Scalars['String']>;
  enumValue?: Maybe<Scalars['String']>;
  floatValue?: Maybe<Scalars['Float']>;
  intValue?: Maybe<Scalars['Int']>;
  logicalValue?: Maybe<Array<Maybe<FilterExpressionOutput>>>;
  name?: Maybe<Scalars['String']>;
  negate?: Maybe<Scalars['Boolean']>;
  operator: Scalars['String'];
  stringValue?: Maybe<Scalars['String']>;
  uuidValue?: Maybe<Scalars['ID']>;
};

export type Identity = {
  __typename?: 'Identity';
  accountingId?: Maybe<Scalars['String']>;
  accountsCount?: Maybe<Scalars['Int']>;
  companyMobilePhone?: Maybe<Scalars['String']>;
  companyPhone?: Maybe<Scalars['String']>;
  costCenter?: Maybe<Scalars['String']>;
  customFields?: Maybe<CustomFields>;
  employeeId?: Maybe<Scalars['String']>;
  externalEntityIds?: Maybe<ExternalEntityIds>;
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  identityType: IdentityType;
  identityTypeField?: Maybe<Scalars['String']>;
  initials?: Maybe<Scalars['String']>;
  lastName: Scalars['String'];
  manager?: Maybe<Scalars['String']>;
  meta?: Maybe<Metadata>;
  primaryEmail?: Maybe<Scalars['String']>;
  privateEmail?: Maybe<Scalars['String']>;
  privateMobilePhone?: Maybe<Scalars['String']>;
  roleMemberships?: Maybe<IdentityRoleMembershipConnection>;
  roles?: Maybe<RoleConnection>;
  salutation?: Maybe<Scalars['String']>;
  secondaryManager?: Maybe<Scalars['String']>;
  substitute?: Maybe<Scalars['String']>;
};


export type IdentityRoleMembershipsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<FilterExpression>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Scalars['String']>;
};


export type IdentityRolesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<FilterExpression>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Scalars['String']>;
};

export type IdentityConnection = {
  __typename?: 'IdentityConnection';
  edges: Array<Maybe<IdentityEdge>>;
  pageInfo: PageInfo;
};

export type IdentityEdge = {
  __typename?: 'IdentityEdge';
  cursor: Scalars['String'];
  node: Identity;
};

export type IdentityFieldDefinition = {
  __typename?: 'IdentityFieldDefinition';
  fieldDefinitionFields: FieldDefinitionFields;
  id: Scalars['ID'];
  identityType: Scalars['ID'];
};

export type IdentityFieldDefinitionInput = {
  fieldDefinitionFields: FieldDefinitionFieldsInput;
  identityType: Scalars['ID'];
};

export type IdentityFilter = {
  OR?: InputMaybe<Array<IdentityFilter>>;
  employeeId_contains?: InputMaybe<Scalars['String']>;
  firstName_contains?: InputMaybe<Scalars['String']>;
  initials_contains?: InputMaybe<Scalars['String']>;
  lastName_contains?: InputMaybe<Scalars['String']>;
  primaryEmail_contains?: InputMaybe<Scalars['String']>;
};

export type IdentityInput = {
  accountingId?: InputMaybe<Scalars['String']>;
  companyMobilePhone?: InputMaybe<Scalars['String']>;
  companyPhone?: InputMaybe<Scalars['String']>;
  costCenter?: InputMaybe<Scalars['String']>;
  customFields?: InputMaybe<CustomFieldsInput>;
  employeeId?: InputMaybe<Scalars['String']>;
  externalEntityIds?: InputMaybe<ExternalEntityIdsInput>;
  firstName?: InputMaybe<Scalars['String']>;
  identityType: Scalars['String'];
  identityTypeField?: InputMaybe<Scalars['String']>;
  initials?: InputMaybe<Scalars['String']>;
  lastName: Scalars['String'];
  manager?: InputMaybe<Scalars['String']>;
  primaryEmail?: InputMaybe<Scalars['String']>;
  privateEmail?: InputMaybe<Scalars['String']>;
  privateMobilePhone?: InputMaybe<Scalars['String']>;
  salutation?: InputMaybe<Scalars['String']>;
  secondaryManager?: InputMaybe<Scalars['String']>;
  substitute?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['String']>;
  updatedBy?: InputMaybe<Scalars['String']>;
};

export type IdentityRoleMembership = {
  __typename?: 'IdentityRoleMembership';
  allAccounts?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  identity: Identity;
  meta?: Maybe<Metadata>;
  role: Role;
  template: Scalars['Boolean'];
  validity?: Maybe<Validity>;
};

export type IdentityRoleMembershipConnection = {
  __typename?: 'IdentityRoleMembershipConnection';
  edges: Array<Maybe<IdentityRoleMembershipEdge>>;
  pageInfo: PageInfo;
};

export type IdentityRoleMembershipEdge = {
  __typename?: 'IdentityRoleMembershipEdge';
  cursor: Scalars['String'];
  node: IdentityRoleMembership;
};

export type IdentityRoleMembershipInput = {
  identityId?: InputMaybe<Scalars['ID']>;
  roleId?: InputMaybe<Scalars['ID']>;
  validity?: InputMaybe<ValidityInput>;
};

export type IdentityType = {
  __typename?: 'IdentityType';
  Identities?: Maybe<Array<Maybe<Identity>>>;
  apiName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  meta?: Maybe<Metadata>;
  name: Scalars['String'];
};

/**  New connection type for IdentityType */
export type IdentityTypeConnection = {
  __typename?: 'IdentityTypeConnection';
  edges: Array<Maybe<IdentityTypeEdge>>;
  pageInfo: PageInfo;
};

/**  New edge type for IdentityType */
export type IdentityTypeEdge = {
  __typename?: 'IdentityTypeEdge';
  cursor: Scalars['String'];
  node: IdentityType;
};

export type IdentityTypeInput = {
  apiName?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['String']>;
  updatedBy?: InputMaybe<Scalars['String']>;
};

export type Metadata = {
  __typename?: 'Metadata';
  createdAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  updatedBy?: Maybe<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  createAccount: Account;
  createAccountFieldDefinition: AccountFieldDefinition;
  createAccountType: AccountType;
  createAddress: Address;
  createBusinessRule: BusinessRule;
  createEntitlement: Entitlement;
  createIdentity: Identity;
  createIdentityFieldDefinition: IdentityFieldDefinition;
  createIdentityRoleMembership: IdentityRoleMembership;
  createIdentityType: IdentityType;
  createOeRelation: OeRelation;
  createOetRelation: OetRelation;
  createOrganizationalEntity: OrganizationalEntity;
  createOrganizationalEntityType: OrganizationalEntityType;
  createOwnership?: Maybe<Ownership>;
  createResourceSet: ResourceSet;
  createRole: Role;
  createSystem: System;
  deleteAccount: Account;
  deleteAccountType: AccountType;
  deleteAddress: Address;
  deleteBusinessRule: BusinessRule;
  /**
   * 
   * type as a parameter has no purpose here, but frontend graphql endpoint needs it for cache handling
   */
  deleteEntitlement: Entitlement;
  deleteIdentity: Identity;
  deleteIdentityRoleMembership: IdentityRoleMembership;
  deleteIdentityType: IdentityType;
  deleteOeRelation: OeRelation;
  deleteOetRelation: OetRelation;
  deleteOrganizationalEntity: OrganizationalEntity;
  deleteOrganizationalEntityType: OrganizationalEntityType;
  deleteOwnership?: Maybe<Ownership>;
  deleteResourceSet: ResourceSet;
  deleteRole: Role;
  deleteSystem: System;
  updateAccount: Account;
  updateAccountFieldDefinition: AccountFieldDefinition;
  updateAccountFieldDefinitions: Array<Maybe<AccountFieldDefinition>>;
  updateAccountRoleMembership: AccountRoleMembership;
  updateAccountType?: Maybe<AccountType>;
  updateAddress: Address;
  updateBusinessRule: BusinessRule;
  updateEntitlement: Entitlement;
  updateIdentity: Identity;
  updateIdentityFieldDefinition: IdentityFieldDefinition;
  updateIdentityFieldDefinitions: Array<Maybe<IdentityFieldDefinition>>;
  updateIdentityRoleMembership: IdentityRoleMembership;
  updateIdentityType?: Maybe<IdentityType>;
  updateOeRelation: OeRelation;
  updateOetRelation: OetRelation;
  updateOrganizationalEntity?: Maybe<OrganizationalEntity>;
  updateOrganizationalEntityType?: Maybe<OrganizationalEntityType>;
  updateOwnership?: Maybe<Ownership>;
  updateResourceSet: ResourceSet;
  updateRole: Role;
  updateSystem: System;
};


export type MutationCreateAccountArgs = {
  input?: InputMaybe<AccountInput>;
};


export type MutationCreateAccountFieldDefinitionArgs = {
  input: AccountFieldDefinitionInput;
};


export type MutationCreateAccountTypeArgs = {
  input?: InputMaybe<AccountTypeInput>;
};


export type MutationCreateAddressArgs = {
  input?: InputMaybe<AddressInput>;
};


export type MutationCreateBusinessRuleArgs = {
  input?: InputMaybe<BusinessRuleInput>;
};


export type MutationCreateEntitlementArgs = {
  entitledId: Scalars['ID'];
  input?: InputMaybe<EntitlementInput>;
  targetId: Scalars['ID'];
  type: Scalars['String'];
};


export type MutationCreateIdentityArgs = {
  input?: InputMaybe<IdentityInput>;
};


export type MutationCreateIdentityFieldDefinitionArgs = {
  input: IdentityFieldDefinitionInput;
};


export type MutationCreateIdentityRoleMembershipArgs = {
  identityId: Scalars['ID'];
  input?: InputMaybe<IdentityRoleMembershipInput>;
  roleId: Scalars['ID'];
};


export type MutationCreateIdentityTypeArgs = {
  input?: InputMaybe<IdentityTypeInput>;
};


export type MutationCreateOeRelationArgs = {
  assignedEntityId: Scalars['ID'];
  oetRelationTypeId: Scalars['ID'];
  ownerEntityId: Scalars['ID'];
};


export type MutationCreateOetRelationArgs = {
  assignedTypeId: Scalars['ID'];
  input?: InputMaybe<OetRelationInput>;
  oetRelationTypeId: Scalars['ID'];
  ownerTypeId: Scalars['ID'];
};


export type MutationCreateOrganizationalEntityArgs = {
  input?: InputMaybe<OrganizationalEntityInput>;
};


export type MutationCreateOrganizationalEntityTypeArgs = {
  input?: InputMaybe<OrganizationalEntityTypeInput>;
};


export type MutationCreateOwnershipArgs = {
  input?: InputMaybe<OwnershipInput>;
  ownerId: Scalars['ID'];
  targetId: Scalars['ID'];
  type: Scalars['String'];
};


export type MutationCreateResourceSetArgs = {
  input?: InputMaybe<ResourceSetInput>;
};


export type MutationCreateRoleArgs = {
  input?: InputMaybe<RoleInput>;
};


export type MutationCreateSystemArgs = {
  input?: InputMaybe<SystemInput>;
};


export type MutationDeleteAccountArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteAccountTypeArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteAddressArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteBusinessRuleArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteEntitlementArgs = {
  id: Scalars['ID'];
  type?: InputMaybe<Scalars['String']>;
};


export type MutationDeleteIdentityArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteIdentityRoleMembershipArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteIdentityTypeArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteOeRelationArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteOetRelationArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteOrganizationalEntityArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteOrganizationalEntityTypeArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteOwnershipArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteResourceSetArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteRoleArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteSystemArgs = {
  id: Scalars['ID'];
};


export type MutationUpdateAccountArgs = {
  id: Scalars['ID'];
  input: AccountInput;
};


export type MutationUpdateAccountFieldDefinitionArgs = {
  id: Scalars['ID'];
  input: AccountFieldDefinitionInput;
};


export type MutationUpdateAccountFieldDefinitionsArgs = {
  inputs: Array<UpdateAccountFieldDefinitionInput>;
};


export type MutationUpdateAccountRoleMembershipArgs = {
  id: Scalars['ID'];
  input?: InputMaybe<AccountRoleMembershipInput>;
};


export type MutationUpdateAccountTypeArgs = {
  id: Scalars['ID'];
  input: AccountTypeInput;
};


export type MutationUpdateAddressArgs = {
  id: Scalars['ID'];
  input?: InputMaybe<AddressInput>;
};


export type MutationUpdateBusinessRuleArgs = {
  id: Scalars['ID'];
  input?: InputMaybe<BusinessRuleInput>;
};


export type MutationUpdateEntitlementArgs = {
  id: Scalars['ID'];
  input?: InputMaybe<EntitlementInput>;
};


export type MutationUpdateIdentityArgs = {
  id: Scalars['ID'];
  input?: InputMaybe<IdentityInput>;
};


export type MutationUpdateIdentityFieldDefinitionArgs = {
  id: Scalars['ID'];
  input: IdentityFieldDefinitionInput;
};


export type MutationUpdateIdentityFieldDefinitionsArgs = {
  inputs: Array<UpdateIdentityFieldDefinitionInput>;
};


export type MutationUpdateIdentityRoleMembershipArgs = {
  id: Scalars['ID'];
  input?: InputMaybe<IdentityRoleMembershipInput>;
};


export type MutationUpdateIdentityTypeArgs = {
  id: Scalars['ID'];
  input: IdentityTypeInput;
};


export type MutationUpdateOeRelationArgs = {
  id: Scalars['ID'];
  input?: InputMaybe<OeRelationInput>;
};


export type MutationUpdateOetRelationArgs = {
  id: Scalars['ID'];
  input?: InputMaybe<OetRelationInput>;
};


export type MutationUpdateOrganizationalEntityArgs = {
  id: Scalars['ID'];
  input: OrganizationalEntityInput;
};


export type MutationUpdateOrganizationalEntityTypeArgs = {
  id: Scalars['ID'];
  input: OrganizationalEntityTypeInput;
};


export type MutationUpdateOwnershipArgs = {
  id: Scalars['ID'];
  input?: InputMaybe<OwnershipInput>;
};


export type MutationUpdateResourceSetArgs = {
  id: Scalars['ID'];
  input: ResourceSetInput;
};


export type MutationUpdateRoleArgs = {
  id: Scalars['ID'];
  input?: InputMaybe<RoleInput>;
};


export type MutationUpdateSystemArgs = {
  id: Scalars['ID'];
  input: SystemInput;
};

export type OeRelation = {
  __typename?: 'OeRelation';
  assignedEntity: OrganizationalEntity;
  id: Scalars['ID'];
  meta?: Maybe<Metadata>;
  oetRelation: OetRelation;
  ownerEntity: OrganizationalEntity;
};

export type OeRelationConnection = {
  __typename?: 'OeRelationConnection';
  edges?: Maybe<Array<Maybe<OeRelationEdge>>>;
  pageInfo: PageInfo;
};

export type OeRelationEdge = {
  __typename?: 'OeRelationEdge';
  cursor: Scalars['String'];
  node?: Maybe<OeRelation>;
};

export type OeRelationInput = {
  entityId: Scalars['ID'];
};

export type OetRelation = {
  __typename?: 'OetRelation';
  assignedType: OrganizationalEntityType;
  id: Scalars['ID'];
  isRequired?: Maybe<Scalars['Boolean']>;
  meta?: Maybe<Metadata>;
  oetRelationType: OetRelationType;
  ownerType: OrganizationalEntityType;
};

export type OetRelationConnection = {
  __typename?: 'OetRelationConnection';
  edges?: Maybe<Array<Maybe<OetRelationEdge>>>;
  pageInfo: PageInfo;
};

export type OetRelationEdge = {
  __typename?: 'OetRelationEdge';
  cursor: Scalars['String'];
  node?: Maybe<OetRelation>;
};

export type OetRelationInput = {
  isRequired?: InputMaybe<Scalars['Boolean']>;
};

export type OetRelationType = {
  __typename?: 'OetRelationType';
  id?: Maybe<Scalars['ID']>;
  type?: Maybe<Scalars['String']>;
};

/**  OrganizationalEntity */
export type OrganizationalEntity = {
  __typename?: 'OrganizationalEntity';
  address?: Maybe<Address>;
  alternativeName?: Maybe<Scalars['String']>;
  customID?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  meta?: Maybe<Metadata>;
  name: Scalars['String'];
  organizationalEntityType?: Maybe<OrganizationalEntityType>;
};

export type OrganizationalEntityConnection = {
  __typename?: 'OrganizationalEntityConnection';
  edges: Array<Maybe<OrganizationalEntityEdge>>;
  pageInfo: PageInfo;
};

export type OrganizationalEntityEdge = {
  __typename?: 'OrganizationalEntityEdge';
  cursor: Scalars['String'];
  node: OrganizationalEntity;
};

export type OrganizationalEntityInput = {
  address?: InputMaybe<Scalars['ID']>;
  alternativeName?: InputMaybe<Scalars['String']>;
  customID?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  organizationalEntityType?: InputMaybe<Scalars['ID']>;
  updatedAt?: InputMaybe<Scalars['String']>;
  updatedBy?: InputMaybe<Scalars['String']>;
};

export type OrganizationalEntityType = {
  __typename?: 'OrganizationalEntityType';
  apiName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  meta?: Maybe<Metadata>;
  name: Scalars['String'];
  organizationalEntities?: Maybe<OrganizationalEntityConnection>;
};


export type OrganizationalEntityTypeOrganizationalEntitiesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<FilterExpression>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Scalars['String']>;
};

export type OrganizationalEntityTypeConnection = {
  __typename?: 'OrganizationalEntityTypeConnection';
  edges: Array<Maybe<OrganizationalEntityTypeEdge>>;
  pageInfo: PageInfo;
};

export type OrganizationalEntityTypeEdge = {
  __typename?: 'OrganizationalEntityTypeEdge';
  cursor: Scalars['String'];
  node: OrganizationalEntityType;
};

export type OrganizationalEntityTypeInput = {
  apiName?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['String']>;
  updatedBy?: InputMaybe<Scalars['String']>;
};

export type Ownership = {
  __typename?: 'Ownership';
  id: Scalars['ID'];
  meta?: Maybe<Metadata>;
  ownerId: Scalars['ID'];
  targetId: Scalars['ID'];
  type: Scalars['String'];
  validity?: Maybe<Validity>;
};

export type OwnershipConnection = {
  __typename?: 'OwnershipConnection';
  edges: Array<Maybe<OwnershipEdge>>;
  pageInfo: PageInfo;
};

export type OwnershipEdge = {
  __typename?: 'OwnershipEdge';
  cursor: Scalars['String'];
  node: Ownership;
};

export type OwnershipInput = {
  validity?: InputMaybe<ValidityInput>;
};

export type PageInfo = {
  __typename?: 'PageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
};

export type Query = {
  __typename?: 'Query';
  account?: Maybe<Account>;
  accountFieldDefinition?: Maybe<AccountFieldDefinition>;
  accountFieldDefinitions?: Maybe<Array<Maybe<AccountFieldDefinition>>>;
  accountRoleMembership?: Maybe<AccountRoleMembership>;
  accountRoleMemberships?: Maybe<AccountRoleMembershipConnection>;
  accountType?: Maybe<AccountType>;
  accountTypes?: Maybe<AccountTypeConnection>;
  accounts?: Maybe<AccountConnection>;
  address?: Maybe<Address>;
  addresses?: Maybe<AddressConnection>;
  businessRule?: Maybe<BusinessRule>;
  businessRuleStatusFilterEntries: Array<Maybe<FilterEntry>>;
  businessRules?: Maybe<BusinessRuleConnection>;
  calculateRulesForSivisEntities?: Maybe<Array<Maybe<RuleExecutionResult>>>;
  entitlement?: Maybe<Entitlement>;
  entitlements?: Maybe<EntitlementConnection>;
  entitlementsResourceTypes: Array<Maybe<FilterEntry>>;
  entitlementsSystems: Array<Maybe<FilterEntry>>;
  entitlementsValidity: Array<Maybe<FilterEntry>>;
  getDraftForBusinessRule?: Maybe<BusinessRule>;
  identities?: Maybe<IdentityConnection>;
  identitiesById?: Maybe<Array<Maybe<Identity>>>;
  identitiesByString?: Maybe<Array<Maybe<Identity>>>;
  identitiesCreatableResourceEntitlements?: Maybe<IdentityConnection>;
  identity?: Maybe<Identity>;
  identityCreationDates: Array<Maybe<FilterEntry>>;
  identityFieldDefinition?: Maybe<IdentityFieldDefinition>;
  identityFieldDefinitions?: Maybe<Array<Maybe<IdentityFieldDefinition>>>;
  identityRoleMembership?: Maybe<IdentityRoleMembership>;
  identityRoleMemberships?: Maybe<IdentityRoleMembershipConnection>;
  identityType?: Maybe<IdentityType>;
  identityTypes?: Maybe<IdentityTypeConnection>;
  identityUpdateDates: Array<Maybe<FilterEntry>>;
  oeRelation?: Maybe<OeRelation>;
  oeRelations?: Maybe<OeRelationConnection>;
  oetRelation?: Maybe<OetRelation>;
  oetRelationTypes?: Maybe<Array<Maybe<OetRelationType>>>;
  oetRelations?: Maybe<OetRelationConnection>;
  organizationalEntities?: Maybe<OrganizationalEntityConnection>;
  organizationalEntity?: Maybe<OrganizationalEntity>;
  organizationalEntityType?: Maybe<OrganizationalEntityType>;
  organizationalEntityTypes?: Maybe<OrganizationalEntityTypeConnection>;
  ownership?: Maybe<Ownership>;
  ownershipTypes: Array<Maybe<FilterEntry>>;
  ownerships?: Maybe<OwnershipConnection>;
  ownershipsValidity: Array<Maybe<FilterEntry>>;
  resource: Resource;
  resourceSet?: Maybe<ResourceSet>;
  resourceSets?: Maybe<ResourceSetConnection>;
  resourceType?: Maybe<ResourceType>;
  resourceTypeFilterEntries: Array<Maybe<FilterEntry>>;
  resourceTypes?: Maybe<ResourceTypeConnection>;
  resources?: Maybe<ResourceConnection>;
  role?: Maybe<Role>;
  roleCreationDates: Array<Maybe<FilterEntry>>;
  roleUpdateDates: Array<Maybe<FilterEntry>>;
  roles?: Maybe<RoleConnection>;
  system?: Maybe<System>;
  systemType?: Maybe<SystemType>;
  systemTypeFilterEntries: Array<Maybe<FilterEntry>>;
  systemTypes?: Maybe<SystemTypeConnection>;
  systems?: Maybe<SystemConnection>;
};


export type QueryAccountArgs = {
  id: Scalars['ID'];
};


export type QueryAccountFieldDefinitionArgs = {
  id: Scalars['ID'];
};


export type QueryAccountRoleMembershipArgs = {
  id: Scalars['ID'];
};


export type QueryAccountRoleMembershipsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<FilterExpression>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Scalars['String']>;
};


export type QueryAccountTypeArgs = {
  id: Scalars['ID'];
};


export type QueryAccountTypesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<FilterExpression>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Scalars['String']>;
};


export type QueryAccountsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<FilterExpression>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Scalars['String']>;
};


export type QueryAddressArgs = {
  id: Scalars['ID'];
};


export type QueryAddressesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<FilterExpression>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Scalars['String']>;
};


export type QueryBusinessRuleArgs = {
  id: Scalars['ID'];
};


export type QueryBusinessRulesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<FilterExpression>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Scalars['String']>;
};


export type QueryCalculateRulesForSivisEntitiesArgs = {
  entities?: InputMaybe<Array<InputMaybe<SapEntityInput>>>;
};


export type QueryEntitlementArgs = {
  id: Scalars['ID'];
};


export type QueryEntitlementsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<FilterExpression>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Scalars['String']>;
};


export type QueryEntitlementsResourceTypesArgs = {
  entitledId: Scalars['ID'];
  type: Scalars['String'];
};


export type QueryEntitlementsSystemsArgs = {
  entitledId: Scalars['ID'];
  type: Scalars['String'];
};


export type QueryEntitlementsValidityArgs = {
  entitledId: Scalars['ID'];
  type: Scalars['String'];
};


export type QueryGetDraftForBusinessRuleArgs = {
  id: Scalars['ID'];
};


export type QueryIdentitiesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<FilterExpression>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Scalars['String']>;
};


export type QueryIdentitiesByIdArgs = {
  input: Array<InputMaybe<Scalars['ID']>>;
};


export type QueryIdentitiesByStringArgs = {
  filter?: InputMaybe<IdentityFilter>;
};


export type QueryIdentitiesCreatableResourceEntitlementsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<FilterExpression>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  resourceId: Scalars['ID'];
  sort?: InputMaybe<Scalars['String']>;
};


export type QueryIdentityArgs = {
  id: Scalars['ID'];
};


export type QueryIdentityFieldDefinitionArgs = {
  id: Scalars['ID'];
};


export type QueryIdentityRoleMembershipArgs = {
  id: Scalars['ID'];
};


export type QueryIdentityRoleMembershipsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<FilterExpression>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Scalars['String']>;
};


export type QueryIdentityTypeArgs = {
  id: Scalars['ID'];
};


export type QueryIdentityTypesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<FilterExpression>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Scalars['String']>;
};


export type QueryOeRelationArgs = {
  id: Scalars['ID'];
};


export type QueryOeRelationsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<FilterExpression>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Scalars['String']>;
};


export type QueryOetRelationArgs = {
  id: Scalars['ID'];
};


export type QueryOetRelationsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<FilterExpression>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Scalars['String']>;
};


export type QueryOrganizationalEntitiesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<FilterExpression>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Scalars['String']>;
};


export type QueryOrganizationalEntityArgs = {
  id: Scalars['ID'];
};


export type QueryOrganizationalEntityTypeArgs = {
  id: Scalars['ID'];
};


export type QueryOrganizationalEntityTypesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<FilterExpression>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Scalars['String']>;
};


export type QueryOwnershipArgs = {
  id: Scalars['ID'];
};


export type QueryOwnershipTypesArgs = {
  ownerId: Scalars['ID'];
  targetId: Scalars['ID'];
};


export type QueryOwnershipsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<FilterExpression>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Scalars['String']>;
};


export type QueryOwnershipsValidityArgs = {
  ownerId: Scalars['ID'];
  targetId: Scalars['ID'];
};


export type QueryResourceArgs = {
  id: Scalars['ID'];
};


export type QueryResourceSetArgs = {
  id: Scalars['ID'];
};


export type QueryResourceSetsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<FilterExpression>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Scalars['String']>;
};


export type QueryResourceTypeArgs = {
  id: Scalars['ID'];
};


export type QueryResourceTypesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<FilterExpression>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Scalars['String']>;
};


export type QueryResourcesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<FilterExpression>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Scalars['String']>;
};


export type QueryRoleArgs = {
  id: Scalars['ID'];
};


export type QueryRolesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<FilterExpression>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Scalars['String']>;
};


export type QuerySystemArgs = {
  id: Scalars['ID'];
};


export type QuerySystemTypeArgs = {
  id: Scalars['ID'];
};


export type QuerySystemTypesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<FilterExpression>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Scalars['String']>;
};


export type QuerySystemsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<FilterExpression>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Scalars['String']>;
};

export type Resource = {
  __typename?: 'Resource';
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  meta?: Maybe<Metadata>;
  name: Scalars['String'];
  resourceId?: Maybe<Scalars['String']>;
  resourceSets?: Maybe<ResourceSetConnection>;
  resourceType?: Maybe<ResourceType>;
  system?: Maybe<System>;
};


export type ResourceResourceSetsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<FilterExpression>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Scalars['String']>;
};

export type ResourceConnection = {
  __typename?: 'ResourceConnection';
  edges: Array<Maybe<ResourceEdge>>;
  pageInfo: PageInfo;
};

export type ResourceEdge = {
  __typename?: 'ResourceEdge';
  cursor: Scalars['String'];
  node: Resource;
};

export type ResourceInput = {
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  resourceId?: InputMaybe<Scalars['String']>;
  resourceSet?: InputMaybe<Scalars['ID']>;
  resourceType?: InputMaybe<Scalars['ID']>;
  system?: InputMaybe<Scalars['ID']>;
  updatedAt?: InputMaybe<Scalars['String']>;
  updatedBy?: InputMaybe<Scalars['String']>;
};

export type ResourceSet = {
  __typename?: 'ResourceSet';
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  meta?: Maybe<Metadata>;
  name: Scalars['String'];
  resources?: Maybe<ResourceConnection>;
};


export type ResourceSetResourcesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<FilterExpression>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Scalars['String']>;
};

export type ResourceSetConnection = {
  __typename?: 'ResourceSetConnection';
  edges: Array<Maybe<ResourceSetEdge>>;
  pageInfo: PageInfo;
};

export type ResourceSetEdge = {
  __typename?: 'ResourceSetEdge';
  cursor: Scalars['String'];
  node: ResourceSet;
};

export type ResourceSetInput = {
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  resources?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  updatedAt?: InputMaybe<Scalars['String']>;
  updatedBy?: InputMaybe<Scalars['String']>;
};

export type ResourceType = {
  __typename?: 'ResourceType';
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  meta?: Maybe<Metadata>;
  name: Scalars['String'];
  resources?: Maybe<ResourceConnection>;
  systemType?: Maybe<SystemType>;
};


export type ResourceTypeResourcesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<FilterExpression>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Scalars['String']>;
};

export type ResourceTypeConnection = {
  __typename?: 'ResourceTypeConnection';
  edges: Array<Maybe<ResourceTypeEdge>>;
  pageInfo: PageInfo;
};

export type ResourceTypeEdge = {
  __typename?: 'ResourceTypeEdge';
  cursor: Scalars['String'];
  node: ResourceType;
};

export type ResourceTypeInput = {
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  systemType?: InputMaybe<Scalars['ID']>;
  updatedAt?: InputMaybe<Scalars['String']>;
  updatedBy?: InputMaybe<Scalars['String']>;
};

export type Role = {
  __typename?: 'Role';
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  identities?: Maybe<IdentityConnection>;
  identitiesCount?: Maybe<Scalars['Int']>;
  identityMemberships?: Maybe<IdentityRoleMembershipConnection>;
  meta?: Maybe<Metadata>;
  name: Scalars['String'];
};


export type RoleIdentitiesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<FilterExpression>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Scalars['String']>;
};


export type RoleIdentityMembershipsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<FilterExpression>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Scalars['String']>;
};

export type RoleConnection = {
  __typename?: 'RoleConnection';
  edges: Array<Maybe<RoleEdge>>;
  pageInfo: PageInfo;
};

export type RoleEdge = {
  __typename?: 'RoleEdge';
  cursor: Scalars['String'];
  node: Role;
};

export type RoleInput = {
  description?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['String']>;
  updatedBy?: InputMaybe<Scalars['String']>;
};

export enum RuleAction {
  AssignSivisJob = 'ASSIGN_SIVIS_JOB'
}

export type RuleExecutionResult = {
  __typename?: 'RuleExecutionResult';
  businessRuleKey?: Maybe<BusinessRuleKey>;
  entityIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export enum RuleLogicalOperator {
  And = 'AND',
  Or = 'OR'
}

export enum RuleOperation {
  Equals = 'EQUALS'
}

export enum RuleStatus {
  Active = 'ACTIVE',
  Calculating = 'CALCULATING',
  Inactive = 'INACTIVE'
}

/**  TODO: clarify the structure of the input (SapEntityDTO? this is only a placeholder) */
export type SapEntityInput = {
  id?: InputMaybe<Scalars['String']>;
  values?: InputMaybe<Array<InputMaybe<SapFieldValueInput>>>;
};

export type SapFieldValueInput = {
  field: Scalars['String'];
  value: Scalars['String'];
};

export type System = {
  __typename?: 'System';
  id: Scalars['ID'];
  meta?: Maybe<Metadata>;
  resources?: Maybe<ResourceConnection>;
  systemDescription?: Maybe<Scalars['String']>;
  systemName: Scalars['String'];
  systemType?: Maybe<SystemType>;
};


export type SystemResourcesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<FilterExpression>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Scalars['String']>;
};

export type SystemConnection = {
  __typename?: 'SystemConnection';
  edges: Array<Maybe<SystemEdge>>;
  pageInfo: PageInfo;
};

export type SystemEdge = {
  __typename?: 'SystemEdge';
  cursor: Scalars['String'];
  node: System;
};

export type SystemInput = {
  systemDescription?: InputMaybe<Scalars['String']>;
  systemName?: InputMaybe<Scalars['String']>;
  systemType?: InputMaybe<Scalars['ID']>;
  updatedAt?: InputMaybe<Scalars['String']>;
  updatedBy?: InputMaybe<Scalars['String']>;
};

export type SystemType = {
  __typename?: 'SystemType';
  id: Scalars['ID'];
  meta?: Maybe<Metadata>;
  resourceTypes?: Maybe<ResourceTypeConnection>;
  systemTypeDescription?: Maybe<Scalars['String']>;
  systemTypeName: Scalars['String'];
  systems?: Maybe<SystemConnection>;
};


export type SystemTypeResourceTypesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<FilterExpression>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Scalars['String']>;
};


export type SystemTypeSystemsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<FilterExpression>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Scalars['String']>;
};

export type SystemTypeConnection = {
  __typename?: 'SystemTypeConnection';
  edges: Array<Maybe<SystemTypeEdge>>;
  pageInfo: PageInfo;
};

export type SystemTypeEdge = {
  __typename?: 'SystemTypeEdge';
  cursor: Scalars['String'];
  node: SystemType;
};

export type SystemTypeInput = {
  systemTypeDescription?: InputMaybe<Scalars['String']>;
  systemTypeName?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['String']>;
  updatedBy?: InputMaybe<Scalars['String']>;
};

export type UpdateAccountFieldDefinitionInput = {
  fieldDefinitionFields: FieldDefinitionFieldsInput;
  id: Scalars['ID'];
  system: Scalars['ID'];
};

export type UpdateIdentityFieldDefinitionInput = {
  fieldDefinitionFields: FieldDefinitionFieldsInput;
  id: Scalars['ID'];
  identityType: Scalars['ID'];
};

export type Validity = {
  __typename?: 'Validity';
  validFrom?: Maybe<Scalars['String']>;
  validTo?: Maybe<Scalars['String']>;
};

export type ValidityInput = {
  validFrom?: InputMaybe<Scalars['String']>;
  validTo?: InputMaybe<Scalars['String']>;
};

export enum ValueTypes {
  Array = 'ARRAY',
  Boolean = 'BOOLEAN',
  Date = 'DATE',
  Datetime = 'DATETIME',
  Decimal = 'DECIMAL',
  Integer = 'INTEGER',
  Object = 'OBJECT',
  String = 'STRING',
  Time = 'TIME',
  Timestamp = 'TIMESTAMP'
}



export type ResolverTypeWrapper<T> = Promise<T> | T;


export type ResolverWithResolve<TResult, TParent, TContext, TArgs> = {
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};
export type Resolver<TResult, TParent = {}, TContext = {}, TArgs = {}> = ResolverFn<TResult, TParent, TContext, TArgs> | ResolverWithResolve<TResult, TParent, TContext, TArgs>;

export type ResolverFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => Promise<TResult> | TResult;

export type SubscriptionSubscribeFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => AsyncIterable<TResult> | Promise<AsyncIterable<TResult>>;

export type SubscriptionResolveFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

export interface SubscriptionSubscriberObject<TResult, TKey extends string, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<{ [key in TKey]: TResult }, TParent, TContext, TArgs>;
  resolve?: SubscriptionResolveFn<TResult, { [key in TKey]: TResult }, TContext, TArgs>;
}

export interface SubscriptionResolverObject<TResult, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<any, TParent, TContext, TArgs>;
  resolve: SubscriptionResolveFn<TResult, any, TContext, TArgs>;
}

export type SubscriptionObject<TResult, TKey extends string, TParent, TContext, TArgs> =
  | SubscriptionSubscriberObject<TResult, TKey, TParent, TContext, TArgs>
  | SubscriptionResolverObject<TResult, TParent, TContext, TArgs>;

export type SubscriptionResolver<TResult, TKey extends string, TParent = {}, TContext = {}, TArgs = {}> =
  | ((...args: any[]) => SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>)
  | SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>;

export type TypeResolveFn<TTypes, TParent = {}, TContext = {}> = (
  parent: TParent,
  context: TContext,
  info: GraphQLResolveInfo
) => Maybe<TTypes> | Promise<Maybe<TTypes>>;

export type IsTypeOfResolverFn<T = {}, TContext = {}> = (obj: T, context: TContext, info: GraphQLResolveInfo) => boolean | Promise<boolean>;

export type NextResolverFn<T> = () => Promise<T>;

export type DirectiveResolverFn<TResult = {}, TParent = {}, TContext = {}, TArgs = {}> = (
  next: NextResolverFn<TResult>,
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;



/** Mapping between all available schema types and the resolvers types */
export type ResolversTypes = {
  Account: ResolverTypeWrapper<Account>;
  AccountConnection: ResolverTypeWrapper<AccountConnection>;
  AccountEdge: ResolverTypeWrapper<AccountEdge>;
  AccountFieldDefinition: ResolverTypeWrapper<AccountFieldDefinition>;
  AccountFieldDefinitionInput: AccountFieldDefinitionInput;
  AccountInput: AccountInput;
  AccountRoleMembership: ResolverTypeWrapper<AccountRoleMembership>;
  AccountRoleMembershipConnection: ResolverTypeWrapper<AccountRoleMembershipConnection>;
  AccountRoleMembershipEdge: ResolverTypeWrapper<AccountRoleMembershipEdge>;
  AccountRoleMembershipInput: AccountRoleMembershipInput;
  AccountType: ResolverTypeWrapper<AccountType>;
  AccountTypeConnection: ResolverTypeWrapper<AccountTypeConnection>;
  AccountTypeEdge: ResolverTypeWrapper<AccountTypeEdge>;
  AccountTypeInput: AccountTypeInput;
  Address: ResolverTypeWrapper<Address>;
  AddressConnection: ResolverTypeWrapper<AddressConnection>;
  AddressEdge: ResolverTypeWrapper<AddressEdge>;
  AddressInput: AddressInput;
  Boolean: ResolverTypeWrapper<Scalars['Boolean']>;
  BusinessRule: ResolverTypeWrapper<BusinessRule>;
  BusinessRuleConnection: ResolverTypeWrapper<BusinessRuleConnection>;
  BusinessRuleEdge: ResolverTypeWrapper<BusinessRuleEdge>;
  BusinessRuleInput: BusinessRuleInput;
  BusinessRuleKey: ResolverTypeWrapper<BusinessRuleKey>;
  CriteriaGroup: ResolverTypeWrapper<CriteriaGroup>;
  CriteriaGroupInput: CriteriaGroupInput;
  Criterion: ResolverTypeWrapper<Criterion>;
  CriterionInput: CriterionInput;
  CustomFields: ResolverTypeWrapper<CustomFields>;
  CustomFieldsInput: CustomFieldsInput;
  Entitlement: ResolverTypeWrapper<Entitlement>;
  EntitlementConnection: ResolverTypeWrapper<EntitlementConnection>;
  EntitlementEdge: ResolverTypeWrapper<EntitlementEdge>;
  EntitlementInput: EntitlementInput;
  ExternalEntityIds: ResolverTypeWrapper<ExternalEntityIds>;
  ExternalEntityIdsInput: ExternalEntityIdsInput;
  FieldDefinitionFields: ResolverTypeWrapper<FieldDefinitionFields>;
  FieldDefinitionFieldsInput: FieldDefinitionFieldsInput;
  FilterEntry: ResolverTypeWrapper<FilterEntry>;
  FilterExpression: FilterExpression;
  FilterExpressionOutput: ResolverTypeWrapper<FilterExpressionOutput>;
  Float: ResolverTypeWrapper<Scalars['Float']>;
  ID: ResolverTypeWrapper<Scalars['ID']>;
  Identity: ResolverTypeWrapper<Identity>;
  IdentityConnection: ResolverTypeWrapper<IdentityConnection>;
  IdentityEdge: ResolverTypeWrapper<IdentityEdge>;
  IdentityFieldDefinition: ResolverTypeWrapper<IdentityFieldDefinition>;
  IdentityFieldDefinitionInput: IdentityFieldDefinitionInput;
  IdentityFilter: IdentityFilter;
  IdentityInput: IdentityInput;
  IdentityRoleMembership: ResolverTypeWrapper<IdentityRoleMembership>;
  IdentityRoleMembershipConnection: ResolverTypeWrapper<IdentityRoleMembershipConnection>;
  IdentityRoleMembershipEdge: ResolverTypeWrapper<IdentityRoleMembershipEdge>;
  IdentityRoleMembershipInput: IdentityRoleMembershipInput;
  IdentityType: ResolverTypeWrapper<IdentityType>;
  IdentityTypeConnection: ResolverTypeWrapper<IdentityTypeConnection>;
  IdentityTypeEdge: ResolverTypeWrapper<IdentityTypeEdge>;
  IdentityTypeInput: IdentityTypeInput;
  Int: ResolverTypeWrapper<Scalars['Int']>;
  Metadata: ResolverTypeWrapper<Metadata>;
  Mutation: ResolverTypeWrapper<{}>;
  OeRelation: ResolverTypeWrapper<OeRelation>;
  OeRelationConnection: ResolverTypeWrapper<OeRelationConnection>;
  OeRelationEdge: ResolverTypeWrapper<OeRelationEdge>;
  OeRelationInput: OeRelationInput;
  OetRelation: ResolverTypeWrapper<OetRelation>;
  OetRelationConnection: ResolverTypeWrapper<OetRelationConnection>;
  OetRelationEdge: ResolverTypeWrapper<OetRelationEdge>;
  OetRelationInput: OetRelationInput;
  OetRelationType: ResolverTypeWrapper<OetRelationType>;
  OrganizationalEntity: ResolverTypeWrapper<OrganizationalEntity>;
  OrganizationalEntityConnection: ResolverTypeWrapper<OrganizationalEntityConnection>;
  OrganizationalEntityEdge: ResolverTypeWrapper<OrganizationalEntityEdge>;
  OrganizationalEntityInput: OrganizationalEntityInput;
  OrganizationalEntityType: ResolverTypeWrapper<OrganizationalEntityType>;
  OrganizationalEntityTypeConnection: ResolverTypeWrapper<OrganizationalEntityTypeConnection>;
  OrganizationalEntityTypeEdge: ResolverTypeWrapper<OrganizationalEntityTypeEdge>;
  OrganizationalEntityTypeInput: OrganizationalEntityTypeInput;
  Ownership: ResolverTypeWrapper<Ownership>;
  OwnershipConnection: ResolverTypeWrapper<OwnershipConnection>;
  OwnershipEdge: ResolverTypeWrapper<OwnershipEdge>;
  OwnershipInput: OwnershipInput;
  PageInfo: ResolverTypeWrapper<PageInfo>;
  Query: ResolverTypeWrapper<{}>;
  Resource: ResolverTypeWrapper<Resource>;
  ResourceConnection: ResolverTypeWrapper<ResourceConnection>;
  ResourceEdge: ResolverTypeWrapper<ResourceEdge>;
  ResourceInput: ResourceInput;
  ResourceSet: ResolverTypeWrapper<ResourceSet>;
  ResourceSetConnection: ResolverTypeWrapper<ResourceSetConnection>;
  ResourceSetEdge: ResolverTypeWrapper<ResourceSetEdge>;
  ResourceSetInput: ResourceSetInput;
  ResourceType: ResolverTypeWrapper<ResourceType>;
  ResourceTypeConnection: ResolverTypeWrapper<ResourceTypeConnection>;
  ResourceTypeEdge: ResolverTypeWrapper<ResourceTypeEdge>;
  ResourceTypeInput: ResourceTypeInput;
  Role: ResolverTypeWrapper<Role>;
  RoleConnection: ResolverTypeWrapper<RoleConnection>;
  RoleEdge: ResolverTypeWrapper<RoleEdge>;
  RoleInput: RoleInput;
  RuleAction: RuleAction;
  RuleExecutionResult: ResolverTypeWrapper<RuleExecutionResult>;
  RuleLogicalOperator: RuleLogicalOperator;
  RuleOperation: RuleOperation;
  RuleStatus: RuleStatus;
  SapEntityInput: SapEntityInput;
  SapFieldValueInput: SapFieldValueInput;
  String: ResolverTypeWrapper<Scalars['String']>;
  System: ResolverTypeWrapper<System>;
  SystemConnection: ResolverTypeWrapper<SystemConnection>;
  SystemEdge: ResolverTypeWrapper<SystemEdge>;
  SystemInput: SystemInput;
  SystemType: ResolverTypeWrapper<SystemType>;
  SystemTypeConnection: ResolverTypeWrapper<SystemTypeConnection>;
  SystemTypeEdge: ResolverTypeWrapper<SystemTypeEdge>;
  SystemTypeInput: SystemTypeInput;
  UpdateAccountFieldDefinitionInput: UpdateAccountFieldDefinitionInput;
  UpdateIdentityFieldDefinitionInput: UpdateIdentityFieldDefinitionInput;
  Validity: ResolverTypeWrapper<Validity>;
  ValidityInput: ValidityInput;
  ValueTypes: ValueTypes;
};

/** Mapping between all available schema types and the resolvers parents */
export type ResolversParentTypes = {
  Account: Account;
  AccountConnection: AccountConnection;
  AccountEdge: AccountEdge;
  AccountFieldDefinition: AccountFieldDefinition;
  AccountFieldDefinitionInput: AccountFieldDefinitionInput;
  AccountInput: AccountInput;
  AccountRoleMembership: AccountRoleMembership;
  AccountRoleMembershipConnection: AccountRoleMembershipConnection;
  AccountRoleMembershipEdge: AccountRoleMembershipEdge;
  AccountRoleMembershipInput: AccountRoleMembershipInput;
  AccountType: AccountType;
  AccountTypeConnection: AccountTypeConnection;
  AccountTypeEdge: AccountTypeEdge;
  AccountTypeInput: AccountTypeInput;
  Address: Address;
  AddressConnection: AddressConnection;
  AddressEdge: AddressEdge;
  AddressInput: AddressInput;
  Boolean: Scalars['Boolean'];
  BusinessRule: BusinessRule;
  BusinessRuleConnection: BusinessRuleConnection;
  BusinessRuleEdge: BusinessRuleEdge;
  BusinessRuleInput: BusinessRuleInput;
  BusinessRuleKey: BusinessRuleKey;
  CriteriaGroup: CriteriaGroup;
  CriteriaGroupInput: CriteriaGroupInput;
  Criterion: Criterion;
  CriterionInput: CriterionInput;
  CustomFields: CustomFields;
  CustomFieldsInput: CustomFieldsInput;
  Entitlement: Entitlement;
  EntitlementConnection: EntitlementConnection;
  EntitlementEdge: EntitlementEdge;
  EntitlementInput: EntitlementInput;
  ExternalEntityIds: ExternalEntityIds;
  ExternalEntityIdsInput: ExternalEntityIdsInput;
  FieldDefinitionFields: FieldDefinitionFields;
  FieldDefinitionFieldsInput: FieldDefinitionFieldsInput;
  FilterEntry: FilterEntry;
  FilterExpression: FilterExpression;
  FilterExpressionOutput: FilterExpressionOutput;
  Float: Scalars['Float'];
  ID: Scalars['ID'];
  Identity: Identity;
  IdentityConnection: IdentityConnection;
  IdentityEdge: IdentityEdge;
  IdentityFieldDefinition: IdentityFieldDefinition;
  IdentityFieldDefinitionInput: IdentityFieldDefinitionInput;
  IdentityFilter: IdentityFilter;
  IdentityInput: IdentityInput;
  IdentityRoleMembership: IdentityRoleMembership;
  IdentityRoleMembershipConnection: IdentityRoleMembershipConnection;
  IdentityRoleMembershipEdge: IdentityRoleMembershipEdge;
  IdentityRoleMembershipInput: IdentityRoleMembershipInput;
  IdentityType: IdentityType;
  IdentityTypeConnection: IdentityTypeConnection;
  IdentityTypeEdge: IdentityTypeEdge;
  IdentityTypeInput: IdentityTypeInput;
  Int: Scalars['Int'];
  Metadata: Metadata;
  Mutation: {};
  OeRelation: OeRelation;
  OeRelationConnection: OeRelationConnection;
  OeRelationEdge: OeRelationEdge;
  OeRelationInput: OeRelationInput;
  OetRelation: OetRelation;
  OetRelationConnection: OetRelationConnection;
  OetRelationEdge: OetRelationEdge;
  OetRelationInput: OetRelationInput;
  OetRelationType: OetRelationType;
  OrganizationalEntity: OrganizationalEntity;
  OrganizationalEntityConnection: OrganizationalEntityConnection;
  OrganizationalEntityEdge: OrganizationalEntityEdge;
  OrganizationalEntityInput: OrganizationalEntityInput;
  OrganizationalEntityType: OrganizationalEntityType;
  OrganizationalEntityTypeConnection: OrganizationalEntityTypeConnection;
  OrganizationalEntityTypeEdge: OrganizationalEntityTypeEdge;
  OrganizationalEntityTypeInput: OrganizationalEntityTypeInput;
  Ownership: Ownership;
  OwnershipConnection: OwnershipConnection;
  OwnershipEdge: OwnershipEdge;
  OwnershipInput: OwnershipInput;
  PageInfo: PageInfo;
  Query: {};
  Resource: Resource;
  ResourceConnection: ResourceConnection;
  ResourceEdge: ResourceEdge;
  ResourceInput: ResourceInput;
  ResourceSet: ResourceSet;
  ResourceSetConnection: ResourceSetConnection;
  ResourceSetEdge: ResourceSetEdge;
  ResourceSetInput: ResourceSetInput;
  ResourceType: ResourceType;
  ResourceTypeConnection: ResourceTypeConnection;
  ResourceTypeEdge: ResourceTypeEdge;
  ResourceTypeInput: ResourceTypeInput;
  Role: Role;
  RoleConnection: RoleConnection;
  RoleEdge: RoleEdge;
  RoleInput: RoleInput;
  RuleExecutionResult: RuleExecutionResult;
  SapEntityInput: SapEntityInput;
  SapFieldValueInput: SapFieldValueInput;
  String: Scalars['String'];
  System: System;
  SystemConnection: SystemConnection;
  SystemEdge: SystemEdge;
  SystemInput: SystemInput;
  SystemType: SystemType;
  SystemTypeConnection: SystemTypeConnection;
  SystemTypeEdge: SystemTypeEdge;
  SystemTypeInput: SystemTypeInput;
  UpdateAccountFieldDefinitionInput: UpdateAccountFieldDefinitionInput;
  UpdateIdentityFieldDefinitionInput: UpdateIdentityFieldDefinitionInput;
  Validity: Validity;
  ValidityInput: ValidityInput;
};

export type FilterQueryDirectiveArgs = { };

export type FilterQueryDirectiveResolver<Result, Parent, ContextType = any, Args = FilterQueryDirectiveArgs> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export type OneOfDirectiveArgs = { };

export type OneOfDirectiveResolver<Result, Parent, ContextType = any, Args = OneOfDirectiveArgs> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export type AccountResolvers<ContextType = any, ParentType extends ResolversParentTypes['Account'] = ResolversParentTypes['Account']> = {
  accountType?: Resolver<ResolversTypes['AccountType'], ParentType, ContextType>;
  customFields?: Resolver<Maybe<ResolversTypes['CustomFields']>, ParentType, ContextType>;
  externalEntityIds?: Resolver<Maybe<ResolversTypes['ExternalEntityIds']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  identityId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  meta?: Resolver<Maybe<ResolversTypes['Metadata']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  system?: Resolver<Maybe<ResolversTypes['System']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AccountConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['AccountConnection'] = ResolversParentTypes['AccountConnection']> = {
  edges?: Resolver<Array<Maybe<ResolversTypes['AccountEdge']>>, ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AccountEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['AccountEdge'] = ResolversParentTypes['AccountEdge']> = {
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  node?: Resolver<ResolversTypes['Account'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AccountFieldDefinitionResolvers<ContextType = any, ParentType extends ResolversParentTypes['AccountFieldDefinition'] = ResolversParentTypes['AccountFieldDefinition']> = {
  fieldDefinitionFields?: Resolver<ResolversTypes['FieldDefinitionFields'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  system?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AccountRoleMembershipResolvers<ContextType = any, ParentType extends ResolversParentTypes['AccountRoleMembership'] = ResolversParentTypes['AccountRoleMembership']> = {
  account?: Resolver<ResolversTypes['Account'], ParentType, ContextType>;
  active?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  meta?: Resolver<Maybe<ResolversTypes['Metadata']>, ParentType, ContextType>;
  role?: Resolver<ResolversTypes['Role'], ParentType, ContextType>;
  validity?: Resolver<Maybe<ResolversTypes['Validity']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AccountRoleMembershipConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['AccountRoleMembershipConnection'] = ResolversParentTypes['AccountRoleMembershipConnection']> = {
  edges?: Resolver<Array<Maybe<ResolversTypes['AccountRoleMembershipEdge']>>, ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AccountRoleMembershipEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['AccountRoleMembershipEdge'] = ResolversParentTypes['AccountRoleMembershipEdge']> = {
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  node?: Resolver<ResolversTypes['AccountRoleMembership'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AccountTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['AccountType'] = ResolversParentTypes['AccountType']> = {
  apiName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  meta?: Resolver<Maybe<ResolversTypes['Metadata']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AccountTypeConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['AccountTypeConnection'] = ResolversParentTypes['AccountTypeConnection']> = {
  edges?: Resolver<Array<Maybe<ResolversTypes['AccountTypeEdge']>>, ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AccountTypeEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['AccountTypeEdge'] = ResolversParentTypes['AccountTypeEdge']> = {
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  node?: Resolver<ResolversTypes['AccountType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AddressResolvers<ContextType = any, ParentType extends ResolversParentTypes['Address'] = ResolversParentTypes['Address']> = {
  address1?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  address2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  address3?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  city?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  country?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  defaultLanguage?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  defaultTimeZone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  fax?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  faxExtension?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  meta?: Resolver<Maybe<ResolversTypes['Metadata']>, ParentType, ContextType>;
  mobilePhone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  organizationalEntities?: Resolver<Maybe<ResolversTypes['OrganizationalEntityConnection']>, ParentType, ContextType, Partial<AddressOrganizationalEntitiesArgs>>;
  phone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  phoneExtension?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  region?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  searchTerm?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  standardCommunicationMethod?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  state?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  zipcode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AddressConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['AddressConnection'] = ResolversParentTypes['AddressConnection']> = {
  edges?: Resolver<Maybe<Array<Maybe<ResolversTypes['AddressEdge']>>>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AddressEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['AddressEdge'] = ResolversParentTypes['AddressEdge']> = {
  cursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  node?: Resolver<Maybe<ResolversTypes['Address']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BusinessRuleResolvers<ContextType = any, ParentType extends ResolversParentTypes['BusinessRule'] = ResolversParentTypes['BusinessRule']> = {
  changes?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  criteriaGroups?: Resolver<Maybe<Array<ResolversTypes['CriteriaGroup']>>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  isPublished?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  meta?: Resolver<Maybe<ResolversTypes['Metadata']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  operatorOutsideGroup?: Resolver<ResolversTypes['RuleLogicalOperator'], ParentType, ContextType>;
  publishedId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  ruleAction?: Resolver<ResolversTypes['RuleAction'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['RuleStatus'], ParentType, ContextType>;
  targetValue?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BusinessRuleConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['BusinessRuleConnection'] = ResolversParentTypes['BusinessRuleConnection']> = {
  edges?: Resolver<Array<Maybe<ResolversTypes['BusinessRuleEdge']>>, ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BusinessRuleEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['BusinessRuleEdge'] = ResolversParentTypes['BusinessRuleEdge']> = {
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  node?: Resolver<ResolversTypes['BusinessRule'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BusinessRuleKeyResolvers<ContextType = any, ParentType extends ResolversParentTypes['BusinessRuleKey'] = ResolversParentTypes['BusinessRuleKey']> = {
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ruleAction?: Resolver<Maybe<ResolversTypes['RuleAction']>, ParentType, ContextType>;
  targetValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CriteriaGroupResolvers<ContextType = any, ParentType extends ResolversParentTypes['CriteriaGroup'] = ResolversParentTypes['CriteriaGroup']> = {
  criteriaInsideGroup?: Resolver<Array<ResolversTypes['Criterion']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  meta?: Resolver<Maybe<ResolversTypes['Metadata']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CriterionResolvers<ContextType = any, ParentType extends ResolversParentTypes['Criterion'] = ResolversParentTypes['Criterion']> = {
  entityField?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  meta?: Resolver<Maybe<ResolversTypes['Metadata']>, ParentType, ContextType>;
  negate?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  operation?: Resolver<ResolversTypes['RuleOperation'], ParentType, ContextType>;
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CustomFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CustomFields'] = ResolversParentTypes['CustomFields']> = {
  customField1?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  customField2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  customField3?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  customField4?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  customField5?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  customField6?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  customField7?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  customField8?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  customField9?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  customField10?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  customField11?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  customField12?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  customField13?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  customField14?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  customField15?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  customField16?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  customField17?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  customField18?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  customField19?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EntitlementResolvers<ContextType = any, ParentType extends ResolversParentTypes['Entitlement'] = ResolversParentTypes['Entitlement']> = {
  entitledId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  meta?: Resolver<Maybe<ResolversTypes['Metadata']>, ParentType, ContextType>;
  resourceTypeId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  systemId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  targetId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  validity?: Resolver<Maybe<ResolversTypes['Validity']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EntitlementConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['EntitlementConnection'] = ResolversParentTypes['EntitlementConnection']> = {
  edges?: Resolver<Array<Maybe<ResolversTypes['EntitlementEdge']>>, ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EntitlementEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['EntitlementEdge'] = ResolversParentTypes['EntitlementEdge']> = {
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  node?: Resolver<ResolversTypes['Entitlement'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ExternalEntityIdsResolvers<ContextType = any, ParentType extends ResolversParentTypes['ExternalEntityIds'] = ResolversParentTypes['ExternalEntityIds']> = {
  externalEntityId1?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  externalEntityId2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  externalEntityId3?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  externalEntityId4?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  externalEntityId5?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  externalEntityId6?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  externalEntityId7?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  externalEntityId8?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  externalEntityId9?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  externalEntityId10?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  externalEntityId11?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  externalEntityId12?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  externalEntityId13?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  externalEntityId14?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  externalEntityId15?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  externalEntityId16?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  externalEntityId17?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  externalEntityId18?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  externalEntityId19?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FieldDefinitionFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['FieldDefinitionFields'] = ResolversParentTypes['FieldDefinitionFields']> = {
  apiName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  autoValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  autoValueDefinition?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  autoValueSetting?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  defaultValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  fieldName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  fieldType?: Resolver<ResolversTypes['ValueTypes'], ParentType, ContextType>;
  helpText?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  readOnly?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  required?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  searchable?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  uiMaxCharacters?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  uiPosition?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  uniqueField?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  visible?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FilterEntryResolvers<ContextType = any, ParentType extends ResolversParentTypes['FilterEntry'] = ResolversParentTypes['FilterEntry']> = {
  count?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  filter?: Resolver<Maybe<ResolversTypes['FilterExpressionOutput']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  text?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FilterExpressionOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['FilterExpressionOutput'] = ResolversParentTypes['FilterExpressionOutput']> = {
  booleanValue?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  dateValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  enumValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  floatValue?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  intValue?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  logicalValue?: Resolver<Maybe<Array<Maybe<ResolversTypes['FilterExpressionOutput']>>>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  negate?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  operator?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  stringValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  uuidValue?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IdentityResolvers<ContextType = any, ParentType extends ResolversParentTypes['Identity'] = ResolversParentTypes['Identity']> = {
  accountingId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  accountsCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  companyMobilePhone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  companyPhone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  costCenter?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  customFields?: Resolver<Maybe<ResolversTypes['CustomFields']>, ParentType, ContextType>;
  employeeId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  externalEntityIds?: Resolver<Maybe<ResolversTypes['ExternalEntityIds']>, ParentType, ContextType>;
  firstName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  identityType?: Resolver<ResolversTypes['IdentityType'], ParentType, ContextType>;
  identityTypeField?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  initials?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lastName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  manager?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  meta?: Resolver<Maybe<ResolversTypes['Metadata']>, ParentType, ContextType>;
  primaryEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  privateEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  privateMobilePhone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  roleMemberships?: Resolver<Maybe<ResolversTypes['IdentityRoleMembershipConnection']>, ParentType, ContextType, Partial<IdentityRoleMembershipsArgs>>;
  roles?: Resolver<Maybe<ResolversTypes['RoleConnection']>, ParentType, ContextType, Partial<IdentityRolesArgs>>;
  salutation?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  secondaryManager?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  substitute?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IdentityConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['IdentityConnection'] = ResolversParentTypes['IdentityConnection']> = {
  edges?: Resolver<Array<Maybe<ResolversTypes['IdentityEdge']>>, ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IdentityEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['IdentityEdge'] = ResolversParentTypes['IdentityEdge']> = {
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  node?: Resolver<ResolversTypes['Identity'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IdentityFieldDefinitionResolvers<ContextType = any, ParentType extends ResolversParentTypes['IdentityFieldDefinition'] = ResolversParentTypes['IdentityFieldDefinition']> = {
  fieldDefinitionFields?: Resolver<ResolversTypes['FieldDefinitionFields'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  identityType?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IdentityRoleMembershipResolvers<ContextType = any, ParentType extends ResolversParentTypes['IdentityRoleMembership'] = ResolversParentTypes['IdentityRoleMembership']> = {
  allAccounts?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  identity?: Resolver<ResolversTypes['Identity'], ParentType, ContextType>;
  meta?: Resolver<Maybe<ResolversTypes['Metadata']>, ParentType, ContextType>;
  role?: Resolver<ResolversTypes['Role'], ParentType, ContextType>;
  template?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  validity?: Resolver<Maybe<ResolversTypes['Validity']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IdentityRoleMembershipConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['IdentityRoleMembershipConnection'] = ResolversParentTypes['IdentityRoleMembershipConnection']> = {
  edges?: Resolver<Array<Maybe<ResolversTypes['IdentityRoleMembershipEdge']>>, ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IdentityRoleMembershipEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['IdentityRoleMembershipEdge'] = ResolversParentTypes['IdentityRoleMembershipEdge']> = {
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  node?: Resolver<ResolversTypes['IdentityRoleMembership'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IdentityTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['IdentityType'] = ResolversParentTypes['IdentityType']> = {
  Identities?: Resolver<Maybe<Array<Maybe<ResolversTypes['Identity']>>>, ParentType, ContextType>;
  apiName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  meta?: Resolver<Maybe<ResolversTypes['Metadata']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IdentityTypeConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['IdentityTypeConnection'] = ResolversParentTypes['IdentityTypeConnection']> = {
  edges?: Resolver<Array<Maybe<ResolversTypes['IdentityTypeEdge']>>, ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IdentityTypeEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['IdentityTypeEdge'] = ResolversParentTypes['IdentityTypeEdge']> = {
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  node?: Resolver<ResolversTypes['IdentityType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MetadataResolvers<ContextType = any, ParentType extends ResolversParentTypes['Metadata'] = ResolversParentTypes['Metadata']> = {
  createdAt?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['Mutation'] = ResolversParentTypes['Mutation']> = {
  createAccount?: Resolver<ResolversTypes['Account'], ParentType, ContextType, Partial<MutationCreateAccountArgs>>;
  createAccountFieldDefinition?: Resolver<ResolversTypes['AccountFieldDefinition'], ParentType, ContextType, RequireFields<MutationCreateAccountFieldDefinitionArgs, 'input'>>;
  createAccountType?: Resolver<ResolversTypes['AccountType'], ParentType, ContextType, Partial<MutationCreateAccountTypeArgs>>;
  createAddress?: Resolver<ResolversTypes['Address'], ParentType, ContextType, Partial<MutationCreateAddressArgs>>;
  createBusinessRule?: Resolver<ResolversTypes['BusinessRule'], ParentType, ContextType, Partial<MutationCreateBusinessRuleArgs>>;
  createEntitlement?: Resolver<ResolversTypes['Entitlement'], ParentType, ContextType, RequireFields<MutationCreateEntitlementArgs, 'entitledId' | 'targetId' | 'type'>>;
  createIdentity?: Resolver<ResolversTypes['Identity'], ParentType, ContextType, Partial<MutationCreateIdentityArgs>>;
  createIdentityFieldDefinition?: Resolver<ResolversTypes['IdentityFieldDefinition'], ParentType, ContextType, RequireFields<MutationCreateIdentityFieldDefinitionArgs, 'input'>>;
  createIdentityRoleMembership?: Resolver<ResolversTypes['IdentityRoleMembership'], ParentType, ContextType, RequireFields<MutationCreateIdentityRoleMembershipArgs, 'identityId' | 'roleId'>>;
  createIdentityType?: Resolver<ResolversTypes['IdentityType'], ParentType, ContextType, Partial<MutationCreateIdentityTypeArgs>>;
  createOeRelation?: Resolver<ResolversTypes['OeRelation'], ParentType, ContextType, RequireFields<MutationCreateOeRelationArgs, 'assignedEntityId' | 'oetRelationTypeId' | 'ownerEntityId'>>;
  createOetRelation?: Resolver<ResolversTypes['OetRelation'], ParentType, ContextType, RequireFields<MutationCreateOetRelationArgs, 'assignedTypeId' | 'oetRelationTypeId' | 'ownerTypeId'>>;
  createOrganizationalEntity?: Resolver<ResolversTypes['OrganizationalEntity'], ParentType, ContextType, Partial<MutationCreateOrganizationalEntityArgs>>;
  createOrganizationalEntityType?: Resolver<ResolversTypes['OrganizationalEntityType'], ParentType, ContextType, Partial<MutationCreateOrganizationalEntityTypeArgs>>;
  createOwnership?: Resolver<Maybe<ResolversTypes['Ownership']>, ParentType, ContextType, RequireFields<MutationCreateOwnershipArgs, 'ownerId' | 'targetId' | 'type'>>;
  createResourceSet?: Resolver<ResolversTypes['ResourceSet'], ParentType, ContextType, Partial<MutationCreateResourceSetArgs>>;
  createRole?: Resolver<ResolversTypes['Role'], ParentType, ContextType, Partial<MutationCreateRoleArgs>>;
  createSystem?: Resolver<ResolversTypes['System'], ParentType, ContextType, Partial<MutationCreateSystemArgs>>;
  deleteAccount?: Resolver<ResolversTypes['Account'], ParentType, ContextType, RequireFields<MutationDeleteAccountArgs, 'id'>>;
  deleteAccountType?: Resolver<ResolversTypes['AccountType'], ParentType, ContextType, RequireFields<MutationDeleteAccountTypeArgs, 'id'>>;
  deleteAddress?: Resolver<ResolversTypes['Address'], ParentType, ContextType, RequireFields<MutationDeleteAddressArgs, 'id'>>;
  deleteBusinessRule?: Resolver<ResolversTypes['BusinessRule'], ParentType, ContextType, RequireFields<MutationDeleteBusinessRuleArgs, 'id'>>;
  deleteEntitlement?: Resolver<ResolversTypes['Entitlement'], ParentType, ContextType, RequireFields<MutationDeleteEntitlementArgs, 'id'>>;
  deleteIdentity?: Resolver<ResolversTypes['Identity'], ParentType, ContextType, RequireFields<MutationDeleteIdentityArgs, 'id'>>;
  deleteIdentityRoleMembership?: Resolver<ResolversTypes['IdentityRoleMembership'], ParentType, ContextType, RequireFields<MutationDeleteIdentityRoleMembershipArgs, 'id'>>;
  deleteIdentityType?: Resolver<ResolversTypes['IdentityType'], ParentType, ContextType, RequireFields<MutationDeleteIdentityTypeArgs, 'id'>>;
  deleteOeRelation?: Resolver<ResolversTypes['OeRelation'], ParentType, ContextType, RequireFields<MutationDeleteOeRelationArgs, 'id'>>;
  deleteOetRelation?: Resolver<ResolversTypes['OetRelation'], ParentType, ContextType, RequireFields<MutationDeleteOetRelationArgs, 'id'>>;
  deleteOrganizationalEntity?: Resolver<ResolversTypes['OrganizationalEntity'], ParentType, ContextType, RequireFields<MutationDeleteOrganizationalEntityArgs, 'id'>>;
  deleteOrganizationalEntityType?: Resolver<ResolversTypes['OrganizationalEntityType'], ParentType, ContextType, RequireFields<MutationDeleteOrganizationalEntityTypeArgs, 'id'>>;
  deleteOwnership?: Resolver<Maybe<ResolversTypes['Ownership']>, ParentType, ContextType, RequireFields<MutationDeleteOwnershipArgs, 'id'>>;
  deleteResourceSet?: Resolver<ResolversTypes['ResourceSet'], ParentType, ContextType, RequireFields<MutationDeleteResourceSetArgs, 'id'>>;
  deleteRole?: Resolver<ResolversTypes['Role'], ParentType, ContextType, RequireFields<MutationDeleteRoleArgs, 'id'>>;
  deleteSystem?: Resolver<ResolversTypes['System'], ParentType, ContextType, RequireFields<MutationDeleteSystemArgs, 'id'>>;
  updateAccount?: Resolver<ResolversTypes['Account'], ParentType, ContextType, RequireFields<MutationUpdateAccountArgs, 'id' | 'input'>>;
  updateAccountFieldDefinition?: Resolver<ResolversTypes['AccountFieldDefinition'], ParentType, ContextType, RequireFields<MutationUpdateAccountFieldDefinitionArgs, 'id' | 'input'>>;
  updateAccountFieldDefinitions?: Resolver<Array<Maybe<ResolversTypes['AccountFieldDefinition']>>, ParentType, ContextType, RequireFields<MutationUpdateAccountFieldDefinitionsArgs, 'inputs'>>;
  updateAccountRoleMembership?: Resolver<ResolversTypes['AccountRoleMembership'], ParentType, ContextType, RequireFields<MutationUpdateAccountRoleMembershipArgs, 'id'>>;
  updateAccountType?: Resolver<Maybe<ResolversTypes['AccountType']>, ParentType, ContextType, RequireFields<MutationUpdateAccountTypeArgs, 'id' | 'input'>>;
  updateAddress?: Resolver<ResolversTypes['Address'], ParentType, ContextType, RequireFields<MutationUpdateAddressArgs, 'id'>>;
  updateBusinessRule?: Resolver<ResolversTypes['BusinessRule'], ParentType, ContextType, RequireFields<MutationUpdateBusinessRuleArgs, 'id'>>;
  updateEntitlement?: Resolver<ResolversTypes['Entitlement'], ParentType, ContextType, RequireFields<MutationUpdateEntitlementArgs, 'id'>>;
  updateIdentity?: Resolver<ResolversTypes['Identity'], ParentType, ContextType, RequireFields<MutationUpdateIdentityArgs, 'id'>>;
  updateIdentityFieldDefinition?: Resolver<ResolversTypes['IdentityFieldDefinition'], ParentType, ContextType, RequireFields<MutationUpdateIdentityFieldDefinitionArgs, 'id' | 'input'>>;
  updateIdentityFieldDefinitions?: Resolver<Array<Maybe<ResolversTypes['IdentityFieldDefinition']>>, ParentType, ContextType, RequireFields<MutationUpdateIdentityFieldDefinitionsArgs, 'inputs'>>;
  updateIdentityRoleMembership?: Resolver<ResolversTypes['IdentityRoleMembership'], ParentType, ContextType, RequireFields<MutationUpdateIdentityRoleMembershipArgs, 'id'>>;
  updateIdentityType?: Resolver<Maybe<ResolversTypes['IdentityType']>, ParentType, ContextType, RequireFields<MutationUpdateIdentityTypeArgs, 'id' | 'input'>>;
  updateOeRelation?: Resolver<ResolversTypes['OeRelation'], ParentType, ContextType, RequireFields<MutationUpdateOeRelationArgs, 'id'>>;
  updateOetRelation?: Resolver<ResolversTypes['OetRelation'], ParentType, ContextType, RequireFields<MutationUpdateOetRelationArgs, 'id'>>;
  updateOrganizationalEntity?: Resolver<Maybe<ResolversTypes['OrganizationalEntity']>, ParentType, ContextType, RequireFields<MutationUpdateOrganizationalEntityArgs, 'id' | 'input'>>;
  updateOrganizationalEntityType?: Resolver<Maybe<ResolversTypes['OrganizationalEntityType']>, ParentType, ContextType, RequireFields<MutationUpdateOrganizationalEntityTypeArgs, 'id' | 'input'>>;
  updateOwnership?: Resolver<Maybe<ResolversTypes['Ownership']>, ParentType, ContextType, RequireFields<MutationUpdateOwnershipArgs, 'id'>>;
  updateResourceSet?: Resolver<ResolversTypes['ResourceSet'], ParentType, ContextType, RequireFields<MutationUpdateResourceSetArgs, 'id' | 'input'>>;
  updateRole?: Resolver<ResolversTypes['Role'], ParentType, ContextType, RequireFields<MutationUpdateRoleArgs, 'id'>>;
  updateSystem?: Resolver<ResolversTypes['System'], ParentType, ContextType, RequireFields<MutationUpdateSystemArgs, 'id' | 'input'>>;
};

export type OeRelationResolvers<ContextType = any, ParentType extends ResolversParentTypes['OeRelation'] = ResolversParentTypes['OeRelation']> = {
  assignedEntity?: Resolver<ResolversTypes['OrganizationalEntity'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  meta?: Resolver<Maybe<ResolversTypes['Metadata']>, ParentType, ContextType>;
  oetRelation?: Resolver<ResolversTypes['OetRelation'], ParentType, ContextType>;
  ownerEntity?: Resolver<ResolversTypes['OrganizationalEntity'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OeRelationConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['OeRelationConnection'] = ResolversParentTypes['OeRelationConnection']> = {
  edges?: Resolver<Maybe<Array<Maybe<ResolversTypes['OeRelationEdge']>>>, ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OeRelationEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['OeRelationEdge'] = ResolversParentTypes['OeRelationEdge']> = {
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  node?: Resolver<Maybe<ResolversTypes['OeRelation']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OetRelationResolvers<ContextType = any, ParentType extends ResolversParentTypes['OetRelation'] = ResolversParentTypes['OetRelation']> = {
  assignedType?: Resolver<ResolversTypes['OrganizationalEntityType'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  isRequired?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  meta?: Resolver<Maybe<ResolversTypes['Metadata']>, ParentType, ContextType>;
  oetRelationType?: Resolver<ResolversTypes['OetRelationType'], ParentType, ContextType>;
  ownerType?: Resolver<ResolversTypes['OrganizationalEntityType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OetRelationConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['OetRelationConnection'] = ResolversParentTypes['OetRelationConnection']> = {
  edges?: Resolver<Maybe<Array<Maybe<ResolversTypes['OetRelationEdge']>>>, ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OetRelationEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['OetRelationEdge'] = ResolversParentTypes['OetRelationEdge']> = {
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  node?: Resolver<Maybe<ResolversTypes['OetRelation']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OetRelationTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['OetRelationType'] = ResolversParentTypes['OetRelationType']> = {
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrganizationalEntityResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrganizationalEntity'] = ResolversParentTypes['OrganizationalEntity']> = {
  address?: Resolver<Maybe<ResolversTypes['Address']>, ParentType, ContextType>;
  alternativeName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  customID?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  meta?: Resolver<Maybe<ResolversTypes['Metadata']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  organizationalEntityType?: Resolver<Maybe<ResolversTypes['OrganizationalEntityType']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrganizationalEntityConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrganizationalEntityConnection'] = ResolversParentTypes['OrganizationalEntityConnection']> = {
  edges?: Resolver<Array<Maybe<ResolversTypes['OrganizationalEntityEdge']>>, ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrganizationalEntityEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrganizationalEntityEdge'] = ResolversParentTypes['OrganizationalEntityEdge']> = {
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  node?: Resolver<ResolversTypes['OrganizationalEntity'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrganizationalEntityTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrganizationalEntityType'] = ResolversParentTypes['OrganizationalEntityType']> = {
  apiName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  meta?: Resolver<Maybe<ResolversTypes['Metadata']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  organizationalEntities?: Resolver<Maybe<ResolversTypes['OrganizationalEntityConnection']>, ParentType, ContextType, Partial<OrganizationalEntityTypeOrganizationalEntitiesArgs>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrganizationalEntityTypeConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrganizationalEntityTypeConnection'] = ResolversParentTypes['OrganizationalEntityTypeConnection']> = {
  edges?: Resolver<Array<Maybe<ResolversTypes['OrganizationalEntityTypeEdge']>>, ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrganizationalEntityTypeEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrganizationalEntityTypeEdge'] = ResolversParentTypes['OrganizationalEntityTypeEdge']> = {
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  node?: Resolver<ResolversTypes['OrganizationalEntityType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OwnershipResolvers<ContextType = any, ParentType extends ResolversParentTypes['Ownership'] = ResolversParentTypes['Ownership']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  meta?: Resolver<Maybe<ResolversTypes['Metadata']>, ParentType, ContextType>;
  ownerId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  targetId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  validity?: Resolver<Maybe<ResolversTypes['Validity']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OwnershipConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['OwnershipConnection'] = ResolversParentTypes['OwnershipConnection']> = {
  edges?: Resolver<Array<Maybe<ResolversTypes['OwnershipEdge']>>, ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OwnershipEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['OwnershipEdge'] = ResolversParentTypes['OwnershipEdge']> = {
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  node?: Resolver<ResolversTypes['Ownership'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PageInfoResolvers<ContextType = any, ParentType extends ResolversParentTypes['PageInfo'] = ResolversParentTypes['PageInfo']> = {
  endCursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  hasNextPage?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  hasPreviousPage?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  startCursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type QueryResolvers<ContextType = any, ParentType extends ResolversParentTypes['Query'] = ResolversParentTypes['Query']> = {
  account?: Resolver<Maybe<ResolversTypes['Account']>, ParentType, ContextType, RequireFields<QueryAccountArgs, 'id'>>;
  accountFieldDefinition?: Resolver<Maybe<ResolversTypes['AccountFieldDefinition']>, ParentType, ContextType, RequireFields<QueryAccountFieldDefinitionArgs, 'id'>>;
  accountFieldDefinitions?: Resolver<Maybe<Array<Maybe<ResolversTypes['AccountFieldDefinition']>>>, ParentType, ContextType>;
  accountRoleMembership?: Resolver<Maybe<ResolversTypes['AccountRoleMembership']>, ParentType, ContextType, RequireFields<QueryAccountRoleMembershipArgs, 'id'>>;
  accountRoleMemberships?: Resolver<Maybe<ResolversTypes['AccountRoleMembershipConnection']>, ParentType, ContextType, Partial<QueryAccountRoleMembershipsArgs>>;
  accountType?: Resolver<Maybe<ResolversTypes['AccountType']>, ParentType, ContextType, RequireFields<QueryAccountTypeArgs, 'id'>>;
  accountTypes?: Resolver<Maybe<ResolversTypes['AccountTypeConnection']>, ParentType, ContextType, Partial<QueryAccountTypesArgs>>;
  accounts?: Resolver<Maybe<ResolversTypes['AccountConnection']>, ParentType, ContextType, Partial<QueryAccountsArgs>>;
  address?: Resolver<Maybe<ResolversTypes['Address']>, ParentType, ContextType, RequireFields<QueryAddressArgs, 'id'>>;
  addresses?: Resolver<Maybe<ResolversTypes['AddressConnection']>, ParentType, ContextType, Partial<QueryAddressesArgs>>;
  businessRule?: Resolver<Maybe<ResolversTypes['BusinessRule']>, ParentType, ContextType, RequireFields<QueryBusinessRuleArgs, 'id'>>;
  businessRuleStatusFilterEntries?: Resolver<Array<Maybe<ResolversTypes['FilterEntry']>>, ParentType, ContextType>;
  businessRules?: Resolver<Maybe<ResolversTypes['BusinessRuleConnection']>, ParentType, ContextType, Partial<QueryBusinessRulesArgs>>;
  calculateRulesForSivisEntities?: Resolver<Maybe<Array<Maybe<ResolversTypes['RuleExecutionResult']>>>, ParentType, ContextType, Partial<QueryCalculateRulesForSivisEntitiesArgs>>;
  entitlement?: Resolver<Maybe<ResolversTypes['Entitlement']>, ParentType, ContextType, RequireFields<QueryEntitlementArgs, 'id'>>;
  entitlements?: Resolver<Maybe<ResolversTypes['EntitlementConnection']>, ParentType, ContextType, Partial<QueryEntitlementsArgs>>;
  entitlementsResourceTypes?: Resolver<Array<Maybe<ResolversTypes['FilterEntry']>>, ParentType, ContextType, RequireFields<QueryEntitlementsResourceTypesArgs, 'entitledId' | 'type'>>;
  entitlementsSystems?: Resolver<Array<Maybe<ResolversTypes['FilterEntry']>>, ParentType, ContextType, RequireFields<QueryEntitlementsSystemsArgs, 'entitledId' | 'type'>>;
  entitlementsValidity?: Resolver<Array<Maybe<ResolversTypes['FilterEntry']>>, ParentType, ContextType, RequireFields<QueryEntitlementsValidityArgs, 'entitledId' | 'type'>>;
  getDraftForBusinessRule?: Resolver<Maybe<ResolversTypes['BusinessRule']>, ParentType, ContextType, RequireFields<QueryGetDraftForBusinessRuleArgs, 'id'>>;
  identities?: Resolver<Maybe<ResolversTypes['IdentityConnection']>, ParentType, ContextType, Partial<QueryIdentitiesArgs>>;
  identitiesById?: Resolver<Maybe<Array<Maybe<ResolversTypes['Identity']>>>, ParentType, ContextType, RequireFields<QueryIdentitiesByIdArgs, 'input'>>;
  identitiesByString?: Resolver<Maybe<Array<Maybe<ResolversTypes['Identity']>>>, ParentType, ContextType, Partial<QueryIdentitiesByStringArgs>>;
  identitiesCreatableResourceEntitlements?: Resolver<Maybe<ResolversTypes['IdentityConnection']>, ParentType, ContextType, RequireFields<QueryIdentitiesCreatableResourceEntitlementsArgs, 'resourceId'>>;
  identity?: Resolver<Maybe<ResolversTypes['Identity']>, ParentType, ContextType, RequireFields<QueryIdentityArgs, 'id'>>;
  identityCreationDates?: Resolver<Array<Maybe<ResolversTypes['FilterEntry']>>, ParentType, ContextType>;
  identityFieldDefinition?: Resolver<Maybe<ResolversTypes['IdentityFieldDefinition']>, ParentType, ContextType, RequireFields<QueryIdentityFieldDefinitionArgs, 'id'>>;
  identityFieldDefinitions?: Resolver<Maybe<Array<Maybe<ResolversTypes['IdentityFieldDefinition']>>>, ParentType, ContextType>;
  identityRoleMembership?: Resolver<Maybe<ResolversTypes['IdentityRoleMembership']>, ParentType, ContextType, RequireFields<QueryIdentityRoleMembershipArgs, 'id'>>;
  identityRoleMemberships?: Resolver<Maybe<ResolversTypes['IdentityRoleMembershipConnection']>, ParentType, ContextType, Partial<QueryIdentityRoleMembershipsArgs>>;
  identityType?: Resolver<Maybe<ResolversTypes['IdentityType']>, ParentType, ContextType, RequireFields<QueryIdentityTypeArgs, 'id'>>;
  identityTypes?: Resolver<Maybe<ResolversTypes['IdentityTypeConnection']>, ParentType, ContextType, Partial<QueryIdentityTypesArgs>>;
  identityUpdateDates?: Resolver<Array<Maybe<ResolversTypes['FilterEntry']>>, ParentType, ContextType>;
  oeRelation?: Resolver<Maybe<ResolversTypes['OeRelation']>, ParentType, ContextType, RequireFields<QueryOeRelationArgs, 'id'>>;
  oeRelations?: Resolver<Maybe<ResolversTypes['OeRelationConnection']>, ParentType, ContextType, Partial<QueryOeRelationsArgs>>;
  oetRelation?: Resolver<Maybe<ResolversTypes['OetRelation']>, ParentType, ContextType, RequireFields<QueryOetRelationArgs, 'id'>>;
  oetRelationTypes?: Resolver<Maybe<Array<Maybe<ResolversTypes['OetRelationType']>>>, ParentType, ContextType>;
  oetRelations?: Resolver<Maybe<ResolversTypes['OetRelationConnection']>, ParentType, ContextType, Partial<QueryOetRelationsArgs>>;
  organizationalEntities?: Resolver<Maybe<ResolversTypes['OrganizationalEntityConnection']>, ParentType, ContextType, Partial<QueryOrganizationalEntitiesArgs>>;
  organizationalEntity?: Resolver<Maybe<ResolversTypes['OrganizationalEntity']>, ParentType, ContextType, RequireFields<QueryOrganizationalEntityArgs, 'id'>>;
  organizationalEntityType?: Resolver<Maybe<ResolversTypes['OrganizationalEntityType']>, ParentType, ContextType, RequireFields<QueryOrganizationalEntityTypeArgs, 'id'>>;
  organizationalEntityTypes?: Resolver<Maybe<ResolversTypes['OrganizationalEntityTypeConnection']>, ParentType, ContextType, Partial<QueryOrganizationalEntityTypesArgs>>;
  ownership?: Resolver<Maybe<ResolversTypes['Ownership']>, ParentType, ContextType, RequireFields<QueryOwnershipArgs, 'id'>>;
  ownershipTypes?: Resolver<Array<Maybe<ResolversTypes['FilterEntry']>>, ParentType, ContextType, RequireFields<QueryOwnershipTypesArgs, 'ownerId' | 'targetId'>>;
  ownerships?: Resolver<Maybe<ResolversTypes['OwnershipConnection']>, ParentType, ContextType, Partial<QueryOwnershipsArgs>>;
  ownershipsValidity?: Resolver<Array<Maybe<ResolversTypes['FilterEntry']>>, ParentType, ContextType, RequireFields<QueryOwnershipsValidityArgs, 'ownerId' | 'targetId'>>;
  resource?: Resolver<ResolversTypes['Resource'], ParentType, ContextType, RequireFields<QueryResourceArgs, 'id'>>;
  resourceSet?: Resolver<Maybe<ResolversTypes['ResourceSet']>, ParentType, ContextType, RequireFields<QueryResourceSetArgs, 'id'>>;
  resourceSets?: Resolver<Maybe<ResolversTypes['ResourceSetConnection']>, ParentType, ContextType, Partial<QueryResourceSetsArgs>>;
  resourceType?: Resolver<Maybe<ResolversTypes['ResourceType']>, ParentType, ContextType, RequireFields<QueryResourceTypeArgs, 'id'>>;
  resourceTypeFilterEntries?: Resolver<Array<Maybe<ResolversTypes['FilterEntry']>>, ParentType, ContextType>;
  resourceTypes?: Resolver<Maybe<ResolversTypes['ResourceTypeConnection']>, ParentType, ContextType, Partial<QueryResourceTypesArgs>>;
  resources?: Resolver<Maybe<ResolversTypes['ResourceConnection']>, ParentType, ContextType, Partial<QueryResourcesArgs>>;
  role?: Resolver<Maybe<ResolversTypes['Role']>, ParentType, ContextType, RequireFields<QueryRoleArgs, 'id'>>;
  roleCreationDates?: Resolver<Array<Maybe<ResolversTypes['FilterEntry']>>, ParentType, ContextType>;
  roleUpdateDates?: Resolver<Array<Maybe<ResolversTypes['FilterEntry']>>, ParentType, ContextType>;
  roles?: Resolver<Maybe<ResolversTypes['RoleConnection']>, ParentType, ContextType, Partial<QueryRolesArgs>>;
  system?: Resolver<Maybe<ResolversTypes['System']>, ParentType, ContextType, RequireFields<QuerySystemArgs, 'id'>>;
  systemType?: Resolver<Maybe<ResolversTypes['SystemType']>, ParentType, ContextType, RequireFields<QuerySystemTypeArgs, 'id'>>;
  systemTypeFilterEntries?: Resolver<Array<Maybe<ResolversTypes['FilterEntry']>>, ParentType, ContextType>;
  systemTypes?: Resolver<Maybe<ResolversTypes['SystemTypeConnection']>, ParentType, ContextType, Partial<QuerySystemTypesArgs>>;
  systems?: Resolver<Maybe<ResolversTypes['SystemConnection']>, ParentType, ContextType, Partial<QuerySystemsArgs>>;
};

export type ResourceResolvers<ContextType = any, ParentType extends ResolversParentTypes['Resource'] = ResolversParentTypes['Resource']> = {
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  meta?: Resolver<Maybe<ResolversTypes['Metadata']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  resourceId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  resourceSets?: Resolver<Maybe<ResolversTypes['ResourceSetConnection']>, ParentType, ContextType, Partial<ResourceResourceSetsArgs>>;
  resourceType?: Resolver<Maybe<ResolversTypes['ResourceType']>, ParentType, ContextType>;
  system?: Resolver<Maybe<ResolversTypes['System']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ResourceConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['ResourceConnection'] = ResolversParentTypes['ResourceConnection']> = {
  edges?: Resolver<Array<Maybe<ResolversTypes['ResourceEdge']>>, ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ResourceEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['ResourceEdge'] = ResolversParentTypes['ResourceEdge']> = {
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  node?: Resolver<ResolversTypes['Resource'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ResourceSetResolvers<ContextType = any, ParentType extends ResolversParentTypes['ResourceSet'] = ResolversParentTypes['ResourceSet']> = {
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  meta?: Resolver<Maybe<ResolversTypes['Metadata']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  resources?: Resolver<Maybe<ResolversTypes['ResourceConnection']>, ParentType, ContextType, Partial<ResourceSetResourcesArgs>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ResourceSetConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['ResourceSetConnection'] = ResolversParentTypes['ResourceSetConnection']> = {
  edges?: Resolver<Array<Maybe<ResolversTypes['ResourceSetEdge']>>, ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ResourceSetEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['ResourceSetEdge'] = ResolversParentTypes['ResourceSetEdge']> = {
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  node?: Resolver<ResolversTypes['ResourceSet'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ResourceTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['ResourceType'] = ResolversParentTypes['ResourceType']> = {
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  meta?: Resolver<Maybe<ResolversTypes['Metadata']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  resources?: Resolver<Maybe<ResolversTypes['ResourceConnection']>, ParentType, ContextType, Partial<ResourceTypeResourcesArgs>>;
  systemType?: Resolver<Maybe<ResolversTypes['SystemType']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ResourceTypeConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['ResourceTypeConnection'] = ResolversParentTypes['ResourceTypeConnection']> = {
  edges?: Resolver<Array<Maybe<ResolversTypes['ResourceTypeEdge']>>, ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ResourceTypeEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['ResourceTypeEdge'] = ResolversParentTypes['ResourceTypeEdge']> = {
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  node?: Resolver<ResolversTypes['ResourceType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RoleResolvers<ContextType = any, ParentType extends ResolversParentTypes['Role'] = ResolversParentTypes['Role']> = {
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  identities?: Resolver<Maybe<ResolversTypes['IdentityConnection']>, ParentType, ContextType, Partial<RoleIdentitiesArgs>>;
  identitiesCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  identityMemberships?: Resolver<Maybe<ResolversTypes['IdentityRoleMembershipConnection']>, ParentType, ContextType, Partial<RoleIdentityMembershipsArgs>>;
  meta?: Resolver<Maybe<ResolversTypes['Metadata']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RoleConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['RoleConnection'] = ResolversParentTypes['RoleConnection']> = {
  edges?: Resolver<Array<Maybe<ResolversTypes['RoleEdge']>>, ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RoleEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['RoleEdge'] = ResolversParentTypes['RoleEdge']> = {
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  node?: Resolver<ResolversTypes['Role'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RuleExecutionResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['RuleExecutionResult'] = ResolversParentTypes['RuleExecutionResult']> = {
  businessRuleKey?: Resolver<Maybe<ResolversTypes['BusinessRuleKey']>, ParentType, ContextType>;
  entityIds?: Resolver<Maybe<Array<Maybe<ResolversTypes['ID']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SystemResolvers<ContextType = any, ParentType extends ResolversParentTypes['System'] = ResolversParentTypes['System']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  meta?: Resolver<Maybe<ResolversTypes['Metadata']>, ParentType, ContextType>;
  resources?: Resolver<Maybe<ResolversTypes['ResourceConnection']>, ParentType, ContextType, Partial<SystemResourcesArgs>>;
  systemDescription?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  systemName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  systemType?: Resolver<Maybe<ResolversTypes['SystemType']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SystemConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['SystemConnection'] = ResolversParentTypes['SystemConnection']> = {
  edges?: Resolver<Array<Maybe<ResolversTypes['SystemEdge']>>, ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SystemEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['SystemEdge'] = ResolversParentTypes['SystemEdge']> = {
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  node?: Resolver<ResolversTypes['System'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SystemTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['SystemType'] = ResolversParentTypes['SystemType']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  meta?: Resolver<Maybe<ResolversTypes['Metadata']>, ParentType, ContextType>;
  resourceTypes?: Resolver<Maybe<ResolversTypes['ResourceTypeConnection']>, ParentType, ContextType, Partial<SystemTypeResourceTypesArgs>>;
  systemTypeDescription?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  systemTypeName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  systems?: Resolver<Maybe<ResolversTypes['SystemConnection']>, ParentType, ContextType, Partial<SystemTypeSystemsArgs>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SystemTypeConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['SystemTypeConnection'] = ResolversParentTypes['SystemTypeConnection']> = {
  edges?: Resolver<Array<Maybe<ResolversTypes['SystemTypeEdge']>>, ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SystemTypeEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['SystemTypeEdge'] = ResolversParentTypes['SystemTypeEdge']> = {
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  node?: Resolver<ResolversTypes['SystemType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ValidityResolvers<ContextType = any, ParentType extends ResolversParentTypes['Validity'] = ResolversParentTypes['Validity']> = {
  validFrom?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  validTo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Resolvers<ContextType = any> = {
  Account?: AccountResolvers<ContextType>;
  AccountConnection?: AccountConnectionResolvers<ContextType>;
  AccountEdge?: AccountEdgeResolvers<ContextType>;
  AccountFieldDefinition?: AccountFieldDefinitionResolvers<ContextType>;
  AccountRoleMembership?: AccountRoleMembershipResolvers<ContextType>;
  AccountRoleMembershipConnection?: AccountRoleMembershipConnectionResolvers<ContextType>;
  AccountRoleMembershipEdge?: AccountRoleMembershipEdgeResolvers<ContextType>;
  AccountType?: AccountTypeResolvers<ContextType>;
  AccountTypeConnection?: AccountTypeConnectionResolvers<ContextType>;
  AccountTypeEdge?: AccountTypeEdgeResolvers<ContextType>;
  Address?: AddressResolvers<ContextType>;
  AddressConnection?: AddressConnectionResolvers<ContextType>;
  AddressEdge?: AddressEdgeResolvers<ContextType>;
  BusinessRule?: BusinessRuleResolvers<ContextType>;
  BusinessRuleConnection?: BusinessRuleConnectionResolvers<ContextType>;
  BusinessRuleEdge?: BusinessRuleEdgeResolvers<ContextType>;
  BusinessRuleKey?: BusinessRuleKeyResolvers<ContextType>;
  CriteriaGroup?: CriteriaGroupResolvers<ContextType>;
  Criterion?: CriterionResolvers<ContextType>;
  CustomFields?: CustomFieldsResolvers<ContextType>;
  Entitlement?: EntitlementResolvers<ContextType>;
  EntitlementConnection?: EntitlementConnectionResolvers<ContextType>;
  EntitlementEdge?: EntitlementEdgeResolvers<ContextType>;
  ExternalEntityIds?: ExternalEntityIdsResolvers<ContextType>;
  FieldDefinitionFields?: FieldDefinitionFieldsResolvers<ContextType>;
  FilterEntry?: FilterEntryResolvers<ContextType>;
  FilterExpressionOutput?: FilterExpressionOutputResolvers<ContextType>;
  Identity?: IdentityResolvers<ContextType>;
  IdentityConnection?: IdentityConnectionResolvers<ContextType>;
  IdentityEdge?: IdentityEdgeResolvers<ContextType>;
  IdentityFieldDefinition?: IdentityFieldDefinitionResolvers<ContextType>;
  IdentityRoleMembership?: IdentityRoleMembershipResolvers<ContextType>;
  IdentityRoleMembershipConnection?: IdentityRoleMembershipConnectionResolvers<ContextType>;
  IdentityRoleMembershipEdge?: IdentityRoleMembershipEdgeResolvers<ContextType>;
  IdentityType?: IdentityTypeResolvers<ContextType>;
  IdentityTypeConnection?: IdentityTypeConnectionResolvers<ContextType>;
  IdentityTypeEdge?: IdentityTypeEdgeResolvers<ContextType>;
  Metadata?: MetadataResolvers<ContextType>;
  Mutation?: MutationResolvers<ContextType>;
  OeRelation?: OeRelationResolvers<ContextType>;
  OeRelationConnection?: OeRelationConnectionResolvers<ContextType>;
  OeRelationEdge?: OeRelationEdgeResolvers<ContextType>;
  OetRelation?: OetRelationResolvers<ContextType>;
  OetRelationConnection?: OetRelationConnectionResolvers<ContextType>;
  OetRelationEdge?: OetRelationEdgeResolvers<ContextType>;
  OetRelationType?: OetRelationTypeResolvers<ContextType>;
  OrganizationalEntity?: OrganizationalEntityResolvers<ContextType>;
  OrganizationalEntityConnection?: OrganizationalEntityConnectionResolvers<ContextType>;
  OrganizationalEntityEdge?: OrganizationalEntityEdgeResolvers<ContextType>;
  OrganizationalEntityType?: OrganizationalEntityTypeResolvers<ContextType>;
  OrganizationalEntityTypeConnection?: OrganizationalEntityTypeConnectionResolvers<ContextType>;
  OrganizationalEntityTypeEdge?: OrganizationalEntityTypeEdgeResolvers<ContextType>;
  Ownership?: OwnershipResolvers<ContextType>;
  OwnershipConnection?: OwnershipConnectionResolvers<ContextType>;
  OwnershipEdge?: OwnershipEdgeResolvers<ContextType>;
  PageInfo?: PageInfoResolvers<ContextType>;
  Query?: QueryResolvers<ContextType>;
  Resource?: ResourceResolvers<ContextType>;
  ResourceConnection?: ResourceConnectionResolvers<ContextType>;
  ResourceEdge?: ResourceEdgeResolvers<ContextType>;
  ResourceSet?: ResourceSetResolvers<ContextType>;
  ResourceSetConnection?: ResourceSetConnectionResolvers<ContextType>;
  ResourceSetEdge?: ResourceSetEdgeResolvers<ContextType>;
  ResourceType?: ResourceTypeResolvers<ContextType>;
  ResourceTypeConnection?: ResourceTypeConnectionResolvers<ContextType>;
  ResourceTypeEdge?: ResourceTypeEdgeResolvers<ContextType>;
  Role?: RoleResolvers<ContextType>;
  RoleConnection?: RoleConnectionResolvers<ContextType>;
  RoleEdge?: RoleEdgeResolvers<ContextType>;
  RuleExecutionResult?: RuleExecutionResultResolvers<ContextType>;
  System?: SystemResolvers<ContextType>;
  SystemConnection?: SystemConnectionResolvers<ContextType>;
  SystemEdge?: SystemEdgeResolvers<ContextType>;
  SystemType?: SystemTypeResolvers<ContextType>;
  SystemTypeConnection?: SystemTypeConnectionResolvers<ContextType>;
  SystemTypeEdge?: SystemTypeEdgeResolvers<ContextType>;
  Validity?: ValidityResolvers<ContextType>;
};

export type DirectiveResolvers<ContextType = any> = {
  filterQuery?: FilterQueryDirectiveResolver<any, any, ContextType>;
  oneOf?: OneOfDirectiveResolver<any, any, ContextType>;
};
