import { FilterOperator } from '@sivis/shared/api-utils';
import { defaults, omit } from 'lodash';
import {
  Account,
  AccountInput,
  AccountType,
  AccountTypeInput,
  FilterExpression
} from '../identityApi.types';

export const searchAccountsFilter = (searchStr: string): FilterExpression => ({
  operator: FilterOperator.OR,
  logicalValue: [
    { name: "name", operator: FilterOperator.CONTAINS, stringValue: searchStr },
  ]
});

export const accountInputFactory = (account?: Account | AccountInput | null | Record<string, any>): AccountInput => {
  if (!account) {
    return {
      name: '',
      accountType: '',
      identityId: undefined,
      system: undefined
    };
  }

  let accountWithStrippedSystem = { ...account };

  if (account.system && typeof account.system === 'object' && 'id' in account.system) {
    accountWithStrippedSystem.system = account.system.id;
  }

  return {
    name: account.name || '',
    accountType: account.accountType || '',
    identityId: account.identityId, // Keep identityId as is, even if it's an empty string
    system: accountWithStrippedSystem.system || undefined
  };
};


// Factory function for AccountType
export const accountTypeFactory = (accountTypeInput?: AccountTypeInput): AccountType => {
  if (!accountTypeInput) {
    return {
      id: '', // Scalars['ID'] is a string
      name: '', // Scalars['String']
      description: undefined, // Maybe<Scalars['String']>
      __typename: 'AccountType', // Optional
    };
  }

  return {
    id: '', // Scalars['ID']
    name: accountTypeInput.name ? accountTypeInput.name : '', // Scalars['String']
    description: undefined, // Maybe<Scalars['String']>
    __typename: 'AccountType', // Optional
  };
};

// Factory function for empty AccountTypeInput
const emptyAccountTypeInput = (): AccountTypeInput => ({
  name: '',
  description: ''
});

// Factory function for AccountTypeInput from an AccountType
export const accountTypeInputFactory = (accountType?: AccountType | AccountTypeInput | null | Record<string, any>): AccountTypeInput => {
  if (!accountType) {
    return emptyAccountTypeInput();
  }

  // Use defaults to fill in any missing properties with values from emptyAccountTypeInput
  return defaults({}, omit(accountType, ['id', 'Accounts', 'meta']), emptyAccountTypeInput());
};

export const searchAccountTypesFilter = (searchStr: string): FilterExpression => ({
  operator: FilterOperator.OR,
  logicalValue: [
    { name: "name", operator: FilterOperator.CONTAINS, stringValue: searchStr },
    { name: "description", operator: FilterOperator.CONTAINS, stringValue: searchStr },
  ]
});
