import {EntityView} from "@sivis/shared/components/entityView";
import {useSystemTypeApi} from "./useSystemTypeApi";
import SystemTypeInformationTab, {SystemTypeInformationTabProps} from "./systemTypeInformationTab";
import {Outlet, useOutletContext} from "react-router-dom";
import SystemTypeSystemsTab, {SystemTypeSystemsTabProps} from "./systemTypeSystemsTab";
import {
  INFORMATION_TAB_PATHNAME,
  SYSTEM_TYPE_URL,
  SYSTEMS_TAB_PATHNAME
} from '../components/routes/constants';
import {useCustomIntl} from "@sivis/intl";
import {getTranslatedErrorMessage} from "@sivis/shared/api-utils";
import { useRouteRedirect } from '../common/useRouteRedirect';

export const SystemTypeView = () => {
  useRouteRedirect({ basePath: SYSTEM_TYPE_URL, subPath: INFORMATION_TAB_PATHNAME });

  const intl = useCustomIntl();
  const {
    defaultInput,
    systemType,
    error
  } = useSystemTypeApi();

  const informationTab = {
    label: intl.format("fusion.breadcrumb.information"),
    path: INFORMATION_TAB_PATHNAME
  };
  const systemsTab = {label: intl.format("fusion.system.list.name"), path: SYSTEMS_TAB_PATHNAME}

  const tabs = [informationTab, systemsTab];

  return <EntityView
    title={`${intl.format("fusion.systemType.information.titleExisting")}${defaultInput.systemTypeName}`}
    tabs={tabs}
    error={error ? getTranslatedErrorMessage(error, "fusion.systemType.error.notFound", intl.format) : undefined}>
    <Outlet context={{systemType}}/>
  </EntityView>;
};

export const SystemTypeInformationTabWrapperComponent = () => {
  const {systemType} = useOutletContext<SystemTypeInformationTabProps>();
  return <SystemTypeInformationTab systemType={systemType}/>;
};

export const SystemTypeSystemsTabWrapperComponent = () => {
  const {systemType} = useOutletContext<SystemTypeSystemsTabProps>();
  return <SystemTypeSystemsTab systemType={systemType}/>;
};
