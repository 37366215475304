import {
  EntitlementType,
  ExtendedIdentityEntitlement,
  RelationQueryType, Resource
} from '@sivis/identity/api';
import { CustomIntlType, useCustomIntl, useTranslateColumns } from '@sivis/intl';
import { EntityTableRow, ListModifyViewUpdated } from '@sivis/shared/components/entityView';
import { isEqualById } from '@sivis/shared/utils';
import { useOutletContext } from 'react-router-dom';
import { EntityType, useSingleEditMode } from '../components/breadcrumb/editModeContext';
import { ConfirmationPopup } from '../components/popup/confirmationPopup';
import {
  useResourceIdentityEntitlementsApi
} from '../entitlement/api/useResourceIdentityEntitlementsApi';
import { createEntitlementPopup } from '../entitlement/createEntitlementPopup';
import {
  entitlementIdentityName,
  getEntitlementFlags,
  getIdentityResourceEntitlementDescription,
  identityEntitlementColumns,
  identityEntitlementToTableRow,
  mapTableRowToEntitlement,
  useEntitlementMessages
} from '../entitlement/entitlementUtils';
import {
  IdentityResourceEntitlementDetails
} from '../entitlement/identityResourceEntitlementDetails';
import styles from './resource.module.scss';
import { ContextType } from './resourceView';

export const getResourceEntitlementConfirmationPopup = (
    resource: Resource | undefined,
    open: boolean,
    onClose: () => void,
    translate: CustomIntlType["format"]
) => {
  return resource ? <ConfirmationPopup
      open={open}
      onClose={onClose}
      primaryTitle={translate("fusion.entitlement.popup.title", {entity: resource.name})}
      message={translate("fusion.message.submitted", {entity: resource.name})}
  /> : null;
};

const ResourceIdentitiesTab = () => {
  const intl = useCustomIntl();
  const {deleteEntitlementMessage} = useEntitlementMessages();
  const {resource, editMode, onCancel} = useOutletContext<ContextType>();
  const {
    onSave,
    submitted,
    setSubmitted,
    pageSize,
    useEntitlementsPageQuery,
    parseEntitlementsPageResult
  } = useResourceIdentityEntitlementsApi(resource);
  const {toggleEditMode} = useSingleEditMode(EntityType.RESOURCE);

  const getDescription = (entitlement: ExtendedIdentityEntitlement, resource: Resource) =>
    getIdentityResourceEntitlementDescription(entitlement, entitlementIdentityName(entitlement) ?? "", 0, resource?.name ?? "", intl.format);

  const getPopupTitle = (entitlement: ExtendedIdentityEntitlement) => ({
    primaryTitle: intl.format("fusion.entitlement.title"),
    secondaryTitle: getDescription(entitlement, resource)
  });

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const onSearch = () => {
  };

  const translatedIdentityEntitlementColumns = useTranslateColumns(identityEntitlementColumns, "fusion.entitlement.identityEntitlement.propertyName")
  const shownColumns = editMode ? translatedIdentityEntitlementColumns : translatedIdentityEntitlementColumns.filter(column => column.field !== "action");

  const identityEntitlementToTableRowWithFlags = (
    entity: ExtendedIdentityEntitlement,
    isNew: boolean,
    onEdit?: (entity: ExtendedIdentityEntitlement) => void,
    onDelete?: (entity: ExtendedIdentityEntitlement) => void): EntityTableRow => {
    return {
      ...identityEntitlementToTableRow(entity, isNew, onEdit, onDelete),
      flags: getEntitlementFlags({validity: entity.validity}, intl.format)
    };
  };

  const onConfirm = () => {
    setSubmitted(false);
    toggleEditMode();
  };

  const confirmationPopup = getResourceEntitlementConfirmationPopup(
      resource, submitted, onConfirm, intl.format
  );

  const createPopup = createEntitlementPopup({
    primaryTitle: intl.format("fusion.entitlement.identityEntitlement.resource.createPopup.title"),
    secondaryTitle: intl.formatBold(
      "fusion.entitlement.identityEntitlement.resource.createPopup.description",
      {resource: resource?.name ?? ""}
    ),
    type: EntitlementType.IDENTITY_RESOURCE_ENTITLEMENT,
    resource
  });

  return <div className={styles.listContainer}>
    {confirmationPopup}
    <ListModifyViewUpdated
      columns={shownColumns}
      onSave={onSave}
      entityToTableRow={identityEntitlementToTableRowWithFlags}
      onSearch={onSearch}
      editMode={editMode}
      getEntityTitle={getPopupTitle}
      isEqual={isEqualById}
      entityDetails={IdentityResourceEntitlementDetails}
      deleteMessage={deleteEntitlementMessage}
      mapTableRowToEntity={mapTableRowToEntitlement}
      pageSize={pageSize}
      usePageQuery={useEntitlementsPageQuery}
      parseResult={parseEntitlementsPageResult}
      createButtonText="fusion.entitlement.identityEntitlement.add"
      createPopup={createPopup}
      tableType={RelationQueryType.IDENTITY_RESOURCE_ENTITLEMENTS}
      onCancel={onCancel}
    />
  </div>;
}

export default ResourceIdentitiesTab;

