import React, { useEffect, useRef, useState } from 'react';
import Sortable from 'sortablejs';
import styles from './fieldDefinition.module.scss';
import { CreateButtonWithIcon, Popup, RowGroup, FormButtons } from '@sivis/shared/components/entityView';
import { FieldDefinitionFieldsInput } from '@sivis/identity/api';
import { useSelector } from 'react-redux';
import { selectMessageTextById } from '@sivis/intl';
import FieldItem from './FieldItem';
import PopupContent from './PopupContent';

export interface ExtendedFieldDefinitionFields
  extends Omit<FieldDefinitionFieldsInput, '__typename'> {
  name: string;
  uiPosition: number;
  visible: boolean;
  [key: string]: any;
}

export interface BaseFieldDefinition {
  id: string;
  fieldDefinitionFields: ExtendedFieldDefinitionFields;
  system: any;
  identityType: any;
}

interface DragDropComponentProps<T extends BaseFieldDefinition> {
  items: T[];
  saveFunction: () => [
    (inputs: {
      inputs: {
        system: any;
        fieldDefinitionFields: Omit<{ __typename: any; [p: string]: any }, '__typename'>;
        identityType: any;
        id: string;
      }[];
    }) => Promise<void>
  ];
  editMode: boolean;
  onCancel: () => void;
}

const DragDropComponent = <T extends BaseFieldDefinition>({
  items,
  saveFunction,
  editMode,
  onCancel,
}: DragDropComponentProps<T>) => {
  const allItemsRef = useRef<HTMLDivElement>(null);
  const [sortedItems, setSortedItems] = useState<T[]>([]);
  const [originalItems, setOriginalItems] = useState<T[]>([]);
  const [popupOpen, setPopupOpen] = useState(false);
  const [currentItem, setCurrentItem] = useState<T | null>(null);
  const [originalItem, setOriginalItem] = useState<T | null>(null);
  const [selectedFieldToAdd, setSelectedFieldToAdd] = useState<string>('');
  const [isAddingField, setIsAddingField] = useState(false);
  const [updateFieldDefinitions] = saveFunction();
  const [grabbedItemId, setGrabbedItemId] = useState<string | null>(null);
  const [selectedItemId, setSelectedItemId] = useState<string | null>(null);

  const sortableInstance = useRef<Sortable | null>(null);

  useEffect(() => {
    const handleGlobalKeyDown = (e: KeyboardEvent) => {
      // Check if the event target or one of its ancestors is an input, textarea, or contenteditable element.
      const target = e.target as HTMLElement;
      if (target.closest('input, textarea, [contenteditable="true"]')) {
        // If so, do nothing so that the space key works normally inside the field.
        return;
      }

      if (!editMode) return;

      if (e.code === 'Space' || e.key === ' ') {
        handleSpaceKeyPress(e);
      } else if (['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight'].includes(e.key)) {
        handleArrowKeyPress(e);
      }
    };

    window.addEventListener('keydown', handleGlobalKeyDown);
    return () => window.removeEventListener('keydown', handleGlobalKeyDown);
  }, [editMode, selectedItemId, grabbedItemId, sortedItems]);


  const handleSpaceKeyPress = (e: KeyboardEvent) => {
    e.preventDefault();
    if (!selectedItemId) return;

    // If the currently selected item is grabbed, release it
    if (grabbedItemId === selectedItemId) {
      setGrabbedItemId(null);
    } else {
      // Otherwise, grab the currently selected item
      setGrabbedItemId(selectedItemId);
    }
  };

  const handleArrowKeyPress = (e: KeyboardEvent) => {
    e.preventDefault();
    const visibleItems = sortedItems.filter(item => item.fieldDefinitionFields.visible);
    const currentIndex = visibleItems.findIndex(item =>
      item.id === (selectedItemId || grabbedItemId)
    );

    if (currentIndex === -1) return;

    const newIndex = calculateNewIndex(e.key, currentIndex, visibleItems.length);

    if (newIndex !== currentIndex && newIndex >= 0 && newIndex < visibleItems.length) {
      if (grabbedItemId) {
        // If an item is grabbed, move it
        updateItemPosition(newIndex);
      } else {
        // If no item is grabbed, just update selection
        setSelectedItemId(visibleItems[newIndex].id);
      }
    }
  };

  const calculateNewIndex = (key: string, currentIndex: number, totalItems: number) => {
    const COLUMNS = 2;
    const currentRow = Math.floor(currentIndex / COLUMNS);
    const currentCol = currentIndex % COLUMNS;
    const totalRows = Math.ceil(totalItems / COLUMNS);

    switch (key) {
    case 'ArrowUp': return currentRow > 0 ? currentIndex - COLUMNS : currentIndex;
    case 'ArrowDown': return currentRow < totalRows - 1 ? Math.min(currentIndex + COLUMNS, totalItems - 1) : currentIndex;
    case 'ArrowLeft': return currentCol > 0 ? currentIndex - 1 : currentIndex;
    case 'ArrowRight': return currentCol < COLUMNS - 1 && currentIndex < totalItems - 1 ? currentIndex + 1 : currentIndex;
    default: return currentIndex;
    }
  };

  const updateItemPosition = (newIndex: number) => {
    if (!grabbedItemId) return;

    setSortedItems(prevItems => {
      const updatedItems = [...prevItems];
      const currentIndex = updatedItems.findIndex(item => item.id === grabbedItemId);
      const [movedItem] = updatedItems.splice(currentIndex, 1);
      updatedItems.splice(newIndex, 0, movedItem);
      return updateUiPositions(updatedItems);
    });

    // Keep the grabbed item selected and grabbed
    setSelectedItemId(grabbedItemId);
  };

  function selectFirstVisibleItem() {
    if (sortedItems.length > 0 && !grabbedItemId && !selectedItemId) {
      const firstVisibleItem = sortedItems.find(
        (item) => item.fieldDefinitionFields.visible
      );
      if (firstVisibleItem) {
        setSelectedItemId(firstVisibleItem.id);
      }
    }
  }

  function clearSelectedItem() {
    setSelectedItemId(null);
  }

  useEffect(() => {
    if (editMode) {
      selectFirstVisibleItem();
    } else {
      clearSelectedItem();
    }
  }, [editMode, sortedItems, grabbedItemId, selectedItemId]);

  useEffect(() => {
    const handleItemsChange = () => {
      const initialItems = items
        .map((item, index) => ({
          ...item,
          fieldDefinitionFields: {
            ...item.fieldDefinitionFields,
            visible: item.fieldDefinitionFields.visible ?? false,
            uiPosition: item.fieldDefinitionFields.uiPosition ?? index,
          },
        }))
        .sort((a, b) => {
          if (a.fieldDefinitionFields.visible === b.fieldDefinitionFields.visible) {
            return (a.fieldDefinitionFields.uiPosition ?? 0) - (b.fieldDefinitionFields.uiPosition ?? 0);
          }
          return a.fieldDefinitionFields.visible ? -1 : 1;
        });

      const sortedWithUiPosition = updateUiPositions(initialItems);
      setSortedItems(sortedWithUiPosition);
      setOriginalItems(sortedWithUiPosition);
    };

    handleItemsChange();
  }, [items]);

  const updateUiPositions = (items: T[]) => {
    return items.map((item, index) => ({
      ...item,
      fieldDefinitionFields: {
        ...item.fieldDefinitionFields,
        uiPosition: index,
      },
    }));
  };

  useEffect(() => {
    if (allItemsRef.current) {
      sortableInstance.current = Sortable.create(allItemsRef.current, {
        disabled: !editMode,
        animation: 150,
        ghostClass: 'bg-blue-100',
        handle: '.dragHandle',
      });
    }

    return () => {
      if (sortableInstance.current) {
        sortableInstance.current.destroy();
      }
    };
  }, [editMode, sortedItems]);

  useEffect(() => {
    if (sortableInstance.current) {
      sortableInstance.current.option('disabled', grabbedItemId !== null || !editMode);
    }
  }, [grabbedItemId, editMode]);

  const handleMakeInvisible = (fieldName: string) => {
    setSortedItems(prevItems =>
      updateUiPositions(
        prevItems
          .map(item =>
            item.fieldDefinitionFields.name === fieldName
              ? {
                ...item,
                fieldDefinitionFields: { ...item.fieldDefinitionFields, visible: false },
              }
              : item
          )
          .sort((a, b) => {
            if (a.fieldDefinitionFields.visible === b.fieldDefinitionFields.visible) {
              return (a.fieldDefinitionFields.uiPosition ?? 0) - (b.fieldDefinitionFields.uiPosition ?? 0);
            }
            return a.fieldDefinitionFields.visible ? -1 : 1;
          })
      )
    );
  };

  const handleOpenPopup = (item: T) => {
    setCurrentItem(item);
    setOriginalItem(item);
    setPopupOpen(true);
  };

  const handleClosePopup = () => {
    if (originalItem) {
      setSortedItems(prevItems =>
        prevItems.map(item => (item.id === originalItem.id ? originalItem : item))
      );
    }
    setPopupOpen(false);
    setCurrentItem(null);
    setOriginalItem(null);
    setIsAddingField(false);
    setSelectedFieldToAdd('');
  };

  const handleChange = (field: keyof ExtendedFieldDefinitionFields, value: any) => {
    if (currentItem) {
      // Convert uiMaxCharacters to integer
      const processedValue = field === 'uiMaxCharacters' ? parseInt(value, 10) || 0 : value;

      const updatedItem = {
        ...currentItem,
        fieldDefinitionFields: {
          ...currentItem.fieldDefinitionFields,
          [field]: processedValue,
        },
      };
      setCurrentItem(updatedItem);
      setSortedItems(prevItems =>
        prevItems.map(item =>
          item.id === currentItem.id ? updatedItem : item
        )
      );
    }
  };

  const handleConfirm = async () => {
    if (isAddingField && selectedFieldToAdd) {
      const selectedItem = sortedItems.find(
        item => item.fieldDefinitionFields.name === selectedFieldToAdd
      );

      if (selectedItem) {
        const updatedItems = updateAndSortItems([...sortedItems], selectedFieldToAdd, true);
        setSortedItems(updatedItems);
      }
    } else if (currentItem) {
      try {
        const { __typename, ...fieldDefinitionFields } = currentItem.fieldDefinitionFields as unknown as {
          __typename: any;
          [key: string]: any;
        };
        setSortedItems(prevItems =>
          prevItems.map(item =>
            item.id === currentItem.id
              ? {
                ...currentItem,
                fieldDefinitionFields,
              }
              : item
          )
        );
      } catch (error) {
        console.error('Error in handleConfirm:', error);
      }
    }
    setPopupOpen(false);
    setCurrentItem(null);
    setOriginalItem(null);
    setIsAddingField(false);
    setSelectedFieldToAdd('');
  };

  const handleSave = async () => {
    try {
      onCancel(); // exiting editMode
      const itemsToSave = sortedItems.map(item => {
        const { __typename, ...fieldDefinitionFields } = item.fieldDefinitionFields as unknown as {
          __typename: any;
          [key: string]: any;
        };
        return {
          id: item.id,
          fieldDefinitionFields,
          identityType: item.identityType,
          system: item.system,
        };
      });

      await updateFieldDefinitions({ inputs: itemsToSave });
    } catch (error) {
      console.error('Error in handleSave:', error);
    }
  };

  const handleDiscard = () => {
    setSortedItems(originalItems);
    setPopupOpen(false);
    setCurrentItem(null);
    setOriginalItem(null);
    onCancel(); // exiting editMode
  };

  const handleAddField = () => {
    setIsAddingField(true);
    setPopupOpen(true);
  };

  const updateItemVisibility = (items: T[], fieldName: string, isVisible: boolean): T[] => {
    return items.map(item =>
      item.fieldDefinitionFields.name === fieldName
        ? {
          ...item,
          fieldDefinitionFields: {
            ...item.fieldDefinitionFields,
            visible: isVisible,
          },
        }
        : item
    );
  };

  const sortItemsByVisibility = (items: T[]): T[] => {
    return items.sort((a, b) => {
      if (a.fieldDefinitionFields.visible === b.fieldDefinitionFields.visible) {
        return (a.fieldDefinitionFields.uiPosition ?? 0) - (b.fieldDefinitionFields.uiPosition ?? 0);
      }
      return a.fieldDefinitionFields.visible ? -1 : 1;
    });
  };

  const updateAndSortItems = (items: T[], fieldName: string, isVisible: boolean): T[] => {
    const updatedItems = updateItemVisibility(items, fieldName, isVisible);
    const sortedItems = sortItemsByVisibility(updatedItems);
    return updateUiPositions(sortedItems);
  };

  const invisibleFields = sortedItems.filter(
    item => !item.fieldDefinitionFields.visible
  );

  const text = useSelector(state => selectMessageTextById(state, 'fusion.draganddrop.info'));

  return (
    <>
      <div className={styles.mainContent}>
        {/* Only show the Add Field button in edit mode */}
        {editMode && (
          <CreateButtonWithIcon
            intlId="fusion.fieldDefinition.add"
            key="create"
            onClick={handleAddField}
          />
        )}
        {editMode && (
          <div className={styles.infoBoxContainer}>
            <div className={styles.infoBox}>{text}</div>
          </div>
        )}
      </div>
      <RowGroup differentStyles={styles.rowGroupCompact}>
        <div className={`${styles.container} ${styles.grid}`} ref={allItemsRef}>
          {sortedItems
            .filter((item) => item.fieldDefinitionFields.visible)
            .map((item) => (
              <FieldItem<T>
                key={item.id}
                item={item}
                editMode={editMode}
                selectedItemId={selectedItemId}
                grabbedItemId={grabbedItemId}
                setSelectedItemId={setSelectedItemId}
                handleOpenPopup={handleOpenPopup}
                handleMakeInvisible={handleMakeInvisible}
                setSortedItems={setSortedItems}
              />
            ))}
        </div>
      </RowGroup>
      {/* Only show the bottom buttons in edit mode */}
      {editMode && (
        <FormButtons
          onCancel={handleDiscard}
          onSave={handleSave}
          saveInsteadOfSubmit={false}
        />
      )}
      <Popup
        open={popupOpen}
        onClose={handleClosePopup}
        onConfirm={handleConfirm}
        primaryTitle={isAddingField ? 'Add Field' : 'Field Metadata'}
        secondaryTitle={
          isAddingField
            ? 'Select and configure field'
            : currentItem
              ? `Details for ${currentItem.fieldDefinitionFields.name}`
              : ''
        }
        styleType="edit"
      >
        <PopupContent<T>
          isAddingField={isAddingField}
          selectedFieldToAdd={selectedFieldToAdd}
          setSelectedFieldToAdd={setSelectedFieldToAdd}
          currentItem={currentItem}
          handleChange={handleChange}
          invisibleFields={invisibleFields}
        />
      </Popup>
    </>
  );
};

export default DragDropComponent;
