import { ButtonProps, SxProps, Theme } from '@mui/material';
import { Button } from '@pointsharp/component-library';
import { FunctionComponent } from 'react';
import { useSelector } from 'react-redux';
import { selectMessageTextById } from '../intlSelectors';
import { HasAriaIntlId } from './HasAriaIntlId';
import { HasIntlId } from './HasIntlId';

interface IconProps {
  icon?: JSX.Element;
}

export const IntlButton: FunctionComponent<
  HasIntlId & HasAriaIntlId & ButtonProps & IconProps
> = ({ intlId, ariaIntlId, icon, sx, ...props }) => {
  const style: { button: SxProps<Theme> } = {
    button: {
      fontFamily:
        '-apple-system, BlinkMacSystemFont, "SF Pro Display", "Segoe UI", Roboto, sans-serif',
      fontWeight: 400,
      height: '32px',
      textTransform: 'none',
    },
  };
  const text = useSelector((state) => selectMessageTextById(state, intlId));
  const translatedAriaLabel = useSelector((state) =>
    selectMessageTextById(state, ariaIntlId ?? props['aria-label'])
  );

  return (
    <Button
      {...props}
      aria-label={translatedAriaLabel}
      sx={{ ...style.button, ...(sx ?? {}) } as SxProps<Theme>}
    >
      {icon} {/* icon rendering */}
      {text}
    </Button>
  );
};
