import { PopoverProps } from '@mui/material';
import { selectThemeOptions } from '@sivis/shared/theme';
import React from 'react';
import { useSelector } from 'react-redux';
import { Popover, Typography } from '@pointsharp/component-library'

interface PsPopoverProps extends PopoverProps {
  children: React.ReactNode;
  typographyStyles?: object;
  spanStyles?: object;
  popoverTitle: string;
}

export const PsPopover: React.FC<PsPopoverProps> = ({
  children,
  typographyStyles,
  spanStyles,
  popoverTitle,
  ...popoverProps
}) => {
  const themeOptions = useSelector(selectThemeOptions);

  const style = {
    popover: {
      p: 0,
      lineHeight: 1.5,
      '& .MuiPaper-root': {
        backgroundColor: themeOptions?.palette?.background?.almostWhite,
      },
    },
    popoverTypography: {
      p: 2,
      fontSize: '12px',
      width: '179px',
    },
    popoverSpan: {
      color: '#707070',
    },
  };

  return (
    <Popover
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      sx={style.popover}
      {...popoverProps}
    >
      <Typography sx={style.popoverTypography}>
        <span style={style.popoverSpan}>{popoverTitle}</span>
        {children}
      </Typography>
    </Popover>
  );
};
