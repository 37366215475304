import { useIdentityApi } from './useIdentityApi';
import styles from './identity.module.scss';
import {
  DeleteButton,
  EditButton,
  EntityInformation,
  EntityView
} from '@sivis/shared/components/entityView';
import { IdentityRoleView } from './identityRoleView';
import {
  Identity,
  IdentityInput,
  useGetIdentityFieldDefinitionsQuery,
  useGetIdentityTypesQuery
} from '@sivis/identity/api';
import { Outlet, useOutletContext } from 'react-router-dom';
import {
  ACCOUNTS_TAB_PATHNAME,
  IDENTITY_URL,
  INFORMATION_TAB_PATHNAME,
  OWNERSHIP_TAB_PATHNAME,
  RESOURCES_TAB_PATHNAME,
  ROLES_TAB_PATHNAME
} from '../components/routes/constants';
import { IdentityResourceView } from './identityResourceView';
import { useCustomIntl, useTranslateConfigs } from '@sivis/intl';
import { getTranslatedErrorMessage } from '@sivis/shared/api-utils';
import { IdentityOwnershipView } from './identityOwnershipView';
import { useEffect } from 'react';
import { AccountListByIdentity } from './AccountListByIdentity';
import { useRouteRedirect } from '../common/useRouteRedirect';

export const IdentityView = () => {
  useRouteRedirect({ basePath: IDENTITY_URL, subPath: INFORMATION_TAB_PATHNAME });

  const intl = useCustomIntl();
  const {
    defaultInput,
    identity,
    error,
    isNew,
    onSave,
    onCancel,
    onDelete,
    editMode,
    toggleEditMode,
  } = useIdentityApi();

  const informationTab = {
    label: intl.format("fusion.breadcrumb.information"),
    path: INFORMATION_TAB_PATHNAME
  };
  const tabs = isNew ? [informationTab] : [
    informationTab,
    { label: intl.format("fusion.account.list.name"), path: ACCOUNTS_TAB_PATHNAME },
    { label: intl.format("fusion.role.list.name"), path: ROLES_TAB_PATHNAME },
    { label: intl.format("fusion.resource.list.name"), path: RESOURCES_TAB_PATHNAME },
    { label: intl.format("fusion.ownership.list.name"), path: OWNERSHIP_TAB_PATHNAME }
  ];

  const titleExisting = `${intl.format("fusion.identity.information.titleExisting")}${identity?.firstName} ${identity?.lastName}`;
  const title = isNew ? intl.format("fusion.identity.information.titleNew") : titleExisting;
  const buttons = isNew ? undefined : [
    <EditButton onClick={toggleEditMode} disabled={editMode} key="edit" />,
    <DeleteButton onClick={onDelete} key="delete" />
  ];

  return <EntityView
    title={title}
    tabs={tabs}
    actionButtons={buttons}
    error={error ? getTranslatedErrorMessage(error, "fusion.identity.error.notFound", intl.format) : undefined}>
    <Outlet
      context={{ editMode, identity, defaultInput, onSave, onCancel, isNew, toggleEditMode }} />
  </EntityView>;
};

type ContextType = {
  editMode: boolean;
  identity: Identity;
  isNew: boolean;
  defaultInput: IdentityInput;
  onSave: (value: Record<string, any>) => void;
  onCancel: () => void;
  toggleEditMode: () => void;
};

export const IdentityInformationTab = () => {
  const intl = useCustomIntl();
  const { editMode, defaultInput, isNew, onSave, onCancel, identity } = useOutletContext<ContextType>();

  const { data: fieldDefinitionsData, error: fieldDefinitionsError, isLoading: isLoadingFieldDefinitions, refetch: refetchFieldDefinitions } = useGetIdentityFieldDefinitionsQuery();
  const { data: identityTypesData, error: identityTypesError, isLoading: isLoadingIdentityTypes } = useGetIdentityTypesQuery();

  useEffect(() => {
    if (identity?.identityType?.id) {
      refetchFieldDefinitions();
    }
  }, [identity?.identityType?.id]);

  const loading = isLoadingFieldDefinitions || isLoadingIdentityTypes;
  const error = fieldDefinitionsError || identityTypesError;

  useEffect(() => {
    if (fieldDefinitionsData && identity?.identityType?.id) {
      fieldDefinitionsData.identityFieldDefinitions
        ?.filter((field): field is NonNullable<typeof field> =>
          field !== null &&
          field.fieldDefinitionFields.visible &&
          field.identityType === identity.identityType.id);
    }
  }, [fieldDefinitionsData, identity]);

  const handleOnSubmit = (value: Record<string, any>) => {
    if ('lastName' in value && 'identityType' in value) {
      onSave(value as IdentityInput);
    } else {
      console.error(intl.format("fusion.identity.error.lastNameMissing"));
    }
  };

  const identityTypeReadOnly = identity?.identityType?.name;

  const identityTypesOptions = identityTypesData?.identityTypes?.edges
    ?.filter((edge): edge is NonNullable<typeof edge> => edge !== null)
    .map(edge => ({
      value: edge.node?.id ?? '',
      text: edge.node?.name ?? ''
    })) ?? [];

  function lowercaseFirstLetter(input: string): string {
    if (input.length === 0) {
      return input; // Return the empty string if input is empty
    }
    return input.charAt(0).toLowerCase() + input.slice(1);
  }

  const configs = fieldDefinitionsData?.identityFieldDefinitions
    ?.filter((field): field is NonNullable<typeof field> =>
      field !== null &&
      field.fieldDefinitionFields.visible &&
      field.identityType === (isNew ? identityTypesOptions[0]?.value : identity?.identityType?.id))
    .sort((a, b) => a.fieldDefinitionFields.uiPosition - b.fieldDefinitionFields.uiPosition)
    .map(field => ({
      property: lowercaseFirstLetter(field.fieldDefinitionFields.fieldName),
      required: field.fieldDefinitionFields.required,
      disabled: isNew && field.fieldDefinitionFields.readOnly,
      options: field.fieldDefinitionFields.fieldName === 'IdentityType' ? identityTypesOptions : undefined,
      renderRow: field.fieldDefinitionFields.fieldName === 'IdentityType' ? (_: string) => (identityTypeReadOnly) : undefined,
    })) ?? [];


  const translatedConfigs = useTranslateConfigs(configs, "fusion.identity.propertyName");


  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error loading field definitions or identity types</div>;
  }

  return (
    <div className={styles.listContainer}>
      <EntityInformation
        editMode={editMode}
        configs={translatedConfigs}
        value={defaultInput}
        onSubmit={handleOnSubmit}
        onCancel={onCancel}
      />
    </div>
  );
};



export const IdentityAccountTab = () => {
  const { identity } = useOutletContext<ContextType>();

  if (!identity?.id) {
    return <div>Loading...</div>;
  }

  return <AccountListByIdentity identityId={identity.id} />;
};

export const IdentityRoleTab = () => {
  const { editMode, identity, toggleEditMode, onCancel } = useOutletContext<ContextType>();
  return <IdentityRoleView identity={identity} editMode={editMode} onCancel={onCancel}
                           toggleEditMode={toggleEditMode} />;
};

export const IdentityResourceTab = () => {
  const { editMode, identity, onCancel } = useOutletContext<ContextType>();
  return <IdentityResourceView identity={identity} editMode={editMode} onCancel={onCancel} />;
};

export const IdentityOwnershipTab = () => {
  const { editMode, identity, onCancel } = useOutletContext<ContextType>();
  return <IdentityOwnershipView identity={identity} editMode={editMode} onCancel={onCancel} />;
}
