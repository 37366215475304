import { LinearProgress } from '@mui/material';
import {
  DataGridProProps,
  GridColDef,
  GridRowParams,
  GridRowSelectionModel
} from '@mui/x-data-grid-pro';
import { Box } from '@pointsharp/component-library';
import { addListener } from '@reduxjs/toolkit';
import { TypedUseQueryHookResult } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { SearchFieldProps } from '../searchField/searchField';
import { refetchTable, resetTable, TableType } from './action';
import { EntityTableRow, StyledDataGridPro } from './entityTable';
import styles from './table.module.scss';
import { Toolbar } from './toolbar';

export interface CursorBasedPageResult<T> {
  entries: T[];
  endCursor: string | null;
}

interface InfiniteScrollTableProps<Data, QueryResult> extends SearchFieldProps {
  columns: GridColDef[];
  renderRow?: (data: Data) => EntityTableRow;
  pageSize: number;
  usePageQuery: (cursor: string | null) => TypedUseQueryHookResult<QueryResult, any, any>;
  parseResult: (res?: QueryResult) => CursorBasedPageResult<Data>;
  toolbarButtons?: JSX.Element[];
  /**
   * Called with the clicked row's entityId (if entityId doesn't exist in Data, id will be used)
   * and dataObject (if existing)
   */
  onRowClick?: (id: string, item?: Data) => void;
  sideFilter?: JSX.Element;
  /**
   * An alternative to renderRow, a callback to render all rows based on fetched data.
   * Used e.g. when the data has to be filtered before rendering.
   */
  renderRows?: (data: Data[]) => EntityTableRow[];
  onRowsChange?: (rows: Data[]) => void;
  // Selection mode with checkboxes
  isRowSelected?: (row: EntityTableRow) => boolean;
  onSelectRow?: (row: EntityTableRow, selected: boolean) => void;
  disableSelect?: boolean;
  /**
   * Entity type of the table used to find the action triggering table refetching.
   */
  tableType?: TableType;
  getRowClassName?: (params: GridRowParams) => string;
}

export const InfiniteScrollTableCursor = <Data, QueryResult>(props: InfiniteScrollTableProps<Data, QueryResult>) => {
  const [rows, setRows] = useState<EntityTableRow[]>([]);
  const [nextCursor, setNextCursor] = useState<string | null>(null);
  const [currentData, setCurrentData] = useState<CursorBasedPageResult<Data>>({
    entries: [],
    endCursor: null
  });
  const {data: pageResult, refetch} = props.usePageQuery(nextCursor);
  const dispatch = useDispatch();

  const style = {
    box: {
      display: 'flex',
      flexDirection: 'row',
    },
    filterBox: {
      width: '200px' 
    },
  };

  // @ts-ignore
  // TODO: should be useAppDispatch to get the correct return type (UnsubscribeListener).
  //  But store may not be defined.
  useEffect(() => {
    return dispatch(addListener({
      actionCreator: refetchTable(props.tableType),
      effect: () => {
        if (nextCursor) {
          // nextCursor is reset to null -> refetch automatically
          resetPage();
        } else {
          // nextCursor was null already -> trigger refetch explicitly
          resetPage();
          // Wait for possible cache clearing actions to be finished before calling
          // refetch, otherwise refetch would be rejected
          setTimeout(refetch, 1);
        }
      },
    }));
  }, [nextCursor]);

  // @ts-ignore
  useEffect(() => {
    return dispatch(addListener({
      actionCreator: resetTable(props.tableType),
      effect: () => {
        resetPage();
      }
    }));
  }, []);

  useEffect(() => {
    const newData = props.parseResult(pageResult);
    setCurrentData(newData);
    props.onRowsChange?.(newData.entries.filter(entry => entry))
  }, [pageResult]);

  useEffect(() => {
    const dataToRender = currentData.entries.filter(entry => entry);
    const newRows: EntityTableRow[] = props.renderRows ? props.renderRows(dataToRender) :
      dataToRender.map(entry => props.renderRow!(entry));
    setRows(newRows);
  }, [currentData, props.renderRow, props.renderRows]);

  const handleOnRowsScrollEnd: DataGridProProps['onRowsScrollEnd'] = () => {
    if (currentData.endCursor) {
      setNextCursor(currentData.endCursor);
    }
  };

  const resetPage = () => {
    setNextCursor(null);
    scrollToTop();
  }

  const scrollToTop = () => {
    if (document.querySelector('.MuiDataGrid-virtualScroller')) {
      document.querySelector('.MuiDataGrid-virtualScroller')!.scrollTop = 0;
    }
  };

  const onSearch = (searchStr: string) => {
    resetPage();
    props.onSearch(searchStr);
  }

  const onRowClick = (params: GridRowParams) => {
    props.onRowClick?.(params.row.entityId ?? params.row.id, params.row.dataObject);
  };

  const selectionMode = !!props.isRowSelected || !!props.onSelectRow;

  const onSelectRows = (selectionModel: GridRowSelectionModel) => {
    const newSelectedRowIds = selectionModel as string[];
    const selectedRows = rows.filter(row => !row.active && newSelectedRowIds.includes(row.id));
    if (selectedRows.length) {
      props.onSelectRow?.(selectedRows[0], true);
    } else {
      const deselectedRows = rows.filter(row => row.active && !newSelectedRowIds.includes(row.id));
      deselectedRows.length && props.onSelectRow?.(deselectedRows[0], false);
    }
  };

  const selectedRowIds = useMemo(() => {
    return props.isRowSelected ? rows.filter(props.isRowSelected).map(row => row.id) : [];
  }, [rows, props.isRowSelected]);

  const isRowSelectable = useCallback(() => props.disableSelect !== undefined ? !props.disableSelect : true, [props.disableSelect]);

  const generateRowClassName = (
    params: GridRowParams,
    customGetRowClassName?: (params: GridRowParams) => string
  ): string => {
    let classNames = [];
    if (customGetRowClassName) {
      classNames.push(customGetRowClassName(params));
    }
    if (params.row.isNew) {
      classNames.push('row-isNew');
    }
    return classNames.join(' ').trim();
  };

  return <>
    <Toolbar {...props} totalEntries={rows.length} onSearch={onSearch}
             buttons={props.toolbarButtons}/>
    <div className={styles.entityTable}>
      
      
      <Box sx={style.box}>
      {props.sideFilter && <Box sx={style.filterBox}>
        {props.sideFilter}
      </Box>}
        <StyledDataGridPro
          columns={props.columns}
          rows={rows}
          onRowsScrollEnd={handleOnRowsScrollEnd}
          slots={{
            loadingOverlay: LinearProgress
          }}
          filterMode="server"
          sortingMode="server"
          disableRowSelectionOnClick
          checkboxSelection={selectionMode}
          onRowSelectionModelChange={onSelectRows}
          rowSelectionModel={selectedRowIds}
          hideFooter
          onRowClick={onRowClick}
          getRowClassName={(params) => generateRowClassName(params, props.getRowClassName)}
          isRowSelectable={isRowSelectable}
        />
      </Box>
    </div>
  </>;
};
