import { AccountCircleOutlined } from '@mui/icons-material';
import {
  Button,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
} from '@pointsharp/component-library';
import { HEADER_ITEM_RIGHT } from '@sivis/app-shell';
import { useCustomIntl } from '@sivis/intl';
import { LOGOUT_INTL_ID } from '@sivis/shared/components/login';
import { PsPopover } from '@sivis/shared/components/popover';
import {
  PreparedRegistration,
  prepareRegisterComponent,
} from '@sivis/shared/components/registry';
import { selectThemeOptions } from '@sivis/shared/theme';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { logout } from '../../redux/authSlice';

export const LogoutButton = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const intl = useCustomIntl();
  const themeOptions = useSelector(selectThemeOptions);

  const style = {
    item: {
      color: themeOptions?.palette?.background?.almostWhite,
      minHeight: 20,
      pr: 1.5,
      pl: 1.5,
      '&:hover': {
        backgroundColor: themeOptions?.palette?.background?.almostWhite,
        color: themeOptions?.palette?.background?.almostBlack,
        '& .MuiListItemIcon-root': {
          color: themeOptions?.palette?.background?.almostBlack,
        },
        '& .MuiTypography-root': {
          color: themeOptions?.palette?.background?.almostBlack,
        },
      },
    },
    text: {
      color: themeOptions?.palette?.background?.almostWhite,
      marginLeft: 10,
    },
    listItem: {
      p: 0,
    },
    listItemButton: {
      pl: 2,
      pt: 0,
      pb: 0,
      fontSize: '12px',
    },
  };

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    dispatch(logout());
    navigate('/');
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div>
      <React.Fragment key="languageIcon">
        <Button onClick={handleMenuOpen} sx={{ textTransform: 'none' }}>
          <ListItemButton sx={style.item}>
            <AccountCircleOutlined />
            <ListItemText style={style.text} primary={'Admin'} />
          </ListItemButton>
        </Button>
      </React.Fragment>
      <Typography sx={{ p: 0 }}>
        <PsPopover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleMenuClose}
          popoverTitle=""
        >
          <List>
            <ListItem sx={style.listItem}>
              <ListItemButton
                sx={style.listItemButton}
                onClick={() => handleLogout()}
              >
                <ListItemText
                  primary={intl.format('fusion.header.account.logout')}
                />
              </ListItemButton>
            </ListItem>
          </List>
        </PsPopover>
      </Typography>
    </div>
  );
};

export const logoutButtonRegistration: PreparedRegistration =
  prepareRegisterComponent({
    id: `${HEADER_ITEM_RIGHT}/${LogoutButton.name}`,
    name: LOGOUT_INTL_ID,
    component: LogoutButton,
    type: HEADER_ITEM_RIGHT,
    order: 0,
  });
