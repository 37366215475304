import { useOutletContext } from 'react-router-dom';
import { ContextType } from './ruleView';
import { IntlButton, useCustomIntl, useTranslateConfigs } from '@sivis/intl';
import {
  EntityForm,
  EntityFormHandle,
  FormButtons,
  RowGroup
} from '@sivis/shared/components/entityView';
import { useEffect, useRef, useState } from 'react';
import styles from './rule.module.scss';
import { CriteriaGroupCard, CriteriaGroupCardHandle } from './criteriaGroupCard';
import { CreatableCriteriaGroup, getOperatorTextBold, updateWatchValues } from './ruleUtils';
import {
  BusinessRule,
  CriteriaGroup,
  RuleLogicalOperator,
  RuleOperation
} from '@sivis/identity/api';
import DraftCard from '../components/draft/draftCard';

interface RuleEditCreatePopupInformationTabProps {
  togglePopupOpen: () => void;
}

export const RuleCriteriaPopup = (props: RuleEditCreatePopupInformationTabProps) => {
  const {
    businessRule,
    setBusinessRule,
    draftData,
    setDraftData,
    onCancel,
    watchValues,
    setWatchValues,
    onDeleteDraft
  } = useOutletContext<ContextType>();
  const intl = useCustomIntl();
  const [updatedRule, setUpdatedRule] = useState<BusinessRule>(draftData ?? businessRule);

  const configsRule = [
    {
      property: 'operatorOutsideGroup',
      options: [
        { value: RuleLogicalOperator.Or, text: intl.format('fusion.rule.logicalOperator.or') },
        { value: RuleLogicalOperator.And, text: intl.format('fusion.rule.logicalOperator.and') }
      ],
      useSwitch: true
    }
  ];

  const ref = useRef<EntityFormHandle>(null);
  const criteriaGroupRefs = useRef<(CriteriaGroupCardHandle | null)[]>([]);
  const translatedConfigsRule = useTranslateConfigs(configsRule, 'fusion.rule.propertyName');

  const updateRuleNameAndTarget = (prevRule: BusinessRule) => ({
    name: watchValues['mainForm']?.name || prevRule.name || '',
    targetValue: watchValues['mainForm']?.targetValue || prevRule.targetValue || ''
  });

  useEffect(() => {
    if (Object.keys(watchValues).length > 0) {
      setUpdatedRule(prevRule => ({
        ...prevRule,
        ...updateRuleNameAndTarget(prevRule)
      }));
    }
  }, [watchValues]);

  const handleOnSave = async () => {
    const validStates = await Promise.all(
      criteriaGroupRefs.current.map(ref => ref?.handleSave() ?? Promise.resolve(true))
    );
    const allValid = validStates.every(Boolean);

    if (!allValid) {
      return;
    }
    const updatedGroups = updatedRule.criteriaGroups?.map(group => ({
      ...group,
      criteriaInsideGroup: group.criteriaInsideGroup
      .map(criterion => {
        const watchedCriterion = watchValues[`criterion-${criterion.id}`] || {};
        return {
          ...criterion,
          ...watchedCriterion,
          // currently operation & negate are not being changed
          operation: criterion.operation,
          negate: criterion.negate || false
        };
      })
    }));
    const newUpdatedRule = {
      ...updatedRule,
      name: watchValues['mainForm']?.name || businessRule.name || '',
      targetValue: watchValues['mainForm']?.targetValue || businessRule.targetValue || '',
      criteriaGroups: updatedGroups
    };
    draftData ? setDraftData(newUpdatedRule) : setBusinessRule(newUpdatedRule);
    props.togglePopupOpen();
  };

  const onAddNewCriteriaGroup = () => {
    setUpdatedRule(prevRule => ({
      ...prevRule,
      ...updateRuleNameAndTarget(prevRule),
      criteriaGroups: [
        ...(prevRule.criteriaGroups ?? []),
        {
          id: Date.now().toString(),
          isNew: true,
          criteriaInsideGroup: [{
            id: Date.now().toString(),
            isNew: true,
            entityField: '',
            operation: RuleOperation.Equals,
            negate: false,
            value: ''
          }]
        }
      ]
    }));
  };

  const onAddNewCriterion = (groupId: string) => {
    setUpdatedRule(prevRule => ({
      ...prevRule,
      ...updateRuleNameAndTarget(prevRule),
      criteriaGroups: (prevRule.criteriaGroups ?? []).map(group =>
        group.id === groupId
          ? {
            ...group,
            criteriaInsideGroup: [
              ...group.criteriaInsideGroup,
              {
                id: Date.now().toString(),
                isNew: true,
                entityField: '',
                operation: RuleOperation.Equals,
                negate: false,
                value: ''
              }
            ]
          }
          : group
      )
    }));
  };

  const filterCriterionFromGroup = (group: CriteriaGroup | CreatableCriteriaGroup, criterionId: string) => {
    return group.criteriaInsideGroup.filter(criterion => criterion.id !== criterionId);
  };

  const updateGroupCriteria = (group: CriteriaGroup | CreatableCriteriaGroup, criterionId: string) => {
    const updatedCriteria = filterCriterionFromGroup(group, criterionId);
    return updatedCriteria.length > 0 ? { ...group, criteriaInsideGroup: updatedCriteria } : null;
  };

  const updateCriteriaGroups = (prevGroups: (CriteriaGroup | CreatableCriteriaGroup)[], groupId: string, criterionId: string) => {
    return prevGroups.reduce<(CriteriaGroup | CreatableCriteriaGroup)[]>((acc, group) => {
      if (group.id === groupId) {
        const updatedGroup = updateGroupCriteria(group, criterionId);
        if (updatedGroup) {
          acc.push(updatedGroup);
        }
      } else {
        acc.push(group);
      }
      return acc;
    }, []);
  };

  const onDeleteCriterion = (groupId: string, criterionId: string) => {
    setUpdatedRule(prevRule => {
      const updatedGroups = updateCriteriaGroups(prevRule.criteriaGroups ?? [], groupId, criterionId);

      return {
        ...prevRule,
        ...updateRuleNameAndTarget(prevRule),
        criteriaGroups: updatedGroups
      };
    });
  };

  const onDeleteCriteriaGroup = (groupId: string) => {
    setUpdatedRule(prevRule => {
      const updatedGroups = (prevRule.criteriaGroups ?? []).filter(group => group.id !== groupId);
      return {
        ...prevRule,
        ...updateRuleNameAndTarget(prevRule),
        criteriaGroups: updatedGroups
      };
    });
  };

  const onSwitchChange = (value: RuleLogicalOperator) => {
    const operatorInsideGroup = value === RuleLogicalOperator.And ? RuleLogicalOperator.Or : RuleLogicalOperator.And;
    setUpdatedRule(prevRule => ({
      ...prevRule,
      ...updateRuleNameAndTarget(prevRule),
      operatorOutsideGroup: value,
      criteriaGroups: prevRule.criteriaGroups?.map((group: CreatableCriteriaGroup | CriteriaGroup) => ({
        ...group,
        operatorInsideGroup: operatorInsideGroup
      }))
    }));
  };

  return (
    <div>
      {draftData?.publishedId != null &&
        <DraftCard
          draftData={{
            updatedAt: draftData.meta?.updatedAt ?? Date.now().toString(),
            updatedBy: draftData.meta?.updatedBy ?? Date.now().toString()
          }}
          onDiscardDraft={() => onDeleteDraft(draftData.id)}
        />
      }
      <RowGroup differentStyles={styles.rowGroupRuleInformation}>
        <EntityForm
          ref={ref}
          configs={translatedConfigsRule}
          value={updatedRule}
          onSubmit={handleOnSave}
          onCancel={onCancel}
          hideButtons
          onWatch={(watchValues) => {
            updateWatchValues('mainForm', watchValues, setWatchValues);
            // Look for changes specifically in operatorOutsideGroup to trigger the switch logic
            if (watchValues.operatorOutsideGroup) {
              onSwitchChange(watchValues.operatorOutsideGroup);
            }
          }}
        />
      </RowGroup>

      {updatedRule.criteriaGroups?.map((group, index) => (
        <div key={group.id}>
          <CriteriaGroupCard
            ref={(el) => (criteriaGroupRefs.current[index] = el)}
            group={group}
            key={group.id}
            index={index}
            operatorOutsideGroup={updatedRule.operatorOutsideGroup}
            onAddNewCriterion={onAddNewCriterion}
            onDeleteCriterion={onDeleteCriterion}
            onDeleteCriteriaGroup={onDeleteCriteriaGroup}
            onSave={handleOnSave}
            setWatchValues={setWatchValues}
          />
          {updatedRule.criteriaGroups && index < updatedRule.criteriaGroups.length - 1 && (
            <div className={styles.ruleOperator}>
              {getOperatorTextBold(updatedRule.operatorOutsideGroup, intl.formatBold)}
            </div>
          )}
        </div>
      ))}
      <IntlButton className={styles.ruleMargin}
                  variant="outlined"
                  intlId={'fusion.rule.addCriteriaGroup'}
                  onClick={() => {
                    onAddNewCriteriaGroup();
                  }} />
      <FormButtons
        onCancel={props.togglePopupOpen}
        onSave={handleOnSave}
        saveInsteadOfSubmit
      />
    </div>
  );
};
