import { Account, AccountType, GeneratedAccountTypes } from '@sivis/identity/api';
import { GridColDef } from '@mui/x-data-grid-pro';
import { useCustomIntl, useTranslateColumns } from '@sivis/intl';
import { EntityTableRow, InfiniteScrollTableCursor } from '@sivis/shared/components/entityView';
import { useAccountsPageApi } from '../account/useAccountsPageApi';


interface AccountTypeAccountsTabProps {
  accountType?: AccountType;
}

export const accountEntityToTableRow = (entity: Account): EntityTableRow => {
  return {
    id: entity.id,
    accountId: entity.name,
    name: entity.name,
    accountType: entity.accountType,
    systemId: entity.system?.id,
    systemName: entity.system?.systemName,
    accountStatus: 'Active' // dummy value
  };
};

const columns: GridColDef[] = [
  { field: 'accountId', flex: 1 },
  { field: 'accountType', flex: 1, renderCell: params => params.value?.name },
  { field: 'systemName', flex: 1 },
  { field: 'accountStatus', flex: 1 }
];

const AccountTypeAccountsTab = (props: AccountTypeAccountsTabProps) => {
  const intl = useCustomIntl();
  const translatedColumns = useTranslateColumns(columns, 'fusion.account.propertyName');
  const {
    onSearch,
    pageSize,
    useAccountsPageQuery,
    parseAccountsPageResult
  } = useAccountsPageApi({ accountTypeId: props.accountType?.id });

  if (!props?.accountType?.id) {
    return <div>{intl.format('fusion.account.error.noAccountId')}</div>;
  }

  return (
    <InfiniteScrollTableCursor<Account, GeneratedAccountTypes.GetAccountsQuery>
      columns={translatedColumns}
      pageSize={pageSize}
      usePageQuery={useAccountsPageQuery}
      parseResult={parseAccountsPageResult}
      onSearch={onSearch}
      placeholder={intl.format('fusion.general.searchPlaceholder')}
      renderRow={accountEntityToTableRow}
    />
  );
}

export default AccountTypeAccountsTab;

